export default (columns, data) => {
  // Applying headers for csv
  let lines = [columns.map(column => column.label).join(',')];

  return data
    .reduce(
      (acc, curRow) => [
        ...acc,
        columns.map(prop => parseCell(prop.format ? prop.format(curRow[prop.name]) : curRow[prop.name])).join(','),
      ],
      lines
    )
    .join('\n');
};

const parseCell = (cell = '') => {
  // Replace all double quotes with two double quotes
  const parsedValue = cell.toString().replace(/"/g, `""`) || '';

  // If value contains comma, new-line or double-quote, enclose in double quotes
  return /[",\n]|^$/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;
};
