import React, { useState } from 'react';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Divider } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, AddCircle, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { MasonryContainer, MASONRY_GUTTER, H3, BodyText } from '../../common';
import AddWarehousePOCDialog from './AddWarehousePOCDialog';
import AddEditUserDialog from '../PointsOfContact/AddEditUserDialog';
import WlContactCard from '../PointsOfContact/ContactCard/WarehouseCard';
import WarehouseCardSummary from './WarehouseCardSummary';
import { STATES_MAP } from '../../States';
import { getCompany } from '../../../store/selectors/company';
import {
  hasWlBillingAddressPrivilege,
  hasWlMainAddressPrivilege,
  hasWlAdminRights,
} from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: `${theme.spacing(1.5)}px !important`,
  },
  summaryRoot: {
    padding: theme.spacing(0, 1.5),
    '& .MuiExpansionPanelSummary-content': {
      alignItems: 'center',
    },
  },
  contentContainer: {
    padding: theme.spacing(0, 0, 2),
    flexDirection: 'column',
    '& > div': {
      margin: theme.spacing(1, 3),
    },
    '& > hr': {
      margin: theme.spacing(1, 2),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.top': {
      marginBottom: 16,
    },
    '& button': {
      fontSize: 16,
      fontWeight: 700,
      padding: theme.spacing(0.5, 1),
    },
  },
  rowItem: {
    width: '50%',
    '&.last': {
      paddingLeft: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 16,
    fontWeight: 700,
  },
  column: {
    padding: 0,
  },
  contactCard: {
    marginBottom: `${MASONRY_GUTTER}px !important`, // matches parent gutter
    '& .details-left': {
      width: '65%',
    },
    '& .details-right': {
      width: '35%',
    },
  },
}));

const WarehouseLocationCard = ({ className, warehouse, onEdit, isAdmin, warehouses }) => {
  const classes = useStyles();
  const { mainAddress, mainCity, mainState, mainZipCode, billingState, contacts = {} } = warehouse;
  const [addEditDialogContact, setAddEditDialogContact] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [hideContactForm, setHideContactForm] = useState(false);
  const company = useSelector(getCompany);
  const hasWlBillingAddressRights = useSelector(hasWlBillingAddressPrivilege(warehouse.id));
  const hasWlMainAddressRights = useSelector(hasWlMainAddressPrivilege(warehouse.id));
  const isWlAdmin = useSelector(hasWlAdminRights(warehouse.id));

  const stateName = STATES_MAP[mainState].name;
  const billingStateName = (billingState && STATES_MAP[billingState]?.name) || '';

  const isUserAdminRights = isAdmin || isWlAdmin;

  return (
    <div>
      <ExpansionPanel classes={{ rounded: `${classes.root} ${className}` }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summaryRoot }}>
          <WarehouseCardSummary warehouse={warehouse} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentContainer}>
          <Divider />
          <div className={classes.row}>
            <H3>Warehouse Points of Contact</H3>

            {isAdmin && (
              <Button color="primary" onClick={() => setAddEditDialogContact(true)}>
                <AddCircle className={classes.icon} />
                Add Contact
              </Button>
            )}
          </div>
          <MasonryContainer columns={1} columnClassName={classes.column}>
            {Object.values(contacts).map(contact => {
              const user = {
                ...(company.warehouseContacts?.[contact.id] || company.contacts?.[contact.id]),
                ...warehouse.contacts?.[contact.id],
              };

              return user.warehouseLocationIds?.includes(warehouse.id) ? (
                <WlContactCard
                  key={contact.id}
                  className={classes.contactCard}
                  user={user}
                  onEdit={() => setSelectedUser(user)}
                  warehouseId={warehouse.id}
                  warehouses={[warehouse]}
                  isShowWarehouseRoles
                />
              ) : null;
            })}
          </MasonryContainer>

          <Divider />
          <div className={classes.row}>
            <H3>Main Address</H3>

            {hasWlMainAddressRights && (
              <Button color="primary" onClick={onEdit}>
                <Edit className={classes.icon} />
                Edit Address
              </Button>
            )}
          </div>

          <div>
            <div className={`${classes.row} top`}>
              <div className={classes.rowItem}>
                <BodyText variant="label">Street</BodyText>
                <BodyText el="div" variant="large">
                  {mainAddress}
                </BodyText>
              </div>
              <div className={`${classes.rowItem} last`}>
                <BodyText variant="label">Zip Code</BodyText>
                <BodyText el="div" variant="large">
                  {mainZipCode}
                </BodyText>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowItem}>
                <BodyText variant="label">City</BodyText>
                <BodyText el="div" variant="large">
                  {mainCity}
                </BodyText>
              </div>
              <div className={`${classes.rowItem} last`}>
                <BodyText variant="label">State</BodyText>
                <BodyText el="div" variant="large">
                  {stateName}
                </BodyText>
              </div>
            </div>
          </div>

          <Divider />

          <div className={classes.row}>
            <H3>Billing Address</H3>

            {hasWlBillingAddressRights && (
              <Button color="primary" onClick={onEdit}>
                <Edit className={classes.icon} />
                Edit Address
              </Button>
            )}
          </div>

          <div>
            <div className={`${classes.row} top`}>
              <div className={classes.rowItem}>
                <BodyText variant="label">Street</BodyText>
                <BodyText el="div" variant="large">
                  {warehouse.billingAddress}
                </BodyText>
              </div>
              <div className={`${classes.rowItem} last`}>
                <BodyText variant="label">Zip Code</BodyText>
                <BodyText el="div" variant="large">
                  {warehouse.billingZipCode}
                </BodyText>
              </div>
            </div>
            <div className={`${classes.row} top`}>
              <div className={classes.rowItem}>
                <BodyText variant="label">City</BodyText>
                <BodyText el="div" variant="large">
                  {warehouse.billingCity}
                </BodyText>
              </div>
              <div className={`${classes.rowItem} last`}>
                <BodyText variant="label">State</BodyText>
                <BodyText el="div" variant="large">
                  {billingStateName}
                </BodyText>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowItem}>
                <BodyText variant="label">Phone</BodyText>
                <BodyText el="div" variant="large">
                  {warehouse.billingPhone}
                </BodyText>
              </div>
              <div className={`${classes.rowItem} last`}>
                <BodyText variant="label">Email</BodyText>
                <BodyText el="div" variant="large">
                  {warehouse.billingEmail}
                </BodyText>
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {addEditDialogContact && (
        <AddWarehousePOCDialog
          warehouse={warehouse}
          onClose={() => setAddEditDialogContact(false)}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setIsUserDialogOpen={setIsUserDialogOpen}
          setHideContactForm={setHideContactForm}
        />
      )}

      {(selectedUser || isUserDialogOpen) && (
        <AddEditUserDialog
          selectedUser={selectedUser}
          warehouse={warehouse}
          warehouses={warehouses}
          onClose={() => {
            setSelectedUser(null);
            setIsUserDialogOpen(false);
          }}
          isNoRoles={!isUserAdminRights}
          isWlPocForm
          hideContactForm={hideContactForm}
          setHideContactForm={setHideContactForm}
          setAddEditDialogContact={setAddEditDialogContact}
        />
      )}
    </div>
  );
};

export default WarehouseLocationCard;
