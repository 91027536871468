import { useState, useCallback, useEffect, useRef } from 'react';

export const useAsyncCallback = (asyncFn, initialVal = {}) => {
  const fn = useCallback(asyncFn);
  const [result, setResult] = useState(initialVal);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const callAsyncFn = async (...args) => {
    setLoading(true);
    setError(undefined);
    try {
      setResult(await fn(...args));
    } catch (err) {
      console.error(err);
      setError(err);
    }
    setLoading(false);
  };

  return [{ result, isLoading, error }, callAsyncFn];
};
// For backwards compatibility with first parts of the app that use default export
export default useAsyncCallback;

export const useExperimentalAsync = (asyncFn, initialVal = {}, deps = []) => {
  const fn = useCallback(asyncFn, deps);
  const [result, setResult] = useState(initialVal);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  // cancellable effect, dependencies are asyncFn and dependencies it may have
  useEffect(() => {
    let cancelled = false;
    const doAsyncFn = async () => {
      setLoading(true);
      setError(undefined);
      try {
        const data = await fn();
        if (!cancelled) setResult(data);
      } catch (err) {
        if (!cancelled) setError(err);
      }
      if (!cancelled) setLoading(false);
    };
    doAsyncFn();
    // clean up function
    return () => {
      cancelled = true;
    };
  }, [fn]);

  return [{ result, isLoading, error }];
};

export const useAsyncNewCallback = (asyncFn, initialVal) => {
  const [result, setResult] = useState(initialVal);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const mountedRef = useRef(true);
  const callAsyncFn = async (...params) => {
    setLoading(true);
    try {
      const response = await asyncFn(...params);
      if (!mountedRef.current) {
        return null;
      }
      setResult(response);
      setLoading(false);
      return response;
    } catch (err) {
      if (!mountedRef.current) {
        return null;
      }
      setError(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [{ result, isLoading, error }, callAsyncFn];
};
