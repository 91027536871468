import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const getThemeStyles = ({ variant, size, theme }) => {
  const base = {
    backgroundColor: theme.palette.primary.main,
    width: size || theme.spacing(5),
    height: size || theme.spacing(5),
    fontSize: Boolean(size) && size > 48 ? 25 : 16,
    lineHeight: `${size || theme.spacing(5)}px`,
  };
  switch (variant) {
    case 'warehouse': {
      return {
        ...base,
        width: size || 41,
        height: size || 41,
        backgroundColor: '#EBEBEB',
        lineHeight: '44px',
      };
    }
    // Add more variants here if needed.
    default: {
      return base;
    }
  }
};

const useStyles = makeStyles({
  root: {
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: ({ avatarStyles }) => avatarStyles.backgroundColor,
    color: 'white',
    width: ({ avatarStyles }) => avatarStyles.width,
    height: ({ avatarStyles }) => avatarStyles.height,
    fontSize: ({ avatarStyles }) => avatarStyles.fontSize,
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: ({ avatarStyles }) => avatarStyles.lineHeight,
    textTransform: 'uppercase',
  },
});

const Avatar = ({ user, children, size, variant }) => {
  const theme = useTheme();
  const avatarStyles = getThemeStyles({ size, variant, theme });
  const classes = useStyles({ avatarStyles });

  const { firstName, lastName } = user || {};

  return (
    <div className={classes.root}>
      {Boolean(firstName) && firstName.charAt(0)}
      {Boolean(lastName) && lastName.charAt(0)}
      {Boolean(children) && children}
    </div>
  );
};
export default Avatar;
