import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css';

export const MASONRY_GUTTER = 16;

const useStyles = makeStyles(theme => ({
  masonry: {
    marginLeft: -MASONRY_GUTTER, // gutter size offset
    marginBottom: 16,
    width: 'auto',
    '& > *': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto', // gutter size offset
    },
  },
  masonryColumn: {
    paddingLeft: MASONRY_GUTTER, // gutter size
    backgroundClip: 'padding-box',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      paddingLeft: 'unset !important',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const MasonryContainer = ({ className, columnClassName, children, columns }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Masonry
      breakpointCols={{
        default: columns || 2,
        [theme.breakpoints.values.md]: 1,
      }}
      className={`${classes.masonry} ${className}`}
      columnClassName={`${classes.masonryColumn} ${columnClassName}`}
    >
      {children}
    </Masonry>
  );
};
export default MasonryContainer;
