import React from 'react';
import { withStyles, Typography, Tooltip as HtmlTooltip } from '@material-ui/core';

const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#2A2A2A',
    color: theme.palette.white,
    maxWidth: 340,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '6px 7px 8px 1px rgba(176,176,176,1)',
  },
  arrow: {
    color: '#2A2A2A',
  },
}))(HtmlTooltip);

const StyledTooltip = props => {
  let { title } = props;
  return <Tooltip title={<Typography>{title}</Typography>} {...props} />;
};
export default StyledTooltip;
