import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivilegeRoute = ({
  path,
  component,
  privilegesToCheck,
  userPrivileges,
  isEnabled = true,
  redirectTo = '/company/services',
  ...rest
}) => {
  // if enableRoute is undefined or true continue logic.
  if (!isEnabled) {
    return <Redirect to={redirectTo} />;
  }

  // Check the provided userPrivileges for route access.
  if (!privilegesToCheck.some(privilegeName => userPrivileges[privilegeName])) {
    return <Redirect to={redirectTo} />;
  }

  return <Route path={path} component={component} {...rest} />;
};

export default PrivilegeRoute;
