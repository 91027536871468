import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import moverImg from '../../images/mover.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(12)}px)`,
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
  sideImage: {
    objectFit: 'cover',
    position: 'fixed',
    top: 0,
    bottom: 0,
    zIndex: -1,
    width: '50%',
    height: '100vh',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-3),
    },
  },
}));

export default ({ component: Component }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} alignItems="center">
      <Hidden smDown>
        <Grid item md={6}>
          <img src={moverImg} className={classes.sideImage} alt="side_image" />
        </Grid>
        <Grid item md={1} />
      </Hidden>
      <Grid item md={4} xs={12}>
        <Component />
      </Grid>
    </Grid>
  );
};
