import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  h2: {
    margin: 0,
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 700,
    color: 'black',
  },
});

const H2 = ({ className, children, ...props }) => {
  const classes = useStyles();

  return (
    <h2 className={`${classes.h2} ${className}`} {...props}>
      {children}
    </h2>
  );
};

export default H2;
