import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';

import { BodyText } from '../../common';
import PocCardSummary from '../PointsOfContact/ContactCard/PocCardSummary';
import PocCardDetails from '../PointsOfContact/ContactCard/PocCardDetails';
import { getWarehouseRoles } from '../PointsOfContact/ContactCard/WarehouseCard';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    overflowY: 'auto',
    height: '100%',
    padding: theme.spacing(3, 4),
    '& hr': {
      margin: theme.spacing(1, 0, 3),
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > :last-child': {
      marginLeft: 'auto',
    },
  },
  title: {
    '& .title': {
      fontSize: 26,
      lineHeight: '33px',
    },
    '& .subTitle': {
      fontSize: 20,
      lineHeight: '26px',
    },
  },
  button: {
    fontSize: 17,
    padding: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 17,
    fontWeight: 700,
  },
  details: {
    margin: theme.spacing(3, 0),
    '& .details-left': {
      width: '50%',
    },
    '& .details-right': {
      width: '50%',
      paddingLeft: theme.spacing(3),
    },
  },
  warehouse: {
    marginTop: 8,
    '& > h6': {
      fontSize: 16,
      fontStyle: 'italic',
    },
  },
  warehouseTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '24px',
    color: '#1A1A1A',
  },
}));

const WarehouseContactAddingCard = ({ user, warehouses, setHideContactForm }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div className={classes.userInfo}>
        <PocCardSummary user={user} className={classes.title} avatarSize={64} />
        <Button className={classes.button} color="primary" onClick={() => setHideContactForm(false)}>
          <Edit className={classes.icon} />
          Edit Contact
        </Button>
      </div>
      <PocCardDetails user={user} className={classes.details} />
      <div>
        <BodyText variant="label">Warehouses</BodyText>
        {user.warehouseLocationIds?.length ? (
          user.warehouseLocationIds.map(warehouseId => {
            const warehouse = warehouses?.find(warehouse => warehouse.id === warehouseId);
            if (!warehouse) return null;

            const { mainCity, mainState } = warehouse;
            const roles = getWarehouseRoles(user, warehouses, warehouseId);
            return (
              <div key={warehouseId} className={classes.warehouse}>
                <Typography className={classes.warehouseTitle} variant="body1">
                  {`${mainCity}, ${mainState}`}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {roles.join(', ')}
                </Typography>
              </div>
            );
          })
        ) : (
          <Typography className={classes.warehouseTitle} variant="body1">
            Not related to warehouses yet.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default WarehouseContactAddingCard;
