import React from 'react';

import { ServiceSection } from '../index';

const HireCheckedLabor = ({ indexInColumn }) => {
  return (
    <ServiceSection
      indexInColumn={indexInColumn}
      description={
        <>
          <b>Hire background checked labor</b> and access our full dispatch and scheduling platform.
        </>
      }
    />
  );
};

export default HireCheckedLabor;
