import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import CompanyDocumentsSection from './CompanyDocumentsSection';
import WarehouseDocumentSection from './WarehouseDocumentSection';
import PageHeader from '../../PageHeader';

import { hasAdminRights } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(8),
  },
}));

const Index = () => {
  const classes = useStyles();
  const isViewerAdmin = useSelector(hasAdminRights);

  return (
    <div className={classes.root}>
      <PageHeader title="Review and Update Documentation" subtitle="Add and edit company or warehouses documents" />
      {isViewerAdmin && <CompanyDocumentsSection />}
      <WarehouseDocumentSection />
    </div>
  );
};

export default Index;
