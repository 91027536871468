import React from 'react';
import { Container, CircularProgress, Typography, Box, Button } from '@material-ui/core';

import { auth, functions } from '../../firebase';
import { useExperimentalAsync } from '../../hooks/useAsync';

const verifyEmail = async code => {
  const { uid, email } = auth.currentUser || {};
  await auth.applyActionCode(code);
  await functions.httpsCallable('sendNewUserNotificationToAdmins')({
    uid,
    email,
  });
  return true;
};

export default ({ actionCode }) => {
  const [{ result: verified, isLoading: isVerifying, error }] = useExperimentalAsync(
    () => verifyEmail(actionCode),
    false
  );

  return (
    <>
      <Typography variant="subtitle1" color="textSecondary">
        {isVerifying && 'Please wait while verification completes'}
        {verified && 'Verification done, click continue'}
        {error && error.message}
      </Typography>
      <Container maxWidth="md">
        <Box textAlign="center" marginTop={2}>
          {isVerifying && <CircularProgress size={96} />}
          {(verified || error) && (
            <Button component="a" href={`${window.origin}/`} variant="contained" color="primary">
              Continue
            </Button>
          )}
        </Box>
      </Container>
    </>
  );
};
