import React from 'react';
import { Grid, IconButton, makeStyles, Divider } from '@material-ui/core';
import RegisterButton from '../../components/BookingAgent/RegisterButton';
import { Modal } from '../../components/modal/Modal';
import { Close as CloseIcon } from '@material-ui/icons';
import LearnMoreSteps from '../../components/Services/LearMoreSteps';
import { H3 } from '../../components/common/text';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    width: '20px',
    marginLeft: 'auto',
    backgroundColor: '#3C96D9',
    color: 'white',

    '&:hover': {
      backgroundColor: 'rgb(42, 105, 151)',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    fill: theme.palette.grey[400],
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export const ReserveModal = ({ onClose }) => {
  const classes = useStyles();

  const steps = [
    {
      header: 'You will receive an email for each segment you reserved with a Booking ID Number.',
    },
    {
      header: 'Please reply to the email with the requested shipper information so we can proceed with the shipment.',
    },
  ];

  const history = useHistory();
  const redirectToForm = () => {
    history.push('/company/booking-agent/get-pricing');
  };
  return (
    <Modal open>
      <Grid container spacing={0} wrap="nowrap" justify="flex-end">
        <IconButton size="small">
          <CloseIcon onClick={redirectToForm} className={classes.closeIcon} />
        </IconButton>
      </Grid>
      <Grid item md={7}>
        <H3 className={classes.header}>Thank you for reserving the selected quotes</H3>
      </Grid>
      <Divider className={classes.divider} />
      <LearnMoreSteps stepStyle={{ margin: '25px 0' }} steps={steps} header="What happens next?" />
      <RegisterButton labelBtn="Continue" onClick={redirectToForm} className={classes.button} />
    </Modal>
  );
};
