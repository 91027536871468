import React, { useState, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Typography, Grid, makeStyles, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Select } from '../../../FormComponents';
import { AUTO_TRANSPORT_GET_YEARS_DATA } from '../../constants';
import { getAutoTransportServiceData } from './helpers';

const useStyles = makeStyles(theme => ({
  inlineAdds: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *:last-child': {
      width: '57em',
    },
  },
  inBlock: {
    display: 'block',
  },
  description: {
    fontSize: theme.spacing(1.8),
    marginRight: '1em',
    margin: 'revert',
  },
  addField: {
    fontSize: theme.spacing(1.8),
    marginRight: '1em',
    margin: 'revert',
    textDecorationLine: 'underline',
  },
  iconButton: {
    margin: theme.spacing(0, 0, 0, -6),
    position: 'absolute',
  },
  buttonContainer: {
    padding: theme.spacing(0),
    '&:hover': {
      background: 'none',
    },
  },
}));

const getMakes = async (VehicleYear, setAutoTransportApi) => {
  const data = await getAutoTransportServiceData({ VehicleYear });

  const vehicles = data.VehicleMake.reduce((acc, { VehicleMake }) => ({ ...acc, [VehicleMake]: undefined }), {});

  setAutoTransportApi(prevState => ({
    ...prevState,
    [VehicleYear]: {
      ...vehicles,
    },
  }));
};

const getModels = async (VehicleYear, VehicleMake, setAutoTransportApi) => {
  const data = await getAutoTransportServiceData({ VehicleYear, VehicleMake });

  const models = data?.VehicleModel?.map?.(({ VehicleModel }) => VehicleModel);
  if (models) {
    setAutoTransportApi(prevState => ({
      ...prevState,
      [VehicleYear]: {
        ...prevState[VehicleYear],
        [VehicleMake]: models,
      },
    }));
  }
};

const AutoTransport = ({ item, index, remove, parentName, autoTransportApi, setAutoTransportApi }) => {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  const yearWatch = watch(`${parentName}.${index}.year`);
  const makeWatch = watch(`${parentName}.${index}.make`);

  useEffect(() => {
    if (yearWatch && !autoTransportApi?.[yearWatch]) {
      getMakes(yearWatch, setAutoTransportApi);
    }
  }, [yearWatch, setAutoTransportApi, autoTransportApi]);

  useEffect(() => {
    if (makeWatch && !autoTransportApi?.[yearWatch]?.[makeWatch]) {
      getModels(yearWatch, makeWatch, setAutoTransportApi);
    }
  }, [yearWatch, makeWatch, setAutoTransportApi, autoTransportApi]);

  return (
    <Grid item xs={12} className={classes.inlineAdds}>
      <IconButton aria-label="delete" className={classes.iconButton} onClick={() => remove(index)}>
        <DeleteIcon color="error" />
      </IconButton>
      <Grid container>
        <Typography className={classes.description}>Year:</Typography>
        <Select
          options={AUTO_TRANSPORT_GET_YEARS_DATA}
          placeholder="Select"
          name={`${parentName}.${index}.year`}
          onChange={() => {
            setValue(`${parentName}.${index}.make`, '');
            setValue(`${parentName}.${index}.model`, '');
          }}
          control={control}
          rules={{ required: 'Select Year' }}
          defaultValue={item.year}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.description}>Make:</Typography>
        <Select
          disabled={!yearWatch || !autoTransportApi?.[yearWatch]}
          options={Object.keys(autoTransportApi?.[yearWatch] || {})}
          onChange={() => setValue(`${parentName}.${index}.model`, '')}
          placeholder="Select"
          name={`${parentName}.${index}.make`}
          control={control}
          rules={autoTransportApi?.[yearWatch] && { required: 'Select Make' }}
          defaultValue={item.make}
        />
      </Grid>
      <Grid container>
        <Typography className={classes.description}>Model:</Typography>
        <Select
          disabled={!yearWatch || !makeWatch || !autoTransportApi?.[yearWatch]?.[makeWatch]}
          placeholder="Select"
          options={autoTransportApi?.[yearWatch]?.[makeWatch] || []}
          name={`${parentName}.${index}.model`}
          control={control}
          rules={autoTransportApi?.[yearWatch] && { required: 'Select Model' }}
          defaultValue={item.model}
        />
      </Grid>
    </Grid>
  );
};

const AutoTransportFields = ({ parentName }) => {
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({ name: parentName });

  const [autoTransportApi, setAutoTransportApi] = useState({});

  return (
    <Grid item xs={12} className={classes.inBlock}>
      {fields.map((item, index) => (
        <AutoTransport
          key={item.id}
          item={item}
          index={index}
          remove={remove}
          parentName={parentName}
          autoTransportApi={autoTransportApi}
          setAutoTransportApi={setAutoTransportApi}
        />
      ))}

      {fields.length < 21 && (
        <Button
          className={classes.buttonContainer}
          color="primary"
          onClick={() => append({ year: '', model: '', make: '' })}
        >
          <Typography className={classes.addField}>Add Auto Transportation</Typography>
        </Button>
      )}
    </Grid>
  );
};

export default AutoTransportFields;
