import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';

import { useController } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formHelperText: {
    fontSize: '0,7rem',
    textAlign: 'center',
    marginInline: 'auto',
    position: 'absolute',
    top: '56px',
  },
}));

const FormSelect = ({ options = [], label, control, name, rules, defaultValue, disabled, ...rest }) => {
  const classes = useStyles();
  const {
    field,
    formState: { errors },
  } = useController({ control, name, rules, defaultValue: defaultValue ?? '' });

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} error={Boolean(errors[name])} {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select inputProps={field} label={label} disabled={disabled} autoWidth>
        {Boolean(options) &&
          options.map(element => (
            <MenuItem key={element.name} value={element.value} disabled={element.disabled}>
              {element.name.replace(/([a-z])([A-Z])/g, '$1 $2').replace('_', ' ')}
            </MenuItem>
          ))}
      </Select>

      <FormHelperText className={classes.formHelperText}>{errors[name] && 'Is requiered'}</FormHelperText>
    </FormControl>
  );
};

export default FormSelect;
