import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ExpansionPanel, ExpansionPanelActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  isAdmin,
  hasAdminRights,
  getWarehouseIdsAdminRights,
  getUid,
  hasPocAccess,
} from '../../../../store/selectors/user';
import { useAsyncCallback } from '../../../../hooks/useAsync';
import AccessButtons from './AccessButtons';
import {
  ContactEditButton,
  ContactDeleteButton,
  SummaryInfoCard,
  ExpansionDetailsCard,
  PocRoles,
} from './index.components';

import { deletePoc, approveUser } from '../actions';
import { getCompany } from '../../../../store/selectors/company';
import { ROLE_NAMES } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: `${theme.spacing(1.5)}px !important`,
  },
}));

const CompanyCard = ({ user, className, onEdit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isViewerAdmin = useSelector(hasAdminRights);
  const viewerWarehouseIdsAdminRights = useSelector(getWarehouseIdsAdminRights);
  const viewerId = useSelector(getUid);
  const company = useSelector(getCompany);
  const isUserAdmin = useSelector(state => isAdmin(state, user.id));
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isUserHasPrivilege = useSelector(hasPocAccess);

  const [{ isLoading: isApproving }, performApproval] = useAsyncCallback(approveUser);
  const [{ result: deleteUser, isLoading: isDeleting }, performDelete] = useAsyncCallback(() => deletePoc(user));

  const { primaryContact, secondaryContact, invoicingContact } = company;
  const userRoles = [
    isUserAdmin && ROLE_NAMES.admin.label,
    primaryContact === user.id && ROLE_NAMES.primary.label,
    secondaryContact === user.id && ROLE_NAMES.secondary.label,
    invoicingContact === user.id && ROLE_NAMES.invoicing.label,
  ].filter(role => Boolean(role));

  useEffect(() => {
    if (!!deleteUser.id) {
      setDeleteDialogOpen(false);
    }
  }, [deleteUser, dispatch]);

  const isContact = user.id.includes('static');
  const needsApproval = isViewerAdmin && !isUserAdmin && !isContact && !user.approvedByAdmin;

  // User is authorized to have some access of contact card actions
  // if viewer has Administrator company role or specific role access to own card
  // or Administrator WH role related to the warehouse
  const isUserAuthorized =
    isViewerAdmin ||
    (isUserHasPrivilege && viewerId === user.id) ||
    user.warehouseLocationIds?.some?.(warehouseId => viewerWarehouseIdsAdminRights.includes(warehouseId));

  return (
    <div>
      <ExpansionPanel classes={{ rounded: `${classes.root} ${className}` }} defaultExpanded={needsApproval}>
        <SummaryInfoCard user={user} subInfo={userRoles} />
        <ExpansionDetailsCard user={user}>
          <PocRoles userRoles={userRoles} />
        </ExpansionDetailsCard>

        {isUserAuthorized && (
          <ExpansionPanelActions>
            {needsApproval ? (
              <AccessButtons
                user={user}
                isApproving={isApproving}
                performApproval={performApproval}
                isDeleting={isDeleting}
                performDelete={performDelete}
              />
            ) : (
              <>
                {isViewerAdmin && viewerId !== user.id && (
                  <ContactDeleteButton
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                    onClick={() => performDelete(user, false)}
                    isDeleting={isDeleting}
                  />
                )}
                <ContactEditButton onEdit={onEdit} />
              </>
            )}
          </ExpansionPanelActions>
        )}
      </ExpansionPanel>
    </div>
  );
};

export default CompanyCard;
