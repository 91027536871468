import React from 'react';
import classnames from 'classnames';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Typography, Grid, makeStyles, TextField, IconButton, Button, CircularProgress } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import DeleteIcon from '@material-ui/icons/Delete';
import { addDays, addYears } from 'date-fns';
import { DatePickerField } from '../../../FormComponents';
import { useExperimentalAsync } from '../../../../hooks/useAsync';
import { DEFAULT_PRICING_FIELDS } from '../../constants';
import SelectArrayFields from './SelectArrayFields';
import { getThirdPartyCompanyServices } from './helpers';
import BodyText from '../../../common/text/BodyText';
import SectionWrapper from '../../../wrappers/SectionWrapper';
import AddressField from '../../../FormComponents/AddressField/index';
import AutoTransportFields from './AutoTansportFields';

const useStyles = makeStyles(theme => ({
  subtileAfter: {
    margin: theme.spacing(3),
    padding: theme.spacing(1),
    display: 'flex',
  },
  subtitle: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  description: {
    fontSize: theme.spacing(1.8),
    marginRight: '1em',
    margin: 'revert',
    width: '15em',
  },
  fieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0, 6),
  },
  fieldsArrayContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inlineSubtitle: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
  },
  inBlock: {
    display: 'block',
    '& > *:last-child': {
      padding: theme.spacing(0, 3),
      fontSize: theme.spacing(1.4),
      marginTop: theme.spacing(-4),
    },
  },
  inputs: {
    width: '65% !important',
  },
  fieldTitle: {
    width: '210px',
    textAlign: 'center',
  },
  inline: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
    '& > *:last-child': {
      position: 'end',
      width: '15em',
    },
  },
  inlineLabels: {
    marginBottom: theme.spacing(3),
  },
  addAnOtherSegment: {
    margin: theme.spacing(2, 0, 0),
    textDecorationLine: 'underline',
    color: theme.palette.primary,
  },
  buttonContainer: {
    '&:hover': {
      background: 'none',
    },
  },

  accesorialLocations: {
    textAlign: 'center',
    margin: theme.spacing(3, 0),
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
}));

const handleSubtitle = currentIndex => {
  switch (currentIndex) {
    case 0:
      return 'Primary';
    case 1:
      return 'Secondary';
    case 2:
      return 'Tertiary';
    case 3:
      return 'Fourth';
    default:
      return '';
  }
};

const RequestedPackDeliveryDateField = ({ defaultValue, segmentIndex }) => {
  const { control, watch } = useFormContext();
  const dateWatch = watch(`${segmentIndex}.requested_pack_load_date`);

  return (
    <DatePickerField
      placeHolder="YYYY-MM-DD"
      defaultValue={defaultValue}
      control={control}
      minDate={dateWatch}
      name={`${segmentIndex}.requested_delivery_date`}
      rules={{
        required: false,
        validate: {
          checkPastDate: value => {
            if (value && addDays(new Date(value), 1).valueOf() < new Date().valueOf()) {
              return 'This date is in the past';
            }
          },
          checkValidDate: value => {
            if (dateWatch > value) {
              return 'The delivery date must be after the load date';
            }
          },
        },
      }}
    />
  );
};

const SegmentFields = () => {
  const classes = useStyles();

  const [
    {
      result: { originValues, destinationValues },
      isLoading,
    },
  ] = useExperimentalAsync(getThirdPartyCompanyServices, { originValues: [], destinationValues: [] });

  const {
    register,
    setError,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({ name: 'segments' });

  if (isLoading) {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size={20} />
      </Grid>
    );
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <SectionWrapper key={item.id} borderWeight={4} legend={`${handleSubtitle(index)} Segment`} fontSize={20}>
            <Grid
              item
              xs={12}
              md={6}
              className={classnames({
                [classes.inlineSubtitle]: index < 1,
                [classes.subtileAfter]: index >= 1,
              })}
            >
              {index !== 0 && (
                <IconButton aria-label="delete" onClick={() => remove(index)}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </Grid>
            <SectionWrapper padding="15px 40px 30px" legend="General">
              <Grid item className={classes.inline}>
                <Grid item xs={6} className={classes.inline}>
                  <Typography className={classes.description}>Weight * (Lbs):</Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    error={Boolean(errors?.segments?.[index]?.estimated_weight)}
                    helperText={errors?.segments?.[index]?.estimated_weight?.message}
                    defaultValue={item.estimated_weight}
                    inputProps={register(`segments.${index}.estimated_weight`, {
                      required: 'Required',
                      valueAsNumber: true,
                    })}
                  />
                </Grid>
                <Grid item xs={6} className={classes.inline}>
                  <Typography className={classes.description}>Cubic feet : </Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    error={Boolean(errors?.segments?.[index]?.cubic_feet)}
                    inputProps={register(`segments.${index}.cubic_feet`, {
                      required: false,
                      valueAsNumber: true,
                    })}
                    defaultValue={item.cubic_feet}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} md={12} className={classes.inline}>
                <Grid item xs={6} className={classes.inline}>
                  <Typography className={classes.description}>Origin Location * : </Typography>
                  <AddressField
                    control={control}
                    className={classes.inputs}
                    name={`segments.${index}.origin_formatted_string`}
                    mapPositionFieldName={`segments.${index}.origin_map_position`}
                    rules={{ required: 'Required' }}
                    setError={setError}
                    defaultValue={item.origin_formatted_string}
                  />
                </Grid>
                <Grid item xs={6} className={classes.inline}>
                  <Typography className={classes.description}>Destination Location * : </Typography>
                  <AddressField
                    control={control}
                    className={classes.inputs}
                    name={`segments.${index}.destination_formatted_string`}
                    mapPositionFieldName={`segments.${index}.destination_map_position`}
                    rules={{ required: 'Required' }}
                    setError={setError}
                    defaultValue={item.destination_formatted_string}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} md={12} className={classes.inline}>
                <Grid item xs={6} md={6} className={classes.inline}>
                  <Typography className={classes.description}>Requested Pack/Load Date * : </Typography>
                  <DatePickerField
                    placeHolder="YYYY-MM-DD"
                    control={control}
                    defaultValue={item.requested_pack_load_date}
                    maxDate={addYears(new Date(), 1)}
                    name={`segments.${index}.requested_pack_load_date`}
                    rules={{
                      required: 'Required',
                      validate: {
                        checkPastDate: value => {
                          if (value && addDays(new Date(value), 1).valueOf() < new Date().valueOf()) {
                            return 'This date is in the past';
                          }
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} className={classes.inBlock}>
                  <Grid item xs={12} md={12} className={classes.inline}>
                    <Typography className={classes.description}>Requested Delivery Date: </Typography>
                    <RequestedPackDeliveryDateField
                      defaultValue={item.requested_delivery_date}
                      segmentIndex={`segments.${index}`}
                    />
                  </Grid>
                  <p>(Leave blank if ASAP)</p>
                </Grid>
              </Grid>
            </SectionWrapper>
            <SectionWrapper legend="Accesorials">
              <Grid className={`${classes.inline}`}>
                <Grid xs={6} item className={classes.inlineLabels}>
                  <Grid item className={classes.inline}>
                    <BodyText
                      styles={{ width: 440, display: 'flex', justifyContent: 'flex-end' }}
                      variant="large"
                      size={24}
                    >
                      <div className={classes.fieldTitle}>Origin</div>
                    </BodyText>
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Shuttle(approx. yards): </Typography>

                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.origin_shuttle)}
                      helperText={errors?.segments?.[index]?.origin_shuttle?.message}
                      defaultValue={item.origin_shuttle}
                      inputProps={register(`segments.${index}.origin_shuttle`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Long Carry (approx feet): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.origin_long_carry)}
                      helperText={errors?.segments?.[index]?.origin_long_carry?.message}
                      defaultValue={item.origin_long_carry}
                      inputProps={register(`segments.${index}.origin_long_carry`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Stair Carry (flights): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.origin_stair_carry)}
                      helperText={errors?.segments?.[index]?.origin_stair_carry?.message}
                      defaultValue={item.origin_stair_carry}
                      inputProps={register(`segments.${index}.origin_stair_carry`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>

                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Elevators (floors): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      min={0}
                      error={Boolean(errors?.segments?.[index]?.origin_elevator)}
                      helperText={errors?.segments?.[index]?.origin_elevator?.message}
                      defaultValue={item.origin_elevator}
                      inputProps={register(`segments.${index}.origin_elevator`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6} className={classes.inlineLabels}>
                  <Grid item className={classes.inline}>
                    <BodyText
                      styles={{ width: 440, display: 'flex', justifyContent: 'flex-end' }}
                      variant="large"
                      width={440}
                      size={24}
                    >
                      <div className={classes.fieldTitle}>Destination</div>
                    </BodyText>
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Shuttle(approx. yards): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.destination_shuttle)}
                      helperText={errors?.segments?.[index]?.destination_shuttle?.message}
                      defaultValue={item.destination_shuttle}
                      inputProps={register(`segments.${index}.destination_shuttle`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Long Carry (approx feet): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.destination_long_carry)}
                      helperText={errors?.segments?.[index]?.destination_long_carry?.message}
                      defaultValue={item.destination_long_carry}
                      inputProps={register(`segments.${index}.destination_long_carry`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Stair Carry (flights): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.destination_stair_carry)}
                      helperText={errors?.segments?.[index]?.destination_stair_carry?.message}
                      defaultValue={item.destination_stair_carry}
                      inputProps={register(`segments.${index}.destination_stair_carry`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>

                  <Grid item className={classes.inline}>
                    <Typography className={classes.description}>Elevators (floors): </Typography>
                    <TextField
                      variant="outlined"
                      type={'number'}
                      error={Boolean(errors?.segments?.[index]?.destination_elevator)}
                      helperText={errors?.segments?.[index]?.destination_elevator?.message}
                      defaultValue={item.destination_elevator}
                      inputProps={register(`segments.${index}.destination_elevator`, {
                        required: false,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </SectionWrapper>
            <SectionWrapper legend="Third-Party Services">
              <Grid className={classes.inline}>
                <SelectArrayFields
                  parentName={`segments.${index}.origin_third_party_services`}
                  label="Origin Third Party Service"
                  options={originValues}
                  isLoading={isLoading}
                  defaultValue={item.origin_third_party_services}
                  rules={{ required: 'Select a service' }}
                />
                <SelectArrayFields
                  parentName={`segments.${index}.destination_third_party_services`}
                  label="Destination Third Party Service"
                  options={destinationValues}
                  isLoading={isLoading}
                  defaultValue={item.destination_third_party_services}
                  rules={{ required: 'Select a service' }}
                />
              </Grid>
            </SectionWrapper>
            <SectionWrapper legend="Auto-Transport">
              <Grid x={12} className={classes.inline}>
                <AutoTransportFields parentName={`segments.${index}.auto_transport_quotes`} />
              </Grid>
            </SectionWrapper>
          </SectionWrapper>
        );
      })}
      {fields.length < 3 && (
        <Button
          color="primary"
          className={classes.buttonContainer}
          onClick={e => {
            e.preventDefault();
            append({ ...DEFAULT_PRICING_FIELDS[0], id: uuidV4() });
          }}
        >
          <Typography variant="h6" className={classes.addAnOtherSegment}>
            Add Another Segment
          </Typography>
        </Button>
      )}
    </>
  );
};

export default SegmentFields;
