import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useController, useFormContext } from 'react-hook-form';

import { TextField } from '@material-ui/core';

import { mapsApiKey } from './credentials';
import { parseError } from '../helpers';

const AddressField = ({ control, rules, name, setError, mapPositionFieldName, defaultValue, ...rest }) => {
  const {
    field: { onChange },
    formState: { errors },
  } = useController({ control, rules, name, defaultValue: defaultValue || '' });
  const { setValue } = useFormContext();

  const error = parseError(errors, name);

  const { ref: googleRef } = usePlacesWidget({
    apiKey: mapsApiKey,
    onPlaceSelected: place => onPlaceSelected(place),
    options: {
      componentRestrictions: { country: 'us' },
      types: ['geocode'],
    },
  });

  /**
   * Get the locality and set the locality input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  const getCity = addressArray => {
    const matchedAddress = addressArray.find(address => {
      return address.types[0] && 'locality' === address.types[0];
    });
    return matchedAddress?.long_name || '';
  };

  /**
   * Get the zip code and set the zip code input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  const getZipCode = addressArray => {
    const matchedAddress = addressArray.find(address => {
      return address.types[0] && 'postal_code' === address.types[0];
    });
    return matchedAddress?.long_name || '';
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  const onPlaceSelected = place => {
    const addressArray = place.address_components;
    if (!place.address_components) {
      setError(name, { type: 'manual', message: 'Select a google address options' });
      return;
    }
    const zip = getZipCode(addressArray);
    const city = getCity(addressArray);
    if (!city && !zip) {
      setError(name, { type: 'manual', message: 'Select an address, city or ZIP & State' });
      return;
    }

    const latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    const address = zip !== '' ? place.formatted_address : place.formatted_address.match(/[^,]+,[^,]+/g),
      mapPosition = {
        lat: latValue,
        lng: lngValue,
      };
    onChange(zip !== '' ? address : address[0]);
    setValue(mapPositionFieldName, mapPosition);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      inputRef={googleRef}
      defaultValue={defaultValue}
      placeholder="Enter Address, City & State, or ZIP & State"
      error={Boolean(error?.message)}
      helperText={error?.message}
      {...rest}
    />
  );
};
export default AddressField;
