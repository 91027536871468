import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getCompany } from '../../../store/selectors/company';
import { useSelector } from 'react-redux';
import { POC_FIELDS_ARRAY } from './POCSectionFields';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    overflowY: 'auto',
    height: '100%',
    padding: theme.spacing(3, 5),
    '& > *': {
      marginBottom: theme.spacing(4.5),
      '& input': {
        padding: theme.spacing(1.5, 0),
      },
    },
  },
}));

const PointOfContactForm = ({ user, setUser, errors, setErrors, className }) => {
  const classes = useStyles();
  const company = useSelector(getCompany);

  // keys should match the ROLE_NAMES constant's keys

  const isRealUser = Boolean(company.contacts[user.id]) && !user.id.includes('static');

  return (
    <div className={classes.contentContainer}>
      {POC_FIELDS_ARRAY.map(field => {
        const disabled = field.name === 'email' && isRealUser;
        // If there's an error, show message as helper text. Otherwise, for email field of authed users, explain why it's disabled.
        const helperText =
          errors[field.name] ||
          (disabled && 'Email addresses for point-of-contacts with an actual account may not be modified.') ||
          '';

        return (
          <TextField
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors[field.name]) || false}
            className={className}
            helperText={helperText}
            disabled={disabled}
            key={field.name}
            name={field.name}
            label={field.label}
            required={field.required}
            value={field.format ? field.format(user[field.name]) : user[field.name] || ''}
            onChange={e => {
              setUser({ ...user, [field.name]: field.sanitize ? field.sanitize(e.target.value) : e.target.value });
              setErrors(prevState => ({ ...prevState, [field.name]: '' }));
            }}
          />
        );
      })}
    </div>
  );
};

export default PointOfContactForm;
