import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExpansionPanel, ExpansionPanelActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { functions } from '../../../../firebase';
import { hasAdminRights, getUid, getWarehouseIdsAdminRights } from '../../../../store/selectors/user';
import { getCompany } from '../../../../store/selectors/company';
import { hasWlPocAccess, isAdmin, hasWlAdminRights } from '../../../../store/selectors/user';
import { useAsyncCallback } from '../../../../hooks/useAsync';
import AccessButtons from './AccessButtons';
import {
  ContactEditButton,
  ContactDeleteButton,
  SummaryInfoCard,
  ExpansionDetailsCard,
  PocRoles,
} from './index.components';
import { getWarehouseCardNames } from '../helpers/utils';
import { deletePoc, approveUser } from '../actions';
import { ROLE_WL_NAMES } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: `${theme.spacing(1.5)}px !important`,
  },
}));

const deleteWlPoc = async (user, companyId, warehouseId) => {
  await functions.httpsCallable('deleteWlPoc')({
    uid: user.id,
    companyId,
    // if warehouseId is undefined, will remove all relations
    warehouseId,
  });

  return { id: user.id };
};

export const getWarehouseRoles = (user, warehouses, warehouseId) => {
  const warehouse = warehouses.find?.(warehouse => warehouse.id === warehouseId);
  const warehouseRoles = warehouse?.contacts?.[user.id];

  const roles = [
    warehouseRoles?.adminContact && ROLE_WL_NAMES.admin.label,
    warehouseRoles?.primaryContact && ROLE_WL_NAMES.primary.label,
    warehouseRoles?.escalationContact && ROLE_WL_NAMES.escalation.label,
    warehouseRoles?.operationsContact && ROLE_WL_NAMES.operations.label,
    warehouseRoles?.invoicingContact && ROLE_WL_NAMES.invoicing.label,
  ].filter(role => Boolean(role));

  return roles;
};

const WlContactCard = ({
  user,
  className,
  onEdit = null,
  warehouseId,
  warehouses = {},
  isShowAllWlRelated,
  isShowWarehouseRoles,
  isWlRealUser,
}) => {
  const classes = useStyles();
  const isViewerAdmin = useSelector(hasAdminRights);
  const isViewerWlAdmin = useSelector(hasWlAdminRights(warehouseId));
  const viewerWarehouseIdsAdminRights = useSelector(getWarehouseIdsAdminRights);
  const viewerId = useSelector(getUid);
  const company = useSelector(getCompany);
  const isUserHasPrivilege = useSelector(hasWlPocAccess(warehouseId));

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [{ isLoading: isApproving }, performApproval] = useAsyncCallback(approveUser);
  const [{ result: deleteUser, isLoading: isDeletingCompanyContact }, performCompanyDelete] = useAsyncCallback(() =>
    deletePoc(user)
  );
  const [
    { result: deleteWlUser, isLoading: isDeletingWarehouseContact },
    performWarehouseDelete,
  ] = useAsyncCallback(() => deleteWlPoc(user, company.id, warehouseId));

  // isWlRealUser is only provided on the poc section when seperating the user vs static
  // not per the agent warehouse card, otherwise will use the warehouse actions one.
  const performDeletePoc = isWlRealUser ? performCompanyDelete : performWarehouseDelete;
  const isDeletingPoc = isWlRealUser ? isDeletingCompanyContact : isDeletingWarehouseContact;

  // User is authorized to have some access of contact card actions
  // if viewer has Administrator company role or Administrator WH role for provided WarehouseId
  // or specific role access to own card or Administrator WH role related to the warehouse
  const isUserAuthorized =
    isViewerAdmin ||
    isViewerWlAdmin ||
    (isUserHasPrivilege && viewerId === user.id) ||
    user.warehouseLocationIds?.some?.(warehouseLocationId =>
      viewerWarehouseIdsAdminRights.includes(warehouseLocationId)
    );

  // If isShowWarehouseRoles is provided, show roles on ContactCard
  const userRoles = isShowWarehouseRoles && getWarehouseRoles(user, warehouses, warehouseId);
  const warehouseNames = getWarehouseCardNames(isShowAllWlRelated, user, warehouses);

  const isUserAdmin = useSelector(state => isAdmin(state, user.id));
  const needsApproval = isViewerAdmin && isWlRealUser && !isUserAdmin && !user.approvedByAdmin;

  useEffect(() => {
    if (deleteUser.id || deleteWlUser.id) {
      setDeleteDialogOpen(false);
    }
  }, [deleteUser, deleteWlUser]);

  return (
    <div>
      <ExpansionPanel classes={{ rounded: `${classes.root} ${className}` }} defaultExpanded={needsApproval}>
        <SummaryInfoCard user={user} subInfo={isShowAllWlRelated ? warehouseNames : userRoles} />
        <ExpansionDetailsCard user={user}>
          {isShowWarehouseRoles && <PocRoles user={user} userRoles={userRoles} />}
        </ExpansionDetailsCard>

        {isUserAuthorized && (
          <ExpansionPanelActions>
            {needsApproval ? (
              <AccessButtons
                user={user}
                isApproving={isApproving}
                performApproval={performApproval}
                isDeleting={isDeletingCompanyContact}
                performDelete={performCompanyDelete}
              />
            ) : (
              <>
                {isViewerAdmin && viewerId !== user.id && (
                  <ContactDeleteButton
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                    onClick={performDeletePoc}
                    isDeleting={isDeletingPoc}
                  />
                )}
                <ContactEditButton onEdit={onEdit} />
              </>
            )}
          </ExpansionPanelActions>
        )}
      </ExpansionPanel>
    </div>
  );
};

export default WlContactCard;
