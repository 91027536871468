import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Quote from '../../InstantQuote/Quote';
import { db } from '../../../../firebase';
import SectionWrapper from '../../../wrappers/SectionWrapper';
import { getCompanyWithoutContacts } from '../../../../store/selectors/company';
import { useSelector } from 'react-redux';

const RecentShipmentInquires = ({ setSelectedFullQuote }) => {
  const [fullquote, setFullQuote] = useState(null);
  const company = useSelector(getCompanyWithoutContacts);

  useEffect(() => {
    const onSub = db
      .collection('agent_companies')
      .doc(String(company.id))
      .collection('full_quotes')
      .orderBy('created_at', 'desc')
      .onSnapshot(async snapshot => {
        setFullQuote(snapshot.docs.map(doc => doc.data()));
      });
    return () => {
      onSub();
    };
  }, [company.id]);

  return (
    <SectionWrapper borderWeight={4} legend={'Recent Shipments Inquires'} fontSize={20}>
      <Grid container>
        {fullquote?.slice(0, 5).map(fullQuote => (
          <Quote fullQuote={fullQuote} setSelectedFullQuote={setSelectedFullQuote} key={fullQuote.id} />
        ))}
      </Grid>
    </SectionWrapper>
  );
};

export default RecentShipmentInquires;
