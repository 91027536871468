import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  pricingForm: {
    segments: [
      {
        id: '',
        weight: 0,
        requested_delivery_date: '',
        requested_pack_load_date: '',
        destination_third_party_services: [],
        origin_third_party_services: [],
        national_account_id: null,
        origin_formatted_string: '',
        destination_formatted_string: '',
        origin_map_position: null,
        destination_map_position: null,
        auto_transport_quotes: [],

        destination_shuttle: 0,
        destination_long_carry: 0,
        destination_stair_carry: 0,
        destination_elevator: 0,

        origin_shuttle: 0,
        origin_long_carry: 0,
        origin_stair_carry: 0,
        origin_elevator: 0,
      },
    ],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_PRICING_FORM:
      return {
        ...state,
        pricingForm: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
