import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

// Setup environment configuration
let config = {};
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config = {
    apiKey: 'AIzaSyD41p3FQGB6ZQ_bB_isfotayopmQoWoBhE',
    authDomain: 'walkboard-prod.firebaseapp.com',
    databaseURL: 'https://walkboard-prod.firebaseio.com',
    projectId: 'walkboard-prod',
    storageBucket: 'walkboard-prod.appspot.com',
    messagingSenderId: '739487860112',
    appId: '1:739487860112:web:c4fd300c86ec160bde9f15',
    measurementId: 'G-YBJVN5LPE0',
  };
} else {
  config = {
    apiKey: 'AIzaSyDdJZTRaBmfNdCqGPtsHDgMrDmPlk6e0A4',
    authDomain: 'walkboard-agent-portal-staging.firebaseapp.com',
    databaseURL: 'https://walkboard-agent-portal-staging.firebaseio.com',
    projectId: 'walkboard-agent-portal-staging',
    storageBucket: 'walkboard-agent-portal-staging.appspot.com',
    messagingSenderId: '366883892641',
    appId: '1:366883892641:web:7bcafb653ffc5f05956fe0',
  };
}

firebase.initializeApp(config);

export default firebase;
export const auth = firebase.auth();
export const functions = firebase.functions();
export const db = firebase.firestore();
export const storage = firebase.storage();

// TODO: unfortunately with local frontend dev right now, we still have to use remote firebase app
// because firebase emulator doesn't support Firebase Auth... Once we figure out a good workaround,
// ideally we'd still like to use local firestore instance for local dev.

// if (process.env.NODE_ENV === 'development') {
//   db.settings({
//     host: 'localhost:5000',
//     ssl: false,
//   })
// } else if (process.env.NODE_ENV === 'test') {
//   db.settings({
//     servicePath: undefined,
//     host: 'localhost:8081',
//     ssl: false,
//   });
// }
