import React from 'react';
import { Typography, Drawer as MuiDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 600,
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleContainer: {
    flexShrink: 0,
    padding: theme.spacing(2, 5),
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '31px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.primary.main,
    color: 'white',
  },
}));

const Drawer = ({ open, onClose, anchor, title, children, drawerClasses = {}, titleClasses }) => {
  const classes = useStyles();
  const paperClasses = Boolean(drawerClasses?.paper) ? { ...classes.root, ...drawerClasses.paper } : classes.root;

  return (
    <MuiDrawer
      anchor={anchor || 'right'}
      open={open}
      classes={{ ...drawerClasses, paper: paperClasses }}
      onClose={onClose}
    >
      {Boolean(title) && (
        <Typography variant="h5" className={`${classes.titleContainer} ${titleClasses}`}>
          {title}
        </Typography>
      )}
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
