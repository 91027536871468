import { useEffect, useRef } from 'react';

// This map helps tracks the tallest element across elements with the same specified key
const maxHeightMap = new Map();

// counter to help facilitate resetting of the map only once per window-resize across all elements
let globalResizeCount = 0;

export default (key, containerRef) => {
  // all elements need to keep a reset counter, to know if some other element already reset maxHeightMap.
  const resizeCount = useRef(0);

  // adds classname to retain easy access across component instances, when needing to set height (kind of cheating)
  const classStr = `sameHeight_${key}`;

  const setHeight = () => {
    containerRef.current.style.height = 'auto'; // first set the element's height back to natural state
    const thisHeight = containerRef.current?.clientHeight; // get the element's natural height
    const maxHeightForKey = maxHeightMap.get(key) || 0; // then get the currently tallest recorded height of elements with same key

    // then records and sets height of all same-key elements to the new tallest height.
    const newHeight = Math.max(thisHeight, maxHeightForKey);
    maxHeightMap.set(key, newHeight);
    document.querySelectorAll(`.${classStr}`).forEach(el => {
      el.style.height = `${newHeight}px`;
    });
  };

  // call setHeight on "this" element, when we discover other taller elements
  useEffect(() => {
    containerRef.current.classList.add(classStr); // apparently, can't use conditional dereferencing in an expression, only in assignment.
    setHeight();
  });

  // call setHeight when window size resets
  useEffect(() => {
    const resizeListener = () => {
      // If the local resize count is gonna be larger than the global, that means no other element
      // reset the global map yet. In that case, take up the responsibility to reset it.
      if (++resizeCount.current > globalResizeCount) {
        maxHeightMap.clear();
        globalResizeCount++;
      }
      setHeight();
    };

    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  });
};
