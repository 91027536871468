import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { functions, auth } from '../../firebase';
import useAsync, { useExperimentalAsync } from '../../hooks/useAsync';
import { getCollection } from '../../hooks/getCollection';
import { POC_FIELDS } from '../CompanyInfo/PointsOfContact/POCSectionFields';

const useStyles = makeStyles(theme => ({
  stepper: {
    width: '100%',
    '& .MuiStepIcon-active': {
      color: '#3c96d9 !important;',
    },
    '& .MuiStepIcon-text': {
      display: 'none',
    },
    backgroundColor: 'transparent',
  },
  formGroup: {
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(30),
  },
  checkboxLabel: {
    marginTop: theme.spacing(3),
  },
}));

const signUp = async info => {
  const { data: token } = await functions.httpsCallable('signUp')(info);
  // TODO: do we wanna do email verification first?
  await auth.signInWithCustomToken(token); // This will automatically sign user in with their newly created account
  const user = auth.currentUser;
  await user.sendEmailVerification({ url: `${window.location.origin}/login` }); // continueUrl after verification
};

const steps = ['Basic Info', 'Company', 'Credentials'];

export default () => {
  const classes = useStyles();
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [completed] = useState({});
  const [errors, setErrors] = useState({});
  const [termsChecked, setTermsChecked] = React.useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const [{ isLoading }, doSignup] = useAsync(() => signUp(info));
  const [{ result: companies }] = useExperimentalAsync(() => getCollection('frontend_companies'));

  const handleNext = () => {
    if (isFormStepValid(activeStep)) {
      const newActiveStep =
        activeStep === steps.length - 1 // It's the last step, but not all steps have been completed,
          ? // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const generateField = ({ fieldName, label, type = 'text', required = true, inputProps }) => (
    <TextField
      label={label}
      type={type}
      fullWidth
      required={required}
      value={info[fieldName]}
      variant="outlined"
      onChange={e => setInfo({ ...info, [fieldName]: e.target.value })}
      error={Boolean(errors[fieldName])}
      helperText={errors[fieldName]}
      inputProps={inputProps}
      className={classes.field}
    />
  );

  const validateEmail = async input => {
    const pattern = /\S+@\S+\.\S+/;
    const isEmailFormat = pattern.test(String(input).toLowerCase());

    if (!isEmailFormat) {
      setErrors({ ...errors, email: 'Please enter a correct email format' });
      return false;
    }

    const signInMethods = await auth.fetchSignInMethodsForEmail(input);
    if (signInMethods.length > 0) {
      setErrors({ ...errors, email: 'E-mail has been taken' });
      return false;
    }

    if (Boolean(info.companyId)) {
      const company = companies.find(c => c.id === info.companyId);
      if (Boolean(company.domain) && !input.includes(`@${company.domain}`)) {
        setErrors({ ...errors, email: 'Please use your company e-mail' });
        return false;
      }
    }

    const { email, ...newErrors } = errors;
    setErrors({ ...newErrors });
    return true;
  };

  const validatePasswords = () => {
    const { password, confirmPassword } = info;
    const passwordHelperText =
      'Password must be at least 8 characters and must have at least 3 of an uppercase letter, lowercase letter, number or special character';
    if (password.length < 8) {
      setErrors({ ...errors, password: passwordHelperText });
      return false;
    }

    const checks = [
      /[!@#$%^&*(),.?":{}|<>]/, // special character
      /(?=.*[a-z])/, // lowercase
      /(?=.*[A-Z])/, // uppercase
      /(?=.*[0-9])/, // digits
    ];

    const passedChecks = checks.reduce((totalPassed, currentCheck) => {
      if (currentCheck.test(password)) {
        return totalPassed + 1;
      }
      return totalPassed;
    }, 0);

    if (passedChecks < 3) {
      setErrors({
        ...errors,
        password: passwordHelperText,
      });
      return false;
    }

    if (password !== confirmPassword && confirmPassword.length > 0) {
      setErrors({ ...errors, password: 'Passwords do not match', confirmPassword: 'Passwords do not match' });
      return false;
    }

    const {
      password: omit, // eslint-disable-line no-unused-vars
      confirmPassword: alsoOmit, // eslint-disable-line no-unused-vars
      newErrors,
    } = errors;
    setErrors({ ...newErrors });
    return true;
  };

  const validateLength = (field, length, value) => {
    let actualValue = Boolean(value) ? value : info[field];

    if (field === 'phoneNumber') {
      actualValue = actualValue.replace(/\D+/g, '');
    }

    if (!actualValue || actualValue.length < length) {
      setErrors({ ...errors, [field]: `Please enter at least ${length} characters` });
      return false;
    }

    const { [field]: omit, ...newErrors } = errors;
    setErrors({ ...newErrors });
    return true;
  };

  const isFormStepValid = async step => {
    switch (step) {
      case 0:
        const isEmailValid = await validateEmail(info.email);
        return (
          isEmailValid &&
          validateLength('firstName', 1) &&
          validateLength('lastName', 1) &&
          validateLength('phoneNumber', 10)
        );
      case 1:
        return validateLength('companyName', 1);
      case 2:
        return validatePasswords();
      default:
        return false;
    }
  };

  const isStepValid = step => {
    // Pristine usually means empty AND untouched but since component doesn't track
    // that metadata, this means empty whether touched or not.
    const areFieldsPristine = fields => fields.some(field => info[field] === undefined || info[field] === '');
    switch (step) {
      case 0:
        return areFieldsPristine(['firstName', 'lastName', 'email', 'phoneNumber']);
      case 1:
        return areFieldsPristine(['companyName']);
      case 2:
        return areFieldsPristine(['password', 'confirmPassword']);
      default:
        return false;
    }
  };

  const validateAllFields = async () => {
    for (const step of steps.keys()) {
      const valid = await isFormStepValid(step);
      if (!valid) {
        setActiveStep(step);
        return false;
      }
    }
    return true;
  };

  const selectCompany = name => {
    const company = companies.find(c => c.name === name);
    const companyId = Boolean(company) ? company.id : undefined;
    setInfo({ ...info, companyId, companyName: name });
  };

  const creatingNewCompany = info.companyName && !info.companyId;
  const isFormDisabled = isLoading || isStepValid(activeStep) || Object.keys(errors).length > 0;
  return (
    <form
      className={classes.signupFieldsContainer}
      onSubmit={async e => {
        e.preventDefault();
        (await validateAllFields()) && doSignup();
      }}
    >
      <div className={classes.root}>
        <Stepper nonLinear className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <div className={classes.formGroup}>
            <Grid container spacing={1}>
              <Grid item className={classes.formFieldGrid} xs={6}>
                {generateField({
                  fieldName: 'firstName',
                  label: 'First Name',
                  type: 'text',
                  inputProps: {
                    onBlur: () => validateLength('firstName', 1),
                  },
                })}
              </Grid>
              <Grid item className={classes.formFieldGrid} xs={6}>
                {generateField({
                  fieldName: 'lastName',
                  label: 'Last Name',
                  type: 'text',
                  inputProps: {
                    onBlur: () => validateLength('lastName', 1),
                  },
                })}
              </Grid>
              <Grid item className={classes.formFieldGrid} xs={12}>
                {generateField({
                  fieldName: 'email',
                  label: 'Email',
                  type: 'email',
                  inputProps: { onBlur: e => validateEmail(e.target.value) },
                })}
              </Grid>
              <Grid item className={classes.formFieldGrid} xs={12}>
                <TextField
                  label="Phone"
                  type="text"
                  fullWidth
                  required
                  value={POC_FIELDS.phoneNumber.format(info.phoneNumber) || ''}
                  variant="outlined"
                  onChange={e => {
                    setInfo({ ...info, phoneNumber: POC_FIELDS.phoneNumber.sanitize(e.target.value) });
                    validateLength('phoneNumber', 10, e.target.value);
                  }}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber}
                  className={classes.field}
                />
              </Grid>
            </Grid>
          </div>
        ) : activeStep === 1 && Boolean(companies.length) ? (
          <div className={classes.formGroup}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={companies}
                  getOptionLabel={option => option.name || ''}
                  onChange={e => selectCompany(e.target.textContent)}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        fullWidth
                        onChange={e => selectCompany(e.target.value)}
                        onBlur={() => validateLength('companyName', 1)}
                        error={Boolean(errors.companyName)}
                        helperText={errors.companyName}
                      />
                    );
                  }}
                />
                {creatingNewCompany && (
                  <Typography variant="subtitle2" color="secondary">
                    This will create a new company named {info.companyName}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classes.formGroup}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {generateField({
                  fieldName: 'password',
                  label: 'Password',
                  type: 'password',
                  inputProps: {
                    onBlur: validatePasswords,
                  },
                })}
              </Grid>
              <Grid item xs={12}>
                {generateField({
                  fieldName: 'confirmPassword',
                  label: 'Confirm Password',
                  type: 'password',
                  inputProps: { onBlur: validatePasswords },
                })}
              </Grid>
            </Grid>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  required
                  name="terms"
                  onChange={event => setTermsChecked(event.target.checked)}
                  value={termsChecked}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Typography>
                  I have read and agree to the{' '}
                  <a href="https://www.walkboard.com/terms" target="_blank" rel="noopener noreferrer">
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.walkboard.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Typography>
              }
            />
          </div>
        )}
        {activeStep === steps.length - 1 ? (
          <Button fullWidth variant="contained" type="submit" color="primary" disabled={isFormDisabled}>
            {isLoading ? <CircularProgress size={21} /> : 'Create New Account'}
          </Button>
        ) : (
          <Button
            m={4}
            disabled={isFormDisabled}
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleNext}
            className={classes.button}
          >
            Next
          </Button>
        )}
      </div>
    </form>
  );
};
