import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ServiceSection } from '../index';
import LearnMoreDialog from '../LearnMoreDialog';
import { useAsyncNewCallback } from '../../../hooks/useAsync';
import { getCompany, isNationalAccountApprovedProvider } from '../../../store/selectors/company';
import { getWarehouseIdsAdminRights, hasAdminRights } from '../../../store/selectors/user/access/index';

const steps = [
  {
    header: 'You provide your company information including:',
    bullets: ['MC # for broker entity', 'Survey capabilities', 'Contact information'],
  },
  {
    header: 'Our team will review your application and provide you with SLA for you to review and fill out.',
  },
  {
    header: 'You return completed documents.',
  },
  {
    header:
      'Our team reviews and if approved you will be able to quote and book Walkboard affiliate moving services at wholesale pricing.',
  },
];

const Header = () => {
  return (
    <>
      <b>Quote and book interstate moves</b> as a booking agent for Walkboard's award winning interstate moving service
      affiliates.
    </>
  );
};

const onRegister = async (history, quoteServiceApproved) => {
  if (quoteServiceApproved) {
    return history.push('/company/booking-agent/get-pricing');
  }

  history.push('/company/booking-agent');
};

const QuoteAndBook = ({ indexInColumn }) => {
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const company = useSelector(getCompany);
  const quoteServiceApproved = useSelector(isNationalAccountApprovedProvider);
  const isViewerAdmin = useSelector(hasAdminRights);
  const warehouseIdsAdminRights = useSelector(getWarehouseIdsAdminRights);

  const isViewerHasWlAdmin = Boolean(warehouseIdsAdminRights.length);
  const hasRights = isViewerAdmin || isViewerHasWlAdmin;
  const bookingAgentFlag = Boolean(company.services.bookingAgentRegistered);
  const status = quoteServiceApproved ? 3 : bookingAgentFlag ? 1 : null;

  const [{ isLoading }, doRegister] = useAsyncNewCallback(() => onRegister(history, quoteServiceApproved));

  return (
    <>
      <ServiceSection
        indexInColumn={indexInColumn}
        onLearnMore={() => setOpen(true)}
        description={<Header />}
        onRegister={doRegister}
        inReview={bookingAgentFlag}
        status={status}
        hasRights={hasRights}
      />
      {open && (
        <LearnMoreDialog
          steps={steps}
          header={<Header />}
          onClose={() => setOpen(false)}
          onRegister={doRegister}
          isLoading={isLoading}
          inReview={bookingAgentFlag}
          approved={quoteServiceApproved}
          hasRights={hasRights}
        />
      )}
    </>
  );
};

export default QuoteAndBook;
