import { useState, useEffect } from 'react';
import { db } from '../firebase';

// collectionPath should look something like '/invoices/<companyId>/bookings/<id>...'
const constructQuery = (collectionPath, where) => {
  // split on slash and remove empty parts (like from start or end slash)
  const pathParts = collectionPath.split('/').filter(s => s !== '');
  let query = db;

  // set up the query by alternating between collection and document
  for (const i in pathParts) {
    const part = pathParts[i];
    if (i % 2 === 0) {
      query = query.collection(part);
    } else {
      query = query.doc(part);
    }
  }

  if (!where) {
    return query;
  }
  if (typeof where[0] === 'string' && where.length === 3) {
    // if `where` is e.g. ['companyId', '==', '123]
    query = query.where(...where);
  } else if (Array.isArray(where) && where.every(w => w.length === 3)) {
    // if `where` is e.g. [['companyId', '==', '123], ['bookingId', '==', '321']]
    for (const w of where) {
      query = query.where(...w);
    }
  } else {
    console.error('`where` format incorrect');
  }

  return query;
};

const normalize = docRefArr =>
  docRefArr.reduce((acc, cur) => {
    acc[cur.id] = cur.data();
    return acc;
  }, {});

export default (collectionPath, whereParams) => {
  const query = constructQuery(collectionPath, whereParams);

  const [initialized, setInitialized] = useState(false);
  const [results, setResults] = useState({});

  useEffect(() => {
    const unsub = query.onSnapshot(snapshot => {
      if (!initialized) {
        setResults(normalize(snapshot.docs));
        setInitialized(true);
      } else {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'removed') {
            const { [change.doc.id]: removed, ...newResults } = results;
            setResults(newResults);
          } else {
            // added or modified
            setResults({ ...results, [change.doc.id]: change.doc.data() });
          }
        });
      }
    });
    return unsub;
    // eslint-disable-next-line
  }, [collectionPath, JSON.stringify(whereParams)]); // query should unsub & resub if anything pertaining to the query changes.

  return [Object.values(results), !initialized /* Loading State */];
};
