import React from 'react';

import { ServiceSection } from '../index';

const LocalMoving = ({ indexInColumn }) => {
  return (
    <ServiceSection
      indexInColumn={indexInColumn}
      description={
        <>
          Provide <b>full service local moving</b> as a preferred service provider for our local moving referral and
          lead sharing programs.
        </>
      }
    />
  );
};

export default LocalMoving;
