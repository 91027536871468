import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CompanyDocumentCard from './CompanyDocumentCard';
import { H2 } from '../../common';
import { COMPANY_DOCUMENTS } from './constants';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(3, 0, 3.8),
  },
  companySection: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const CompanyDocumentsSection = () => {
  const classes = useStyles();

  return (
    <div id="sla" className={classes.root}>
      <H2>Service Level Agreement</H2>

      <Divider className={classes.divider} />

      <Grid className={classes.companySection} container spacing={3}>
        {Object.keys(COMPANY_DOCUMENTS).map(docKey => (
          <CompanyDocumentCard key={docKey} docKey={docKey} />
        ))}
      </Grid>
    </div>
  );
};

export default CompanyDocumentsSection;
