import { db } from '../firebase';

export const getCollection = async collection => {
  try {
    const snapshot = await db
      .collection(collection)
      .orderBy('name')
      .get();
    const data = [];
    snapshot.forEach(element => data.push({ id: element.data().id, name: element.data().name, domain: element.data().domain }));
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
