import React from 'react';
import { makeStyles, MenuItem, Grid, TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { parseError } from './helpers';

const useStyles = makeStyles(theme => ({
  selectContainer: {
    width: '210px',
    fontSize: 16,
    '& > .MuiInputBase-root.Mui-disabled': {
      background: theme.palette.grey[200],
    },
  },
  helperText: {
    color: 'red',
  },
}));

const Select = ({
  options,
  className = '',
  control,
  rules = {},
  name,
  defaultValue,
  onChange: onChangeParent,
  ...restSelectProps
}) => {
  const classes = useStyles();

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({ control, rules, name, defaultValue });

  const error = parseError(errors, name);

  return (
    <Grid container className={classes.selectContainer}>
      <TextField
        select
        className={`${classes.selectContainer} ${className}`}
        variant="outlined"
        value={value}
        onChange={(event, _t) => {
          onChange(event.target.value);
          onChangeParent && onChangeParent(event.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        helperText={error?.message}
        error={error?.message}
        {...restSelectProps}
      >
        {options.map((option, index) => {
          return (
            <MenuItem value={option?.value || option} key={index}>
              {option?.label || option}
            </MenuItem>
          );
        })}
      </TextField>
    </Grid>
  );
};

export default Select;
