import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Avatar, H3 } from '../../common';

const useStyles = makeStyles(theme => ({
  headingText: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    height: theme.spacing(3),
    '& h3': {
      marginRight: 16,
    },
  },
}));

const WarehouseCardSummary = ({ warehouse, StatusComponent }) => {
  const classes = useStyles();
  const { mainCity, mainState } = warehouse;

  return (
    <>
      <Avatar variant="warehouse">
        <img src="/WarehouseIcon.png" alt="" />
      </Avatar>
      <div className={classes.headingText}>
        <H3>{`${mainCity}, ${mainState}`}</H3>
        {StatusComponent}
      </div>
    </>
  );
};

export default WarehouseCardSummary;
