import React, { useState } from 'react';
import { Divider, Button, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle as AddIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { MasonryContainer, MASONRY_GUTTER, H2, H3 } from '../../common';
import AcContactCard from './ContactCard/CompanyCard';
import WlContactCard from './ContactCard/WarehouseCard';
import AddEditUserDialog from './AddEditUserDialog';
import { getSeparatedUserContacts, getRoles } from './helpers/utils';
import { getCompany } from '../../../store/selectors/company';
import { hasAdminRights } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 0),
  },
  divider: {
    margin: theme.spacing(3, 0, 4),
  },
  subHeader: {
    marginBottom: theme.spacing(2),
  },
  masonryItem: {
    marginBottom: `${MASONRY_GUTTER}px !important`, // matches parent gutter
  },
  button: {
    marginLeft: 'auto',
    marginBottom: theme.spacing(1),
    border: `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
    padding: theme.spacing(0.5, 1),
    color: theme.palette.primary.main,
    '& > span > svg': {
      fontSize: 16,
      marginRight: theme.spacing(1),
    },
  },
}));

export default ({ warehouses }) => {
  const classes = useStyles();
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [editDialogIsWlPoc, setEditDialogIsWlPoc] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const company = useSelector(getCompany);
  const { contacts, warehouseContacts } = company;
  const isViewerAdmin = useSelector(hasAdminRights);
  const [userContacts, staticContacts] = getSeparatedUserContacts(contacts);
  const otherContacts = [...staticContacts, ...Object.values(warehouseContacts || {})];

  return (
    <Box id="contacts" className={classes.root}>
      <H2>Company Points of Contact</H2>
      <Divider className={classes.divider} />
      <H3 className={classes.subHeader}>Contacts with Portal Access</H3>
      <MasonryContainer>
        {userContacts.map(user => {
          const hasCompanyRoles = Object.values(getRoles(company, user)).some(role => Boolean(role));

          // DISCLAMER: We assume a user is only a WLPOC when a company contact
          // without roles with the company is related to a warehouse location.
          return Boolean(!hasCompanyRoles && user.warehouseLocationIds?.length) ? (
            <WlContactCard
              key={user.id}
              className={classes.masonryItem}
              user={user}
              warehouses={warehouses}
              onEdit={() => {
                setSelectedUser(user);
                setAddEditDialogOpen(true);
                setEditDialogIsWlPoc(false);
              }}
              isShowAllWlRelated
              isWlRealUser
            />
          ) : (
            <AcContactCard
              key={user.id}
              user={user}
              className={classes.masonryItem}
              onEdit={() => {
                setSelectedUser(user);
                setAddEditDialogOpen(true);
              }}
            />
          );
        })}
      </MasonryContainer>
      <Grid container alignItems="center">
        <H3 className={classes.subHeader}>Other Points of Contact</H3>
        {isViewerAdmin && (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              setSelectedUser();
              setAddEditDialogOpen(true);
            }}
          >
            <AddIcon />
            Add Contact
          </Button>
        )}
      </Grid>
      <MasonryContainer classes={classes}>
        {otherContacts.map(user => {
          return user.id?.includes('static') ? (
            <AcContactCard
              key={user.id}
              user={user}
              className={classes.masonryItem}
              onEdit={() => {
                setSelectedUser(user);
                setAddEditDialogOpen(true);
              }}
            />
          ) : (
            <WlContactCard
              key={user.id}
              className={classes.masonryItem}
              user={user}
              warehouses={warehouses}
              onEdit={() => {
                setSelectedUser(user);
                setAddEditDialogOpen(true);
                setEditDialogIsWlPoc(true);
              }}
              isShowAllWlRelated
            />
          );
        })}
      </MasonryContainer>

      {!!addEditDialogOpen && (
        <AddEditUserDialog
          selectedUser={selectedUser}
          onClose={() => {
            setAddEditDialogOpen(false);
            setEditDialogIsWlPoc(false);
          }}
          isWlPocForm={editDialogIsWlPoc}
          isNoRoles={editDialogIsWlPoc || !isViewerAdmin}
        />
      )}
    </Box>
  );
};
