import { eventChannel } from 'redux-saga';
import { take, put, fork } from 'redux-saga/effects';
import { auth, db } from '../../firebase';
import { setUser } from '../actions/user';
import { setCompany } from '../actions/company';
import { setWarehouses } from '../actions/warehouses';
import { initialState as warehousesInitialState } from '../reducers/warehouses';

let unsub;
let unsubWl;

function authChangeChannel() {
  return eventChannel(emit => {
    return auth.onAuthStateChanged(async loggedInUser => {
      if (!!loggedInUser) {
        emit({ company: null, warehouses: { isLoading: true }, user: loggedInUser });

        const companyQuery = await db
          .collection('agent_companies')
          .where(`contacts.${loggedInUser.uid}.id`, '==', loggedInUser.uid)
          .get(); // find the company that has this user as contact

        const companyId = companyQuery.docs[0].data().id;
        const companyDoc = db.collection('agent_companies').doc(String(companyId));

        unsub = companyDoc.onSnapshot(async docsSnap => {
          const company = docsSnap.data();

          const user = {
            ...loggedInUser,
            ...company.contacts[loggedInUser.uid],
          };

          emit({ company, user });
        });

        unsubWl = companyDoc.collection('warehouses').onSnapshot(async docsSnap => {
          // sanitize process is done on the generator
          emit({ warehouses: { warehouses: docsSnap.docs, isLoading: false }, company: null, user: null });
        });
      } else {
        // firebase logout
        if (unsub) {
          unsub();
        }
        if (unsubWl) {
          unsubWl();
        }

        emit({ company: false, user: false, warehouses: warehousesInitialState });
      }
    });
  });
}

// When a user logs in, load the company that contains the user, then stores the company & user into corresponding reducers
function* subscribeToAuthChange() {
  const channel = authChangeChannel();

  while (true) {
    const { company, user, warehouses } = yield take(channel);

    if (warehouses) {
      yield put(setWarehouses(warehouses));
    }

    if (company !== null && user !== null) {
      yield put(setCompany(company));
      yield put(setUser(user));
    }
  }
}

export default function* userSaga() {
  yield fork(subscribeToAuthChange);
}
