import React, { useReducer, useContext, useEffect } from 'react';
import LoadingScreen from '../components/Layouts/LoadingScreen';
import { db } from '../firebase';

// Does a one-time fetch to feature flags. We don't want to use a listener here because
// users shouldn't see a feature-switch during a single session.
// NOTE: should block site/process from loading until init is done.
const initFeatureFlags = async () => {
  const doc = await db.collection('system').doc('enabledFeatures').get();

  // wbEnabledFeaturedFlags = doc && doc.data();
  return doc && doc.data();
};

const FeatureFlagsContext = React.createContext();

const reducer = (state, action) => action.payload;

export const FeatureFlagsProvider = ({ children }) => {
  const [wbEnabledFeaturedFlags, dispatch] = useReducer(reducer);

  useEffect(() => {
    if (!wbEnabledFeaturedFlags) {
      // set initials FeatureFlags
      initFeatureFlags()
        .then(respond => dispatch({ payload: respond }))
        .catch(e => {
          dispatch({ payload: false });
          console.error(e);
        });
    }
  }, [wbEnabledFeaturedFlags]);

  return (
    <FeatureFlagsContext.Provider value={wbEnabledFeaturedFlags}>
      {/* making sure initFeatureFlag() finished before passing the children */}
      {wbEnabledFeaturedFlags === undefined ? <LoadingScreen /> : children}
    </FeatureFlagsContext.Provider>
  );
};

// return all the features flags
export const useFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};

// return one feature flag by key
export const useFeatureFlagsByKey = key => {
  const featureFlags = useFeatureFlags();
  return featureFlags && featureFlags[key];
};
