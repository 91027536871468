import React from 'react';
import { Typography, Container } from '@material-ui/core';

const supportEmail = 'partnersupport@walkboard.com';

export default () => {
  return (
    <Container maxWidth="md">
      <Typography component="h1" variant="h2">
        An error occurred. <br /> Please contact Walkboard customer service at <br /> {supportEmail}
      </Typography>
    </Container>
  );
};
