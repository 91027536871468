import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  success: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.getContrastText(theme.palette.success.dark),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  error: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.getContrastText(theme.palette.error.dark),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  circle: {
    marginRight: theme.spacing(1),
    color: 'white',
    width: `${theme.spacing(2)}px !important`,
    height: `${theme.spacing(2)}px !important`,
  },
}));

const AccessButtons = ({ user, isApproving, performApproval, isDeleting, performDelete }) => {
  const classes = useStyles();
  return (
    <>
      <Button fullWidth disabled={isApproving} className={classes.success} onClick={() => performApproval(user)}>
        {isApproving && <CircularProgress className={classes.circle} />}
        {isApproving ? 'Loading' : 'Approve'}
      </Button>
      <Button disabled={isDeleting} className={classes.error} onClick={performDelete}>
        {isDeleting && <CircularProgress className={classes.circle} />}
        {isDeleting ? 'Loading' : 'Reject'}
      </Button>
    </>
  );
};
export default AccessButtons;
