import {
  isAdmin,
  getUid,
  hasAtLeastOneWarehouseRole,
  getUserRelatedWarehouseIds,
  isPrimaryContact,
  isInvoicingContact,
} from '../index';
import {
  isAdminContact as isWlAdminContact,
  isEscalationContact as isWlEscalationContact,
  isOperationsContact as isWlOperationsContact,
  isInvoicingContact as isWlInvoicingContact,
  isPrimaryContact as isWlPrimaryContact,
} from '../../warehouses';

/**
 * Takes entire state, returns if user has company role admin.
 * @param {Record} state
 * @returns {boolean} test user company admin rights
 */
export const hasAdminRights = state => isAdmin(state, getUid(state));

/**
 * Takes entire state, returns test if user has invoicing access by conditioning
 * these selectors: hasAdminRights, isPrimaryContact, isInvoicingContact, hasAtLeastOneWarehouseRole
 * @param {Record} state
 * @returns {boolean} test user invoicing access
 */
export const hasInvoicingAccess = state =>
  hasAdminRights(state) ||
  isPrimaryContact(state) ||
  isInvoicingContact(state) ||
  hasAtLeastOneWarehouseRole(state, [isWlInvoicingContact, isWlPrimaryContact, isWlAdminContact]);

/**
 * Takes entire state a warehouse id and returns a factory function that takes entire state,
 * returns if user has warehouse admin role between the provided warehouse id.
 * @param {String} warehouseId
 * @returns {boolean} test user warehouse admin role in specific warehouse id.
 */
export const hasWlAdminRights = warehouseId => state => {
  if (!warehouseId) {
    return false;
  }

  const uid = getUid(state);

  return isWlAdminContact(state, warehouseId, uid);
};

/**
 * Takes entire state, return all warehouses related ids that user has WL Admin role
 * @param {Record} state
 * @returns {Array} all warehouse id's with WL admin role.
 */
export const getWarehouseIdsAdminRights = state => {
  const uid = getUid(state);

  return getUserRelatedWarehouseIds(state).filter(warehouseId => isWlAdminContact(state, warehouseId, uid));
};

/**
 * Takes entire state, returns if user has access to an comany point of contact.
 * @param {Record} state
 * @returns {boolean} test if user has company point of contact access.
 */
export const hasPocAccess = state =>
  hasAtLeastOneWarehouseRole(state, [
    isWlEscalationContact,
    isWlOperationsContact,
    isWlInvoicingContact,
    isWlPrimaryContact,
    isWlAdminContact,
  ]);

/**
 * takes a warehouse id, returns a factory function selector
 * that checks if user has warehouse point of contact access
 * @param {String} warehouseId
 * @returns {boolean} test if user has warehouse point of contact access.
 */
export const hasWlPocAccess = warehouseId => state => {
  // From the seperations of what contact card to use, the
  // contact portal access section can use the wl card and
  // doesn't provide warehouseId when using it.
  if (!warehouseId) {
    return hasPocAccess(state);
  }

  const uid = getUid(state);

  return (
    isWlEscalationContact(state, warehouseId, uid) ||
    isWlOperationsContact(state, warehouseId, uid) ||
    isWlInvoicingContact(state, warehouseId, uid) ||
    isWlPrimaryContact(state, warehouseId, uid) ||
    isWlAdminContact(state, warehouseId, uid)
  );
};

/**
 * Takes entire state, returns all related warehouse ids that have invoice access
 * @param {Record} state
 * @returns {Array} Warehouse ids with invoicing access.
 */
export const getUserWarehouseIdsInvoiceAccess = state => {
  const uid = getUid(state);

  return getUserRelatedWarehouseIds(state).filter(
    warehouseId =>
      isWlPrimaryContact(state, warehouseId, uid) ||
      isWlInvoicingContact(state, warehouseId, uid) ||
      isWlAdminContact(state, warehouseId, uid)
  );
};
