import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isLoggedIn, isVerified, hasCompanyAccess } from '../../store/selectors/user';
import { getCompany } from '../../store/selectors/company';

import MessageScreen from '../Layouts/MessageScreen';
import VerificationPrompt from '../../screens/Auth/VerificationPrompt';
import ErrorOccurredScreen from '../../screens/Auth/ErrorOccurred';
import ApprovalScreen from '../../screens/Auth/Approval';

/**
 * For Company Authenticaticated User Only,
 * If it's logged redirect to path /company
 * @param {Object} component
 * @returns {Object} A route that render a component
 */
export const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  const isUserLoggedIn = useSelector(isLoggedIn);

  if (isUserLoggedIn) return <Redirect to="/company" />;

  return <Route {...rest} render={props => <Component {...props} />} />;
};

/**
 * For Company Authenticaticated User Only,
 * If no user, redirect to /login
 * @param {Object} component
 * @returns {Object} A route that render a component
 */
export const AuthorizedCompanyRoute = ({ component: Component, ...rest }) => {
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isCompanyLoaded = useSelector(getCompany);
  const isUserVerified = useSelector(isVerified);
  const hasAccess = useSelector(hasCompanyAccess);

  if (isUserLoggedIn === false) return <Redirect to="/login" />;

  // company will not load; error has happened with collection.
  if (isCompanyLoaded === false) return <MessageScreen component={ErrorOccurredScreen} />;

  // User isn't verified his email.
  if (isUserVerified === false) return <MessageScreen component={VerificationPrompt} />;

  return (
    <Route
      {...rest}
      render={props => (hasAccess ? <Component {...props} /> : <MessageScreen component={ApprovalScreen} />)}
    />
  );
};
