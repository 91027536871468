import faker from 'faker';
import addDays from 'date-fns/addDays';

const statusOptions = ['scheduled', 'paid'];
const chargeTypes = ['Agent Charge', 'Warehouse Handling In', 'Warehouse Handling Out'];
const expenseTypes = ['origin', 'destination'];

const generateInvoice = companyId => {
  const dateOfService = faker.date.recent(faker.random.number(5));
  return {
    companyId,
    name: chargeTypes[faker.random.number(2)],
    invoiceNumber: `AC${faker.random.number(9999)}`,
    bookingId: `WB${faker.random.number(99)}-${faker.random.number(999)}-${faker.random.number(99)}`,
    postgresId: faker.random.number(),
    type: expenseTypes[faker.random.number(1)],
    shipperName: faker.company.companyName(),
    invoiceAmount: faker.random.number(2000),
    status: statusOptions[faker.random.number(1)],
    dateOfService,
    paymentDate: addDays(dateOfService, 30),
  };
};

export const getInvoices = (companyId, amount = 10) =>
  [...Array(amount)]
    .map(() => generateInvoice(companyId))
    .sort((a, b) => b.dateOfService.getTime() - a.dateOfService.getTime());
