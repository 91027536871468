import React from 'react';

import { useSelector } from 'react-redux';
import { ServiceSection } from '../index';
import { useAsyncNewCallback } from '../../../hooks/useAsync';
import { isSurveyor, getSurveyorAccessRequired, getUid } from '../../../store/selectors/user/index';
import { getCompany } from '../../../store/selectors/company';
import { functions } from '../../../firebase';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const onRegister = async (quoteSurveyorApproved, companyId, userId, setOpen) => {  
  if (quoteSurveyorApproved) {
    const res = await functions.httpsCallable('createCustomToken')({ uid: userId});
    const userToken = res.data;    
    return window.open(`${process.env.REACT_APP_VIDEO_PORTAL_URL}/?token=${userToken}`, '_blank', 'noreferrer');
  } else {
    await functions.httpsCallable('sendSurveyorNotificationToAdmin')({ uid: userId, companyId});
    setOpen(true);
  } 
};

const OrderVideoSurveys = () => {

  const quoteSurveyorApproved = useSelector(isSurveyor);  
  const isSurveyorAccessRequired = useSelector(getSurveyorAccessRequired);  
  const company = useSelector(getCompany);  
  const userId = useSelector(getUid);  
  const [open, setOpen] = React.useState(false);
  
  const [{ isLoading }, doRegister] = useAsyncNewCallback(() => onRegister(quoteSurveyorApproved, company.id, userId, setOpen));

  const status = quoteSurveyorApproved ? 3 : isSurveyorAccessRequired ? 1 : 5;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ServiceSection
      indexInColumn={isSurveyorAccessRequired }
      hasRights={status !== 1}
      status={status}
      onRegister={doRegister}
      isLoading={isLoading}
      description={
        <>
          <b>Order video surveys</b> for our out-of-market and off hours survey needs.
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              The Surveyor access was required. 
            </Alert>
          </Snackbar>
        </>
      }
    />
  );
};

export default OrderVideoSurveys;
