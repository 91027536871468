export const ROLE_NAMES = {
  admin: {
    label: 'Administrator',
  },
  primary: {
    label: 'Primary',
  },
  secondary: {
    label: 'Operations',
  },
  invoicing: {
    label: 'Invoicing',
  },
};

export const ROLE_WL_NAMES = {
  primary: {
    label: 'Primary Contact',
  },
  admin: {
    label: 'Administrator',
  },
  escalation: {
    label: 'Escalation',
  },
  operations: {
    label: 'Operations',
  },
  invoicing: {
    label: 'Invoicing',
  },
};

// for iterating
export const ROLE_KEYS = Object.keys(ROLE_NAMES);
export const ROLE_WL_KEYS = Object.keys(ROLE_WL_NAMES);
