import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import InstantQuote from '../../components/BookingAgent/InstantQuote/index';
import RegisterButton from '../../components/BookingAgent/RegisterButton';
import { useAsyncNewCallback } from '../../hooks/useAsync';
import { ReserveModal } from './ReserveModal';
import PageHeader from '../../components/PageHeader';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { getCompanyWithoutContacts } from '../../store/selectors/company';
import LoadingScreen from '../../components/Layouts/LoadingScreen';

import ErrorGetPricingFormBox from '../../components/BookingAgent/Sections/GetPricingForm/ErrorGetPricingFormBox';

const useStyles = makeStyles(theme => ({
  gridPadding: {
    padding: theme.spacing(0),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: theme.spacing(4, 0, 3, 0),
  },
  quoteContainer: {
    display: 'flex',
  },
  error: {
    position: 'absolute',
    marginLeft: 'auto',
    color: 'red',
    fontSize: '15px',
  },
}));

const reserveQuote = async (company, fullQuoteId, getValues, setOpen, setSubmitError) => {
  const formValues = getValues();
  try {
    if (formValues.segments.length === 0) {
      throw new Error('Select at least one item');
    }

    const payload = {
      mirroringPayload: formValues,
      updateBackend: true,
    };

    await db
      .collection('agent_companies')
      .doc(String(company.id))
      .collection('full_quotes')
      .doc(fullQuoteId)
      .update(payload);

    setOpen(true);
  } catch (error) {
    setSubmitError(error.message);
  }
};

const InstantQuoteScreen = ({ pricingForm }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const company = useSelector(getCompanyWithoutContacts);
  const [fullquote, setFullQuote] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const formInstantQuote = useForm({
    defaultValues: {
      full_quote_id: '',
      auto_transportation: [],
      segments: [],
      third_party_services: [],
    },
    criteriaMode: 'all',
  });

  const { handleSubmit, setValue, getValues } = formInstantQuote;

  const [{ isLoading }, doRegister] = useAsyncNewCallback(reserveQuote);

  useEffect(() => {
    const onSub = db
      .collection('agent_companies')
      .doc(String(company.id))
      .collection('full_quotes')
      .doc(String(id))
      .onSnapshot(async snapshot => {
        const snapshotValues = snapshot.data();
        setValue('full_quote_id', snapshotValues.postgres_id);
        return setFullQuote(snapshot.data());
      });
    return () => {
      onSub();
    };
  }, [company.id, id, setValue]);

  if (!fullquote?.postgres_id && !fullquote?.errorOnFbFunctionFullQuote) {
    return <LoadingScreen />;
  }

  if (fullquote.errorOnFbFunctionFullQuote) {
    return <ErrorGetPricingFormBox message={fullquote.errorOnFbFunctionFullQuote} />;
  }

  return (
    <>
      <PageHeader title="Your Instant Quotes" />
      <FormProvider {...formInstantQuote}>
        <Grid className={classes.quoteContainer}>
          {Object.values(fullquote?.segments || {}).map(segment => (
            <InstantQuote key={segment.id} segment={segment} handleError={setSubmitError} />
          ))}
        </Grid>

        <Grid className={classes.buttonContainer}>
          {submitError && <div className={classes.error}>{submitError}</div>}
          <RegisterButton
            className={classes.buttonContainer}
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
            labelBtn="Reserve Selected"
            onClick={handleSubmit(values => doRegister(company, id, getValues, setOpen, setSubmitError))}
          />
        </Grid>
      </FormProvider>

      {open && <ReserveModal onClose={() => setOpen(false)} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    pricingForm: state.bookingAgent.pricingForm,
  };
};

export default connect(mapStateToProps, {})(InstantQuoteScreen);
