import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { lightBlue, blue, grey, yellow, red } from '@material-ui/core/colors';

const breakpoints = createBreakpoints({});

const PRIMARY_MAIN = '#3C96D9';

// Overrides default Material-UI themes that can be found at: https://material-ui.com/customization/default-theme/
export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 800,
      lg: 1366,
      xl: 1920,
    },
  },
  palette: {
    primary: { main: PRIMARY_MAIN, darker: '#2a6a97' },
    secondary: lightBlue,
    secondaryBg: { main: blue[50], darker: blue[100] },
    blue: { lighter: blue[300], main: blue[500], darker: blue[700] },
    grey: { lighter: grey[300], main: grey[500], darker: grey[700] },
    link: '#707070',
    navBg: '#fefefe',
    success: {
      main: '#00954A',
      light: '#2eba3c',
    },
    error: {
      main: red[500],
      light: '#e86161',
    },
    warning: {
      main: yellow[500],
      darker: '#ffb800',
    },
  },
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: '600',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '600',
    },
    h3: {
      lineHeight: '1.15em',
      [breakpoints.down('xs')]: {
        fontSize: '2.4rem',
      },
    },
    h6: {
      fontSize: '1.125rem',
    },
    button: {
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
  },

  // If the rule is not injected by the theme, apply the extra styling using `overrides`
  overrides: {
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        color: 'currentColor',
        fontWeight: 500,
      },
    },
    MuiCard: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'initial',
      },
    },
    MuiStepIcon: {
      root: {
        color: 'rgba(0, 0, 0, 0.08)',
      },
    },
    MuiButton: {
      root: {
        padding: '12px 24px',
        minWidth: 120,
        '& ~.MuiButton-root': {
          marginLeft: 12,
        },
        [breakpoints.down('xs')]: {
          minWidth: 'initial',
        },
      },
      text: {
        padding: '12px 24px',
      },
      outlined: {
        padding: '11px 23px',
      },
      contained: {
        boxShadow: 'initial',
      },
    },
  },
};
