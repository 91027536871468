import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { db } from '../../../firebase';
import { ServiceSection } from '../index';
import LearnMoreDialog from '../LearnMoreDialog';
import { useAsyncNewCallback } from '../../../hooks/useAsync';
import { getCompany, isServiceProviderStatus } from '../../../store/selectors/company';
import { hasAdminRights } from '../../../store/selectors/user';
import { useFeatureFlagsByKey } from '../../../utils/featureFlags';

const steps = [
  {
    header: 'You provide your company information including:',
    bullets: ['Van Line (if affiliated)', 'Address(es)', 'Warehouse Location(s)', 'Point(s) of Contact'],
  },
  {
    header:
      'Our team will review your information and contact you regarding any questions for approval. We’ll then provide our service level agreement for your review.',
  },
  {
    header: 'You then upload:',
    bullets: ['The signed service level agreement', 'Your W9', 'Your load and unload rates'],
  },
  {
    header: 'Our team will review your documentation and contact you regarding any questions for approval.',
  },
];

const Header = () => {
  return (
    <>
      Provide <b>Origin and Destination Services</b> as a preferred service provider for Walkboard Express our
      containerized move solution.
    </>
  );
};

const onRegister = async (history, company, isOriginAndDestinationServicesEnabled, originDestinationStatus) => {
  if (!isOriginAndDestinationServicesEnabled || originDestinationStatus === 2) {
    return;
  }
  await db.collection('agent_companies').doc(String(company.id)).update({ 'services.isServicesRegistered': true });
  history.push('/company/info');
};

const OriginAndDestination = ({ indexInColumn }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const company = useSelector(getCompany);
  const isViewerAdmin = useSelector(hasAdminRights);
  const originDestinationStatus = useSelector(isServiceProviderStatus);
  const isOriginAndDestinationServicesEnabled = useFeatureFlagsByKey('originAndDestinationServices');
  const [{ isLoading }, doRegister] = useAsyncNewCallback(() =>
    onRegister(history, company, isOriginAndDestinationServicesEnabled, originDestinationStatus)
  );

  return (
    <>
      <ServiceSection
        indexInColumn={indexInColumn}
        description={<Header />}
        onLearnMore={() => setOpen(true)}
        onRegister={isOriginAndDestinationServicesEnabled ? doRegister : null}
        isLoading={isLoading}
        hasRights={isViewerAdmin}
        status={originDestinationStatus}
      />

      {open && (
        <LearnMoreDialog
          steps={steps}
          header={<Header />}
          onClose={() => setOpen(false)}
          onRegister={isOriginAndDestinationServicesEnabled ? doRegister : null}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default OriginAndDestination;
