import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Typography, Grid, TextField, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { v4 as uuidv4 } from 'uuid';

import { db } from '../../firebase';
import StyledTooltip from './StyledTooltip';
import RegisterButton from './RegisterButton';
import { Select } from '../shared';
import GeneralCompanyInfo from '../GeneralCompanyInfo';
import { getCompany, isServiceBookingAgentRegistered } from '../../store/selectors/company';
import PointOfContactsBookingAgent from './PointOfContactsBookingAgent';
import { useAsyncNewCallback } from '../../hooks/useAsync';

import { useForm, FormProvider } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  gridPadding: {
    padding: theme.spacing(1),
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
  },
  description: {
    fontSize: '1.3em',
    marginRight: '1em',
    margin: 'revert',
  },
  inline: {
    display: 'flex',
    margin: '10px 0 ',
    padding: theme.spacing(0, 0.5),
    '& > *': {
      marginRight: '2em',
      margin: 'auto 0',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: theme.spacing(2, 0),
  },
}));

const SELECT_OPTIONS = [
  { id: 1, fieldName: '', value: '', name: 'Select', disabled: true },
  { id: 2, fieldName: 'Yes', value: true, name: 'Yes' },
  { id: 3, fieldName: 'No', value: false, name: 'No' },
];

const onSumbit = async (submitValues, companyId, history) => {
  const { brokerPoc, ...nationalAccount } = submitValues;

  const brokerPocId = `static_${uuidv4()}`;

  const payload = {
    'services.bookingAgentRegistered': true,
    updateBackend: true,
    national_account: {
      ...nationalAccount,
      // for mvp, is just adding one nationalUser on NationalAccount
      broker_pocs: { [brokerPocId]: { ...brokerPoc, id: brokerPocId } },
    },
  };

  await db.collection('agent_companies').doc(String(companyId)).update(payload);

  history.push('/company/services');
};

const defaultValues = {
  brokerPoc: {
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
  },
  broker_mc: '',
  broker_dot: '',
  performs_survey: '',
};

const BookingAgentRegistrationForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const company = useSelector(getCompany);
  const bookingAgentFlag = useSelector(isServiceBookingAgentRegistered);

  const formProps = useForm({
    defaultValues,
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = formProps;

  const [{ isLoading }, doRegister] = useAsyncNewCallback(onSumbit);

  return (
    <form
      onSubmit={handleSubmit(
        values => doRegister(values, company.id, history),
        err => console.log(err)
      )}
    >
      <FormProvider {...formProps}>
        <Grid className={classes.gridPadding} item />
        <GeneralCompanyInfo />
        <Grid container className={classes.fieldsContainer}>
          <Grid item xs={12} md={6} className={classes.inline}>
            <TextField
              fullWidth
              variant="outlined"
              label="HHG Brokers DOT#"
              error={Boolean(errors?.broker_dot)}
              helperText={errors?.broker_dot?.message}
              inputProps={register('broker_dot', {
                pattern: {
                  value: /^$|^[\d]{6,7}$/,
                  message: 'Must be six or seven digits.',
                },
              })}
            />
            <StyledTooltip
              arrow
              title="Please enter the Department of Transportation number for the authorized household goods broker entity that will be quoting and booking household goods transportation."
              placement="right"
            >
              <HelpOutlineIcon />
            </StyledTooltip>
          </Grid>
          <Grid item xs={12} md={6} className={classes.inline}>
            <TextField
              fullWidth
              variant="outlined"
              label="HHG Brokers MC#"
              error={Boolean(errors?.broker_mc)}
              helperText={errors?.broker_mc?.message}
              inputProps={register('broker_mc', {
                pattern: {
                  value: /^$|^[\d]{6,7}$/,
                  message: 'Must be six or seven digits.',
                },
              })}
            />
            <StyledTooltip
              arrow
              title="Please enter the FMCSA Motor Carrier (MC) number for the authorized household goods broker entity that will be quoting and booking household goods transportation."
              placement="right"
            >
              <HelpOutlineIcon />
            </StyledTooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className={classes.inline}>
          <Typography className={classes.description}>
            Will you quoting based on physical survey that you or a third party will perform?
          </Typography>
          <Grid item xs={12} md={1}>
            <Select
              options={SELECT_OPTIONS}
              label="Select"
              name="performs_survey"
              control={control}
              rules={{
                validate: {
                  checkIfBoolean: value => typeof value === 'boolean' || 'Is Required',
                },
              }}
            />
          </Grid>
          <StyledTooltip
            arrow
            title='To quote and book household goods moving services there must be a physical survey performed. If you or a third party will conduct the survey, select "Yes". If you need Walkboard Express to survey the shipper, select "No".'
            placement="right"
          >
            <HelpOutlineIcon />
          </StyledTooltip>
        </Grid>

        <PointOfContactsBookingAgent />

        <Grid item xs={12} md={7} className={classes.buttonContainer}>
          <RegisterButton
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
            labelBtn={bookingAgentFlag ? 'Under Review' : 'Submit For Approval'}
          />
        </Grid>
      </FormProvider>
    </form>
  );
};

export default BookingAgentRegistrationForm;
