// Documents Statuses
// These values must stay in sync with the statuses we use in the admin portal.
export const DOC_STATUS = {
  NONE: 0,
  REJECTED: 1,
  APPROVED: 2,
};

export const DOCUMENT_KEYS = {
  SLA: 'sla',
  W9: 'w9',
  TARIFF: 'tariff',
};

export const COMPANY_DOCUMENTS = {
  [DOCUMENT_KEYS.SLA]: {
    name: 'Service Level Agreement',
    urlKey: `${DOCUMENT_KEYS.SLA}_document`,
    statusKey: `${DOCUMENT_KEYS.SLA}_document_status`,
    dateKey: `${DOCUMENT_KEYS.SLA}_date_uploaded`,
    template: 'http://staging.walkboard.com/media/partner_portal_templates/sla.pdf',
  },
};

export const WAREHOUSE_DOCUMENTS = {
  [DOCUMENT_KEYS.W9]: {
    name: 'W9',
    urlKey: `${DOCUMENT_KEYS.W9}_document`,
    statusKey: `${DOCUMENT_KEYS.W9}_document_status`,
    dateKey: `${DOCUMENT_KEYS.W9}_date_uploaded`,
    template: 'http://staging.walkboard.com/media/partner_portal_templates/fw9.pdf',
  },
  [DOCUMENT_KEYS.TARIFF]: {
    name: 'Load/Unload Rates',
    urlKey: `${DOCUMENT_KEYS.TARIFF}_document`,
    statusKey: `${DOCUMENT_KEYS.TARIFF}_document_status`,
    dateKey: `${DOCUMENT_KEYS.TARIFF}_date_uploaded`,
    template: '',
  },
};
