import { hasAtLeastOneWarehouseRole, isApprovedByAdmin, hasAdminRights } from '../index';
import { getWarehouseIdsDocumentAccess } from './documents';
import {
  isAdminContact as isWlAdminContact,
  isInvoicingContact as isWlInvoicingContact,
  isPrimaryContact as isWlPrimaryContact,
} from '../../warehouses';

/**
 * takes entire state, returns if user has company screen access.
 * @param {Record} state
 * @returns {boolean} test if user has company access
 */
export const hasCompanyAccess = state => hasAdminRights(state) || isApprovedByAdmin(state);

/**
 * takes entire state, returns if user has service screen access by
 * having just one of the provided warehouse roles.
 * @param {Record} state
 * @returns {boolean} test if user has service screen access
 */
export const hasServiceScreenAccess = state =>
  hasAtLeastOneWarehouseRole(state, [isWlInvoicingContact, isWlPrimaryContact, isWlAdminContact]);

/**
 * takes entire state, returns if user has documents screens access by
 * using the {getWarehouseIdsDocumentAccess}.
 * @param {Record} state
 * @returns {boolean} test if user has document screen access.
 */
export const hasDocumentScreenAccess = state => Boolean(getWarehouseIdsDocumentAccess(state).length);
