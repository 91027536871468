import React, { useState, useEffect } from 'react';
import { Typography, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { useSelector } from 'react-redux';
import { db } from '../../firebase';
import { useAsyncCallback } from '../../hooks';

import GeneralSection from './GeneralSection';
import PointsOfContactSection from './PointsOfContact';
import WarehouseLocationSection from './WarehouseLocation';
import withPaperReminder from '../wrappers/withPaperReminder';
import { getCompanyWithoutContacts } from '../../store/selectors/company';
import PageHeader from '../PageHeader';
import { getWarehouses, isLoadingData } from '../../store/selectors/warehouses';

const useStyles = makeStyles(theme => ({
  submitMsg: {
    marginTop: theme.spacing(1),
  },
  buttonDivider: {
    margin: theme.spacing(2.5, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  saveBar: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
    },
  },
}));

const jsonEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const saveCompanyAndUsers = async company => {
  await db
    .collection('agent_companies')
    .doc(String(company.id))
    .set({ ...company, id: Number(company.id), updateBackend: true }, { merge: true });
  return { success: true, company };
};

export default () => {
  const classes = useStyles();

  // Point of contact modifications are handled separately by POC components, so in the other info forms
  // we want to use company data AFTER stripping out POC-related properties, so saving POC changes while
  // editing the forms (type some things in address field then click "Add contact" before saving the address change)
  // would't cause the forms to be reset.
  const existingCompanyInfo = useSelector(getCompanyWithoutContacts);
  const [company, setCompany] = useState(existingCompanyInfo);

  const warehouses = useSelector(getWarehouses);
  const isLoadingWls = useSelector(isLoadingData);

  // if changes come from remote data being updated, update states accordingly.
  useEffect(() => {
    setCompany(existingCompanyInfo);
    // eslint-disable-next-line
  }, [JSON.stringify(existingCompanyInfo)]);
  const [{ result: savedCompany, isLoading, error }, doSaveCompany] = useAsyncCallback(
    () => saveCompanyAndUsers(company),
    {}
  );

  // TODO: Need dirty field comparison over equality check since the latter breaks save changes
  // detection on edge case where user changes a field and then undos either by rewriting
  // previous value or keyboard undo hotkey.
  const isDirty = !jsonEqual(existingCompanyInfo, company);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        doSaveCompany();
      }}
    >
      <PageHeader
        title="Review and Update Your Company Information"
        subtitle="Add and edit company locations, and points of contact"
      />
      <Grid container alignItems="center" justify="flex-end">
        {(isDirty || savedCompany.success) && (
          <Grid item xs>
            <PaperReminder
              isError={isDirty && !savedCompany.success}
              isDirty={isDirty}
              savedSuccess={savedCompany.success}
            />
          </Grid>
        )}
        <Grid item>
          <SubmitButton isLoading={isLoading} isDirty={isDirty} />
        </Grid>
      </Grid>
      <GeneralSection company={company} onChange={(key, val) => setCompany({ ...company, [key]: val })} />

      <WarehouseLocationSection warehouses={warehouses} isLoading={isLoadingWls} />
      <PointsOfContactSection warehouses={warehouses} />

      {!!error && (
        <Typography variant="subtitle2" color="error" className={classes.submitMsg}>
          {error.message}
        </Typography>
      )}
    </form>
  );
};

const useButtonStyles = makeStyles(theme => ({
  wrapper: {
    float: 'right',
    margin: theme.spacing(1),
    position: 'relative',
  },
  root: {
    paddingTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
  progress: {
    color: green[600],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubmitButton = props => {
  const { isLoading, isDirty } = props;
  const classes = useButtonStyles();
  return (
    <div className={classes.wrapper}>
      <Button type="submit" variant="contained" disabled={isLoading || !isDirty} className={classes.root}>
        Save Changes
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
};

const usePaperStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const PaperReminder = withPaperReminder(({ isDirty, savedSuccess }) => {
  const isChangeState = isDirty;
  const isSaved = !isDirty && savedSuccess === true;
  const classes = usePaperStyles();

  return (
    <>
      {isSaved && !isChangeState ? (
        <Typography variant="subtitle2" display="inline">
          Your changes are saved.
        </Typography>
      ) : (
        <>
          <Typography variant="subtitle2" display="inline">
            Remember to click
          </Typography>
          <Typography variant="subtitle2" display="inline" className={classes.bold}>
            Save Changes
          </Typography>
        </>
      )}
    </>
  );
});
