import React from 'react';

import { ServiceSection } from '../index';

const UnbookedLead = ({ indexInColumn }) => {
  return (
    <ServiceSection
      indexInColumn={indexInColumn}
      description={
        <>
          <b>Monetize unbooked leads</b> by sharing with the network and earning referral business.
        </>
      }
    />
  );
};

export default UnbookedLead;
