import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Typography, Grid, makeStyles, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Select } from '../../../FormComponents';

const useStyles = makeStyles(theme => ({
  inlineAdds: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
  },
  inBlock: {
    display: 'block',
  },
  description: {
    width: props => props.labelWidth,
    fontSize: theme.spacing(1.8),
    marginRight: '1em',
    margin: 'revert',
  },
  addField: {
    fontSize: theme.spacing(1.8),
    marginRight: '1em',
    margin: 'revert',
    textDecorationLine: 'underline',
  },
  iconButton: {
    margin: theme.spacing(0, 0, 0, -6),
    position: 'absolute',
  },
  buttonContainer: {
    padding: theme.spacing(0),
    '&:hover': {
      background: 'none',
    },
  },
}));

const SelectArrayFields = ({ parentName, label, rules, options, xs = 6, labelWidth = '15em', defaultValue }) => {
  const classes = useStyles({ labelWidth });
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name: parentName });

  return (
    <Grid xs={xs} item className={classes.inBlock}>
      {fields.map((item, index) => (
        <Grid key={item.id} className={classes.inlineAdds}>
          <IconButton aria-label="delete" className={classes.iconButton} onClick={() => remove(index)}>
            <DeleteIcon color="error" />
          </IconButton>
          <Typography className={classes.description}>{label}:</Typography>
          <Select
            placeholder="Select"
            options={options}
            className={classes.inputs}
            control={control}
            name={`${parentName}.${index}`}
            rules={rules}
            defaultValue={defaultValue[index]}
          />
        </Grid>
      ))}
      {fields.length < 21 && (
        <Button
          className={classes.buttonContainer}
          color="primary"
          onClick={() => {
            append('');
          }}
        >
          <Typography className={classes.addField}>Add {label}</Typography>
        </Button>
      )}
    </Grid>
  );
};

export default SelectArrayFields;
