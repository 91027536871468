import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { format, isDate, isFuture } from 'date-fns';
import { useSelector } from 'react-redux';

import currencyFormatter from '../../utils/currencyFormatter';
import InvoicingTableRow from './TableRow';
import exportTableCSV from '../../utils/exportTableCSV';
import { trackGaEvent } from '../../utils/trackGa';
import { hasAdminRights, getUserWarehouseIdsInvoiceAccess } from '../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    background: '#fafafa',
  },
  tableHeader: {
    '& th': {
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: '20px',
      height: 50,
      border: 'none',
    },
  },
  button: {
    display: 'inline-flex',
    width: 145,
    height: 45,
    border: '1px solid black',
    borderRadius: 7,
    marginLeft: theme.spacing(1.6),
    padding: theme.spacing(1.5, 2.5),
    '& > span > span': {
      marginLeft: 'auto',
    },
  },
  exportButton: {
    border: 'none',
  },
  searchField: {
    display: 'inline-flex',
    width: 250,
  },
  searchFieldInput: {
    height: 45,
  },
  filterFieldsBox: {
    marginLeft: 'auto',
  },
  headerBox: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  menuItem: {
    width: 145,
  },
}));

const useButtonStyles = makeStyles({
  button: {
    border: '1px solid black',
    borderRadius: 7,
    width: 110,
    height: 35,
  },
});

const DownloadButton = ({ documentUrl }) => {
  const classes = useButtonStyles();

  return documentUrl ? (
    <Button
      component="a"
      className={classes.button}
      variant="outlined"
      href={documentUrl}
      target="_blank"
      rel="noreferrer"
    >
      Download
    </Button>
  ) : (
    <Typography variant="body2">No Document</Typography>
  );
};

const formatDate = value => {
  if (!value) {
    return;
  }

  // This reverses the offset from the admin portal.
  if (value.toDate) {
    return value.toDate().toLocaleString('en-US', {
      timeZone: 'UTC',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  // This can be cleaned when removing mocked data and expect
  // the nee to parse all Firebase timestamps to dates.
  return isDate(value) ? format(value, 'MM/dd/yyyy') : format(value.toDate(), 'MM/dd/yyyy');
};

const columns = [
  { name: 'invoiceNumber', label: 'INVOICE NUMBER' },
  { name: 'bookingId', label: 'BOOKING ID' },
  { name: 'dateOfService', label: 'DATE OF SERVICE' },
  { name: 'shipperName', label: 'SHIPPER NAME' },
  { name: 'invoiceAmount', label: 'INVOICE AMOUNT' },
  { name: 'status', label: 'STATUS' },
  { name: 'paymentDate', label: 'PAYMENT DATE' },
  { name: 'invoiceDocument', label: 'INVOICE', component: DownloadButton },
];

const preProcessedFields = {
  status: (_, row) => {
    // if paymentDate exists and is in the past, read as paid
    if (row.paymentDate && !isFuture(row.paymentDate?.toDate())) {
      return 'paid';
    }

    return row.isPaymentScheduled ? 'scheduled' : 'received';
  },
  dateOfService: value => formatDate(value),
  invoiceAmount: value => value && currencyFormatter.format(value),
  paymentDate: (value, row) => {
    // Checking for "received" status, which is compounded by format function of previous column "status"
    if ((!value || isFuture(value?.toDate())) && !row.isPaymentScheduled) {
      return 'pending';
    }

    return value ? formatDate(value) : formatDate(row.paymentDueDate);
  },
};

const values = {
  all: 'all',
  scheduled: 'scheduled',
  paid: 'paid',
  received: 'received',
};

const labels = {
  [values.all]: 'All Services',
  [values.scheduled]: 'Scheduled',
  [values.paid]: 'Paid',
  [values.received]: 'Received',
};

const options = Object.keys(labels);

const searchFields = ['bookingId', 'shipperName', 'invoiceNumber'];

const sortInvoices = (invoices = []) =>
  invoices.sort((a, b) => (b.dateOfService?.seconds || 0) - (a.dateOfService?.seconds || 0));

const filterInvoices = (invoices, status, search) =>
  invoices.filter(invoice => {
    if (status !== values.all && invoice.status !== status) {
      return false;
    }

    // Evaluate search input by spaces?
    if (searchFields) {
      return searchFields.some(field => invoice[field].toLowerCase().includes(search.trim().toLowerCase()));
    }

    return true;
  });

const exportTableToCSV = (e, invoices) => {
  // Slice to not import invoice column
  const csvOutput = exportTableCSV(columns.slice(0, -1), invoices);
  const csvBlob = new Blob([csvOutput], { type: 'text/csv' });
  const blobUrl = URL.createObjectURL(csvBlob);

  e.currentTarget.href = blobUrl;
  e.currentTarget.download = 'table-invoices-export.csv';

  // remove the file after 1 second
  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
  }, 1000);
};

const InvoicingTable = ({ invoices }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(values.all);
  const [anchorEl, setAnchorEl] = useState(null);
  const isViewerAdmin = useSelector(hasAdminRights);
  const viewerAccessWarehouseIds = useSelector(getUserWarehouseIdsInvoiceAccess);

  const sortedInvoices = useMemo(
    () =>
      sortInvoices(invoices).reduce((acc, invoice) => {
        // Is viewer doesn't has admin rights or
        // isn't related to curernt invoice, exclude current invoice.
        if (!isViewerAdmin && !viewerAccessWarehouseIds.includes(invoice.warehouseId)) {
          return acc;
        }

        const fields = Object.entries(preProcessedFields).reduce(
          (fields, [field, fn]) => ({ ...fields, [field]: fn(invoice[field], invoice) }),
          {}
        );

        acc.push({ ...invoice, ...fields });
        return acc;
      }, []),
    [invoices, viewerAccessWarehouseIds, isViewerAdmin]
  );
  const invoicesData = useMemo(() => filterInvoices(sortedInvoices, status, search), [sortedInvoices, status, search]);

  return (
    <>
      <Box className={classes.headerBox} display="flex" alignItems="center">
        <div className={classes.filterFieldsBox}>
          <TextField
            className={classes.searchField}
            variant="outlined"
            label="Search"
            value={search || ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ className: classes.searchFieldInput }}
            onChange={e => setSearch(e.target.value)}
          />
          <Button className={classes.button} onClick={e => setAnchorEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>
            {labels[status]}
          </Button>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {options.map(option => (
              <MenuItem
                className={classes.menuItem}
                elevation={0}
                selected={status === option}
                key={option}
                onClick={() => {
                  setAnchorEl(null);
                  setStatus(option);
                }}
              >
                {labels[option]}
              </MenuItem>
            ))}
          </Menu>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.exportButton}`}
            onClick={e => {
              trackGaEvent('Admin', 'Clicked Export Invoices to CSV', 'Invoice');
              exportTableToCSV(e, invoicesData);
            }}
            component="a"
          >
            Export
          </Button>
        </div>
      </Box>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            {columns.map((column, index) => (
              <TableCell key={index} align="center">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicesData.map((rowData, index) => (
            <InvoicingTableRow key={index} row={rowData} columns={columns} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default InvoicingTable;
