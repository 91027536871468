/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { CssBaseline } from '@material-ui/core';
import { useSelector } from 'react-redux';
import './App.css';

import Nav from './components/Nav';
import StatusBar from './components/StatusBar';
import AuthScreen from './screens/Auth';
import CompanyScreen from './screens/Company';
import ErrorOccurredScreen from './screens/Auth/ErrorOccurred';
import MessageScreen from './components/Layouts/MessageScreen';
import LoadingScreen from './components/Layouts/LoadingScreen';

import { useFeatureFlags } from './utils/featureFlags';
import { AuthorizedCompanyRoute, UnauthorizedRoute } from './components/Routes';

import { isLoggedIn, isVerified } from './store/selectors/user';
import { getCompany } from './store/selectors/company';
import { isLoadingData } from './store/selectors/warehouses';

const App = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    ReactGA.pageview(`${pathname}${search}`);
  }, [pathname, search]);

  const featureFlags = useFeatureFlags();
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isCompanyLoaded = useSelector(getCompany);
  const isLoadingWarehouses = useSelector(isLoadingData);
  const isUserVerified = useSelector(isVerified);

  // Not render app, featuredFlags throwed error.
  if (featureFlags === false) return <MessageScreen component={ErrorOccurredScreen} />;

  // Wait for initials data to load
  if (isUserLoggedIn === null || isCompanyLoaded === null || isUserVerified === null || isLoadingWarehouses)
    return <LoadingScreen />;

  return (
    <>
      <CssBaseline />
      <StatusBar />
      <Nav />
      <Switch>
        <AuthorizedCompanyRoute path="/company" component={CompanyScreen} />
        <UnauthorizedRoute path="/login" component={AuthScreen} />
        <UnauthorizedRoute path="/sign-up" component={AuthScreen} />
        <Route path="/reset" component={AuthScreen} />
        <Route path="/action" component={AuthScreen} />

        {/* Maybe refactor and move */}
        <Route path="/reset-success" component={AuthScreen} />

        <Redirect to="/login" />
      </Switch>
    </>
  );
};

export default App;
