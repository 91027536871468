import React from 'react';
import { Typography, Container, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SignUpForm from '../../components/Auth/SignUpForm';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  loginFieldsContainer: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
    padding: theme.spacing(0, 6),
  },
  formHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  separator: {
    border: 'none',
    borderTop: '1px dashed #707070',
    opacity: '0.15',
    margin: theme.spacing(5, 0),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h1">
        Register <br />Your Account
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
        Complete these steps to manage your moves
      </Typography>
      <SignUpForm />
      <hr className={classes.separator} />

      <Box textAlign="center">
        <Link underline="none" className={classes.link} to="/login">
          Already have an account?
        </Link>
      </Box>
    </Container>
  );
};
