import React from 'react';
import { Divider, Box, Grid } from '@material-ui/core';
import { TextField, makeStyles } from '@material-ui/core';

import { H2 } from '../common';
import { PhoneNumberField } from '../shared';

import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  subHeader: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: 'auto',
    marginBottom: theme.spacing(1),
    border: `${theme.spacing(0.25)}px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
    padding: theme.spacing(0.5, 1),
    color: theme.palette.primary.main,
    '& > span > svg': {
      fontSize: 16,
      marginRight: theme.spacing(1),
    },
  },
}));

const PointOfContactsBookingAgent = () => {
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box id="contacts" className={classes.root}>
      <H2>Point of Contact</H2>
      <Divider className={classes.divider} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="First Name"
            error={Boolean(errors?.brokerPoc?.firstName)}
            helperText={errors?.brokerPoc?.firstName?.message}
            inputProps={register('brokerPoc.firstName', {
              required: 'Required',
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Last Name"
            error={Boolean(errors?.brokerPoc?.lastName)}
            helperText={errors?.brokerPoc?.lastName?.message}
            inputProps={register('brokerPoc.lastName', {
              required: 'Required',
            })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Title"
            error={Boolean(errors?.brokerPoc?.title)}
            helperText={errors?.brokerPoc?.title?.message}
            inputProps={register('brokerPoc.title')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PhoneNumberField
            fullWidth
            label="Phone"
            control={control}
            name="brokerPoc.phone"
            rules={{
              required: 'Required',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PointOfContactsBookingAgent;
