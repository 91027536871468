import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, CircularProgress, Typography } from '@material-ui/core';

import { trackGaEvent } from '../../utils/trackGa';
import { useAsyncCallback } from '../../hooks/useAsync';
import { functions } from '../../firebase';

const textFieldProps = {
  variant: 'outlined',
  margin: 'normal',
  required: true,
};

const fields = [
  { name: 'fullName', label: 'Full Name' },
  { name: 'email', label: 'Email' },
  { name: 'message', label: 'Message', multiline: true, rows: 4 },
];
const initialInfo = fields.reduce((obj, field) => ({ ...obj, [field.name]: '' }), {});

export default props => {
  const [info, setInfo] = useState(initialInfo);
  const isInfoValid = fields.reduce((valid, field) => valid && !!info[field.name], true);

  const submitContactUsEmail = async () => {
    await functions.httpsCallable('sendContactUsMessage')(info);
    setInfo(initialInfo);
    trackGaEvent('User', 'Submitted support ticket');
    props.onClose();
    return true;
  };
  const [{ isLoading, error }, submit] = useAsyncCallback(submitContactUsEmail, false);

  return (
    <Dialog open={props.isDialogOpen} onClose={() => props.onClose()}>
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (isInfoValid) submit();
          }}
        >
          {fields.map(field => (
            <TextField
              {...textFieldProps}
              {...field}
              key={field.name}
              value={info[field.name]}
              onChange={e => setInfo({ ...info, [field.name]: e.target.value })}
            />
          ))}
          <Button fullWidth disabled={isLoading || !isInfoValid} type="submit" color="primary">
            {isLoading ? <CircularProgress size={16} /> : 'Submit'}
          </Button>
          {error && <Typography color="error">{error.message}</Typography>}
        </form>
      </DialogContent>
    </Dialog>
  );
};
