import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  h3: {
    margin: 0,
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: ({ variant }) => (variant === 'semiBold' ? 600 : 'bold'),
    color: 'black',
  },
});

const H3 = ({ variant, className, children, ...props }) => {
  const classes = useStyles({ variant });

  return (
    <h3 className={`${classes.h3} ${className}`} {...props}>
      {children}
    </h3>
  );
};

export default H3;
