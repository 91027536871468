import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { H3 } from '../common/text';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
  },
  step: {
    marginBottom: theme.spacing(1.5),
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  header: {
    marginLeft: theme.spacing(0.8),
  },
  circleIdx: {
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'baseline',
    justifyContent: 'center',
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  list: {
    marginInlineStart: `${theme.spacing(4)}px`,
    marginBlockStart: `${theme.spacing(0.5)}px`,
    marginBlockEnd: `${theme.spacing(0.5)}px`,
    '& > li > span': {
      position: 'relative',
      left: theme.spacing(-0.8),
    },
  },
}));

const LearMoreSteps = ({ steps, header, stepStyle }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <H3 className={classes.title}>{header}</H3>

      {steps.map(({ header, bullets = [] }, idx) => (
        <div className={classes.step} style={stepStyle}>
          <Grid container spacing={0} alignItems="center" wrap="nowrap">
            <span className={classes.circleIdx}>{++idx}</span>
            <Typography className={classes.header} variant="body2">
              {header}
            </Typography>
          </Grid>

          <ul className={classes.list}>
            {bullets.map(bullet => (
              <li>
                <Typography component="span" variant="body2">
                  {bullet}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default LearMoreSteps;
