import React from 'react';
import { Grid, Link, makeStyles, useTheme } from '@material-ui/core';

import ActionButtons from './ActionButtons';
import { getStatusLabel, getDateString, getStatusColor } from './helpers/utils';
import { BodyText } from '../../common';

const useStyles = makeStyles(theme => ({
  info: {
    height: '100%',
    display: 'inline-flex',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 6,
    background: ({ statusColor }) => statusColor,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    padding: theme.spacing(0.4, 0.8),
    marginBottom: theme.spacing(1),
  },
  labelDate: {
    fontSize: 12,
    color: theme.palette.grey[500],
    lineHeight: '16px',
    flexBasis: 0,
  },
  templateLink: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline',
    marginLeft: theme.spacing(1.2),
  },
}));

const DocumentActions = ({ docStatus, existingFile, existingUploadDate, templateUrl, ...props }) => {
  const theme = useTheme();
  const statusColor = getStatusColor(docStatus, existingFile, theme);
  const classes = useStyles({ statusColor });

  return (
    <Grid container spacing={0} alignItems="center" wrap="nowrap">
      <ActionButtons docStatus={docStatus} existingFile={existingFile} {...props} />

      {existingFile ? (
        <Grid className={classes.info} container direction="column" spacing={0} wrap="nowrap">
          <Grid item xs={12}>
            <BodyText className={classes.label} variant="label">
              {getStatusLabel(docStatus)}
            </BodyText>
          </Grid>
          <Grid className={classes.labelDate} item xs={12}>
            Last uploaded: {getDateString(existingUploadDate)}
          </Grid>
        </Grid>
      ) : (
        <Link className={classes.templateLink} href={templateUrl} target="_blank" rel="noopener noreferrer">
          Document Template
        </Link>
      )}
    </Grid>
  );
};

export default DocumentActions;
