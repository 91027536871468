import React from 'react';
import { useSelector } from 'react-redux';

import DocumentCard from './DocumentCard';

import useAsync from '../../../hooks/useAsync';
import { db } from '../../../firebase';
import { DOC_STATUS, WAREHOUSE_DOCUMENTS } from './constants';
import { uploadFile } from './helpers/utils';
import { getDocumentPrivileges } from '../../../store/selectors/user';

const saveWarehouseDoc = async ({ warehouse, fileKey, file }) => {
  if (!file) return;

  const url = await uploadFile({
    dir: `companies/${warehouse.companyId}/warehouses/${warehouse.id}`,
    fileKey,
    file,
  });

  const document = WAREHOUSE_DOCUMENTS[fileKey];

  const warehousePayload = {
    [document.urlKey]: url,
    [document.statusKey]: DOC_STATUS.NONE,
    [document.dateKey]: new Date(),
    updateBackend: true,
  };

  await db
    .collection('agent_companies')
    .doc(String(warehouse.companyId))
    .collection('warehouses')
    .doc(warehouse.id)
    .set(warehousePayload, { merge: true });

  return { success: true };
};

const WarehouseDocumentCard = ({ warehouse, docKey }) => {
  const document = WAREHOUSE_DOCUMENTS[docKey];

  const { hasViewRights } = useSelector(getDocumentPrivileges(docKey, warehouse.id));

  const [{ isLoading }, onSelectWarehouseFile] = useAsync(saveWarehouseDoc);

  if (!hasViewRights) {
    return null;
  }

  return (
    <DocumentCard
      key={`${docKey}-${warehouse.id}`}
      name={document.name}
      warehouseId={warehouse.id}
      onSelectFile={onSelectWarehouseFile}
      isLoading={isLoading}
      docKey={docKey}
      existingUploadDate={warehouse[document.dateKey]}
      existingFile={warehouse[document.urlKey]}
      docStatus={+warehouse[document.statusKey]}
      templateUrl={document.template}
    />
  );
};

export default WarehouseDocumentCard;
