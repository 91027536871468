import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getCollection } from '../../hooks/getCollection';
import { useExperimentalAsync as useAsync } from '../../hooks/useAsync';
import { hasAdminRights } from '../../store/selectors/user';
import FormSelect from './FormSelect';
import GeneralCompanyInfo from '../GeneralCompanyInfo';

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: '0.9em;',
  },
  gridBoxLeftFields: {
    padding: '12px 40px 12px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  gridBoxRightFields: {
    padding: '12px 40px',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderLeft: 'none',
      marginTop: theme.spacing(2.5),
      textAlign: 'center',
    },
  },
}));

// TODO: add form validation
export default ({ company, onChange }) => {
  const classes = useStyles();
  const isViewerAdmin = useSelector(hasAdminRights);

  const [{ result: vanlines }] = useAsync(() => getCollection('frontend_vanlines'), []);
  return (
    <Box id="general" paddingTop={7.0}>
      <GeneralCompanyInfo onChange={onChange} />
      <Grid container>
        <Grid item xs={12} md={6} className={classes.gridBoxLeftFields}>
          {!!vanlines.length && (
            <FormSelect
              collection={vanlines}
              disabled={!isViewerAdmin}
              onSelect={vanlineId => onChange('vanlineId', vanlineId)}
              value={company.vanlineId || ''}
              label="Van Line"
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridBoxRightFields}>
          <Typography className={classes.description}>
            You can select your specific van line for <b>{company.name}</b> company.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
