import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, StepButton, Typography, CircularProgress } from '@material-ui/core';

import { db } from '../../firebase';
import { useAsyncNewCallback } from '../../hooks/useAsync';
import { isVanLineProviderActive, getCompany, isSlaDocumentStatus } from '../../store/selectors/company';
import { isWarehouseCreated, getWarehouseDocsStatus } from '../../store/selectors/warehouses';
import { STEPS as steps } from './constants';
import { getActiveStep } from './utils';
import { ErrorStepIcon, ReviewStepIcon } from './common';
import LabelHandler from './LabelHandler';
import StyledStepConnector from './StyledStepConnector';

const useStyles = makeStyles(theme => ({
  offset: {
    width: '100%',
    position: 'sticky',
  },
  button: {
    '& .MuiStepLabel-root': {
      borderRadius: '8px',
      '& .MuiStepLabel-iconContainer': {
        display: 'none',
      },
      '& .MuiStepLabel-labelContainer': {
        '& .MuiStepLabel-label': {
          borderRadius: '8px',
          textAlign: 'center',
          background: '#3C96D9',
          padding: '10px 0px',
          marginBottom: '4px',
          marginTop: '8px',
        },
        '& .MuiTypography-caption': {
          borderRadius: '8px',
          textAlign: 'center',
          color: '#CACACA',
          marginTop: '8px',
          fontSize: '10px',
          fontWeight: 'bold',
          padding: '1em',
        },
      },
    },
  },
  stepper: {
    width: '100%',
    height: '82px',
    padding: theme.spacing(3, 8),
    '& .MuiStep-horizontal': {
      marginLeft: '0px',
      marginRight: '4px',
    },
    '& .MuiStepIcon-root': {
      color: '#4A4A4A',
      border: '#6A6A6A solid 1px',
      borderRadius: '50%',
    },
    '& .MuiStepIcon-active': {
      color: '#4A4A4A',
    },
    '& .MuiStepIcon-completed': {
      color: '#2EBA3C',
      border: 'none',
      backgroundColor: '#FFFFFF',
    },
    '& .MuiStepIcon-text': {
      display: 'none',
    },
    '& .MuiStepLabel-label': {
      color: '#FAFAFA',
      width: '100%',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'initial',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '8px',
    },
    '& .Mui-disabled': {
      color: '#686868',
      '& .MuiStepLabel-label': {
        color: '#686868',
      },
    },
    backgroundColor: '#2A2A2A',
  },
  withoutBorder: {
    '& + div': {
      '& span': {
        border: 'none !important',
      },
    },
  },
  circularProgress: {
    color: theme.palette.common.white,
    padding: '0px !important',
  },
  inReview: {
    color: 'rgb(255, 184, 0)',
  },
}));

const OptionalStepButtonText = () => (
  <Typography variant="caption" style={{ color: '#CACACA' }}>
    Added all your warehouses?
  </Typography>
);

const requestVerification = async company => {
  await db.collection('agent_companies').doc(String(company.id)).update({
    'services.sentEmailVerificationOriginAndDestination': true,
    'services.originAndDestinationStatus': 1,
    updateBackend: true,
  });
};

const StatusHandler = ({ serviceStatus }) => {
  const classes = useStyles();
  const company = useSelector(getCompany);
  const [{ isLoading }, doRequestVerification] = useAsyncNewCallback(requestVerification);

  if (serviceStatus === 1) {
    return (
      <StepLabel StepIconComponent={ReviewStepIcon}>
        <LabelHandler label="In Review" />
        <Typography variant="caption" style={{ color: '#CACACA' }}>
          You'll get an email
        </Typography>
      </StepLabel>
    );
  }

  return (
    <StepButton
      className={classes.button}
      onClick={() => doRequestVerification(company)}
      optional={<OptionalStepButtonText />}
    >
      {isLoading ? (
        <CircularProgress size={12} className={classes.circularProgress} />
      ) : (
        <LabelHandler label="Request Verification" />
      )}
    </StepButton>
  );
};

const StatusBar = ({ serviceStatus }) => {
  const classes = useStyles();
  const history = useHistory();
  const hasVanLine = useSelector(isVanLineProviderActive);
  const hasWarehouses = useSelector(isWarehouseCreated);
  const whStatus = useSelector(getWarehouseDocsStatus);
  const slaDocumentStatus = useSelector(isSlaDocumentStatus);

  const currentActiveStep = getActiveStep(hasVanLine, hasWarehouses, serviceStatus, slaDocumentStatus, whStatus);

  return (
    <div className={classes.offset}>
      <Stepper activeStep={currentActiveStep} className={classes.stepper} connector={<StyledStepConnector />}>
        {steps.map((label, index) => {
          const isButtonStep = currentActiveStep === 3 && index === 3;
          const isSlaStep = currentActiveStep === 4 && index === 4;
          const isWhStep = currentActiveStep === 5 && index === 5;
          const islastStepReview = slaDocumentStatus === 3 && whStatus === 3 && serviceStatus === 4 && index === 6;
          return (
            // TODO: Change logic to have independent status components. Mapping a unique component steps that has to be addapted to the many different logics, is not the best.
            <Step key={index} className={classnames({ [classes.withoutBorder]: index === 3 })}>
              {isButtonStep ? (
                <StatusHandler serviceStatus={serviceStatus} />
              ) : isSlaStep ? (
                <StepLabel
                  onClick={() => history.push(label.href)}
                  StepIconComponent={
                    slaDocumentStatus === 1 ? ReviewStepIcon : slaDocumentStatus === 2 ? ErrorStepIcon : null
                  }
                >
                  <LabelHandler label={label.text} isClickable={label.isClickable} />
                </StepLabel>
              ) : isWhStep ? (
                <StepLabel
                  onClick={() => history.push(label.href)}
                  StepIconComponent={whStatus === 2 ? ReviewStepIcon : whStatus === 1 ? ErrorStepIcon : null}
                >
                  <LabelHandler label={label.text} isClickable={label.isClickable} />
                </StepLabel>
              ) : (
                <StepLabel
                  onClick={label.href && !(index > 3 && currentActiveStep < 4) ? () => history.push(label.href) : null}
                  StepIconComponent={islastStepReview ? ReviewStepIcon : null}
                >
                  <LabelHandler
                    label={label.text}
                    isClickable={!(index > 3 && currentActiveStep < 4) && label.isClickable}
                  />
                </StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StatusBar;
