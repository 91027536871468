import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: '1em',
    lineHeight: '13.6px',
    fontFamily: 'sans-serif',
    letterSpacing: '0.3px',
    cursor: 'default',
  },
  isClickable: {
    transition: 'all .1s',
    cursor: 'pointer',
    color: '#FAFAFA',

    '&:hover': {
      transform: 'scale(1.05)',
      textDecoration: 'underline',
    },
  },
  inReview: {
    color: 'rgb(255, 184, 0)',
  },
}));

const newLabel = label => {
  return Boolean(label) ? label.split(',') : '';
};

const LabelHandler = ({ label, isClickable }) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${classes.root} ${isClickable ? classes.isClickable : ''} ${
        label.includes('Review') ? classes.inReview : ''
      }`}
    >
      {newLabel(label)[0]}
      <br /> {newLabel(label)[1]}
    </Typography>
  );
};

export default LabelHandler;
