import React from 'react';
import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

import validateNumber from '../../../utils/validateNumber';
import cleanPhoneNumber from '../../../utils/cleanPhoneNumber';
import { parseError } from '../../FormComponents/helpers';

const PhoneNumberField = ({ name, control, rules = {}, defaultValue, ...rest }) => {
  const {
    field: { onChange, value, ...field },
    formState: { errors },
  } = useController({
    name,
    control,
    rules: {
      ...rules,
      validate: {
        ...rules.validate,
        checkValidNumber: value => validateNumber(value) || 'A valid number is required',
      },
    },
    defaultValue: defaultValue ?? '',
  });

  const error = parseError(errors, name);

  return (
    <TextField
      variant="outlined"
      {...rest}
      inputProps={{
        ...field,
        onChange: e => {
          const value = e.target.value;
          onChange(value.replace(/\D+/g, ''));
        },
        value: cleanPhoneNumber(value),
      }}
      error={Boolean(error)}
      helperText={error?.message}
    />
  );
};

export default PhoneNumberField;
