import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import DocumentCard from './DocumentCard';

import { getCompany } from '../../../store/selectors/company';
import { COMPANY_DOCUMENTS, DOC_STATUS } from './constants';
import { db } from '../../../firebase';
import { uploadFile } from './helpers/utils';
import useAsync from '../../../hooks/useAsync';

const saveCompanyDoc = async ({ company, fileKey, file }) => {
  if (!file) return;

  const url = await uploadFile({
    dir: `companies/${company.id}`,
    fileKey,
    file,
  });

  const document = COMPANY_DOCUMENTS[fileKey];

  const companyPayload = {
    [document.urlKey]: url,
    [document.statusKey]: DOC_STATUS.NONE,
    [document.dateKey]: new Date(),
    updateBackend: true,
  };

  await db.collection('agent_companies').doc(String(company.id)).set(companyPayload, { merge: true });
  return { success: true, company, file };
};

const CompanyDocumentCard = ({ docKey }) => {
  const document = COMPANY_DOCUMENTS[docKey];

  const company = useSelector(getCompany);

  const [{ isLoading }, onSelectCompanyFile] = useAsync(saveCompanyDoc);

  return (
    <Grid key={docKey} item md={6}>
      <DocumentCard
        name={document.name}
        onSelectFile={onSelectCompanyFile}
        isLoading={isLoading}
        docKey={docKey}
        existingUploadDate={company[document.dateKey]}
        existingFile={company[document.urlKey]}
        docStatus={+company[document.statusKey]}
        templateUrl={document.template}
      />
    </Grid>
  );
};

export default CompanyDocumentCard;
