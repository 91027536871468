import React from 'react'
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative',
		marginBottom: '20px',
		borderRadius: props => `${props.borderRadius}px`,
		border: props => `${props.borderWeight}px solid ${props.borderColor}`,
		padding: props => `${props.padding}`,

	},
	legend: {
		fontSize: props => `${props.fontSize}px`,
		fontWeight: props => `${props.fontWeight}`,
		color: props => `${props.color}`

	}
}));

const SectionWrapper = ({ legend, children, borderRadius = 25, borderWeight = 2, borderColor = '#9F9F9F', fontSize = 15, padding = '0 40px', fontWeight = 600, ...props }) => {
	const classes = useStyles({ borderRadius, borderWeight, borderColor, padding, ...props });
	return (
		<fieldset className={classes.container} >
			{legend && <legend><p style={{ fontSize, fontWeight }}>{legend}</p></legend>}
			{children}
		</fieldset>
	)
}
export default SectionWrapper;
