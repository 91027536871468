import { getCompanyAdmins, getPrimaryContact, getInvoicingContact } from '../company';
import {
  getWarehouses,
  isInvoicingContact as isWlInvoicingContact,
  isPrimaryContact as isWlPrimaryContact,
  isAdminContact as isWlAdminContact,
} from '../warehouses';

import { hasAdminRights, hasWlAdminRights } from './access';

// Exporitng other user selectors
export { hasAdminRights, hasWlAdminRights };
export {
  getUserWarehouseIdsInvoiceAccess,
  getWarehouseIdsAdminRights,
  hasInvoicingAccess,
  hasPocAccess,
  hasWlPocAccess,
} from './access';
export { getDocumentPrivileges, getWarehouseIdsDocumentAccess } from './access/documents';
export { hasCompanyAccess, hasDocumentScreenAccess, hasServiceScreenAccess } from './access/screen';

/**
 * Takes entire state, returns the user reducer.
 * @param {Record} state
 * @returns {Record} user reducer
 */
export const getState = state => state.user;

/**
 * Takes entire state, returns if user is logged in to app.
 * @param {Record} state
 * @returns {boolean} test if user is logged in
 */
export const isLoggedIn = ({ user }) => {
  if (user === false) return false;
  if (!!user && !!user.uid) return true;
  return undefined;
};

/**
 * Takes entire state, returns whether the user is verified or not.
 * @param {Record} state
 * @returns {boolean} test if user email is verified
 */
export const isVerified = ({ user }) => {
  if (!user) return user;
  return user.emailVerified;
};

/**
 * Takes entire state, returns user uid.
 * @param {Record} state
 * @returns {string} user uid
 */
export const getUid = state => !!getState(state) && getState(state).uid;

/**
 * Returns if user is surveyor 
 * @param {Record} state
 * @returns {string} user isSurveyor
 */
export const isSurveyor = state => !!getState(state) && getState(state).isSurveyor;

/**
 * Takes entire state, returns user surveyorAccessRequired.
 * @param {Record} state
 * @returns {string} user surveyorAccessRequired
 */
export const getSurveyorAccessRequired = state => !!getState(state) && getState(state).surveyorAccessRequired;

/**
 * Returns if user approvedByAdmin
 * @param {Record} state
 * @returns {boolean}
 */
export const isApprovedByAdmin = state => !!getState(state) && !!getState(state).approvedByAdmin;

/**
 * Takes entire state, returns user warehouse locations related id's.
 * @param {Record} state
 * @returns {Array} related warehouse locations id's
 */
export const getUserRelatedWarehouseIds = state => getState(state).warehouseLocationIds || [];

/**
 * Takes entire state and contact uid, returns whether
 * if that contact has company admin role
 * @param {Record} state
 * @param {String} uid
 * @returns {boolean} test whether provided contact uid has company admin role
 */
export const isAdmin = (state, uid) => !!getCompanyAdmins(state) && getCompanyAdmins(state).includes(uid);

/**
 * Takes entire state, return and test if user has primary contact role in the company.
 * @param {Record} state
 * @returns {boolean} test user primary contact role
 */
export const isPrimaryContact = state => getUid(state) === getPrimaryContact(state);

/**
 * Takes entire state, return and test if user has invoicing contact role in the company.
 * @param {Record} state
 * @returns {boolean} test user invoicing contact role
 */
export const isInvoicingContact = state => getUid(state) === getInvoicingContact(state);

/**
 * Takes entire state and roles, returns if user has
 * at least one provided roles on one warehouses.
 * @param {Record} state
 * @param {Array} roles
 * @returns {boolean} test whether user has at least one of the provided roles.
 */
export const hasAtLeastOneWarehouseRole = (state, roles) => {
  const uid = getUid(state);

  if (!roles?.length) {
    return false;
  }

  for (const warehouse of getWarehouses(state)) {
    if (roles.some(roleToCheck => roleToCheck(state, warehouse.id, uid))) {
      return true;
    }
  }

  return false;
};

/**
 * Takes warehouse id, returns a factory fucntion selector that takes entire state,
 * returns if user has billing address privilege with the provided warehouse id
 * @param {String} warehouseId
 * @returns {Booelan} test if user has billing address privilege
 */
export const hasWlBillingAddressPrivilege = warehouseId => state => {
  const uid = getUid(state);

  return (
    hasAdminRights(state) ||
    isWlPrimaryContact(state, warehouseId, uid) ||
    isWlInvoicingContact(state, warehouseId, uid) ||
    isWlAdminContact(state, warehouseId, uid)
  );
};

/**
 * Takes warehouse id, returns a factory fucntion selector that takes entire state,
 * returns if user has main address privilege with the provided warehouse id
 * @param {String} warehouseId
 * @returns {Booelan} test if user has main address privilege
 */
export const hasWlMainAddressPrivilege = warehouseId => state =>
  hasAdminRights(state) || hasWlAdminRights(warehouseId)(state);
