/**
 * Company services status values
 */
export const SERVICES_STATUS_VALUES = {
  none: 0,
  inReview: 1,
  rejected: 2,
  approved: 3,
  verified: 4,
};

/**
 * Company services status names
 */
export const SERVICES_STATUS_NAMES = {
  [SERVICES_STATUS_VALUES.none]: 'None',
  [SERVICES_STATUS_VALUES.inReview]: 'In Review',
  [SERVICES_STATUS_VALUES.rejected]: 'Rejected',
  [SERVICES_STATUS_VALUES.approved]: 'Approved',
  [SERVICES_STATUS_VALUES.verified]: 'Verified',
};
