export const getActiveStep = (hasVanLine, hasWarehouses, serviceStatus, slaDocumentStatus, whStatus) => {
  if (!hasVanLine) {
    return 1;
  }
  if (!hasWarehouses) {
    return 2;
  }
  if (!serviceStatus || serviceStatus < 2) {
    return 3;
  }

  if (slaDocumentStatus <= 2) {
    return 4;
  }
  if (whStatus < 3) {
    return 5;
  }
  if (serviceStatus === 4) {
    return 6;
  }
  return 7;
};
