import React, { useState } from 'react';
import { Divider, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { MasonryContainer, MASONRY_GUTTER, H2 } from '../../common';
import WarehouseLocationCard from './WarehouseLocationCard';
import AddEditWarehouseDialog from './AddEditWarehouseDialog';
import { getCompany } from '../../../store/selectors/company';
import { hasAdminRights } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: 'auto',
      border: `${theme.spacing(0.25)}px solid ${theme.palette.primary.main} !important`,
      borderRadius: 8,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
      color: theme.palette.primary.main,
      '& > span > svg': {
        fontSize: '1.2rem',
        marginRight: theme.spacing(1.3),
      },
    },
  },
  divider: {
    margin: theme.spacing(2, 0, 4),
  },
  masonryItem: {
    marginBottom: `${MASONRY_GUTTER}px !important`, // matches parent gutter
  },
}));

export default ({ warehouses = {}, isLoading }) => {
  const classes = useStyles();
  const { id: companyId } = useSelector(getCompany);
  const isAdmin = useSelector(hasAdminRights);
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  return (
    <Box id="warehouses" className={classes.root}>
      <div className={classes.headerRow}>
        <H2>Warehouses</H2>
        {isAdmin && (
          <Button
            variant="outlined"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              setSelectedWarehouse();
              setAddEditDialogOpen(true);
            }}
          >
            <AddCircle />
            Add Warehouse
          </Button>
        )}
      </div>
      <Divider className={classes.divider} />

      <MasonryContainer>
        {Boolean(warehouses) &&
          Object.values(warehouses).map(warehouse => (
            <WarehouseLocationCard
              key={warehouse.id}
              warehouse={warehouse}
              warehouses={warehouses}
              className={classes.masonryItem}
              onEdit={() => {
                setSelectedWarehouse(warehouse);
                setAddEditDialogOpen(true);
              }}
              isAdmin={isAdmin}
            />
          ))}
      </MasonryContainer>

      {Boolean(addEditDialogOpen) && (
        <AddEditWarehouseDialog
          companyId={companyId}
          selectedWarehouse={selectedWarehouse}
          onClose={() => setAddEditDialogOpen(false)}
        />
      )}
    </Box>
  );
};
