import { withStyles } from '@material-ui/core/styles';
import { StepConnector } from '@material-ui/core';

export default withStyles(theme => ({
  root: {
    '& $line': {
      border: '2px dashed #CACACA',
    },
  },
  active: {
    '& $line': {
      border: '1px dashed #CACACA',
    },
    '& .MuiStepIcon-active': {
      color: theme.palette.success.light,
      backgroundColor: theme.palette.success.light,
    },
  },
  completed: {
    '& $line': {
      border: '2px solid',
      borderColor: theme.palette.success.light,
      backgroundColor: theme.palette.success.light,
    },
  },
  disabled: {
    color: '#686868',
    '& $line': {
      border: '1px #686868 dashed',
    },
  },
  line: {
    border: '1px dashed #CACACA',
  },
}))(StepConnector);
