export const getWarehousesMap = state => state.warehouses.warehouses;

export const isLoadingData = state => {
  return state.isLoading;
};

export const getWarehouses = state => Object.values(getWarehousesMap(state));

export const getWarehouseById = id => ({ warehouses: { warehouses } }) => warehouses[id];

export const isInvoicingContact = (state, warehouseId, uid) =>
  getWarehouseById(warehouseId)(state)?.contacts?.[uid]?.invoicingContact;

export const isOperationsContact = (state, warehouseId, uid) =>
  getWarehouseById(warehouseId)(state)?.contacts?.[uid]?.operationsContact;

export const isEscalationContact = (state, warehouseId, uid) =>
  getWarehouseById(warehouseId)(state)?.contacts?.[uid]?.escalationContact;

export const isPrimaryContact = (state, warehouseId, uid) =>
  getWarehouseById(warehouseId)(state)?.contacts?.[uid]?.primaryContact;

export const isAdminContact = (state, warehouseId, uid) =>
  getWarehouseById(warehouseId)(state)?.contacts?.[uid]?.adminContact;

export const isWarehouseCreated = state => Boolean(getWarehouses(state).length);

/**
 * test and return the current status of this stepper by order
 * 0 - nothing yet, 1 - A doc is rejected, 2 - A doc is in review, 3 - docs are approved
 */
export const getWarehouseDocsStatus = state => {
  const warehouses = getWarehouses(state);

  let isDocsApproved = true;
  let hasDocInReview = false;

  // TODO: Find a better logic for returning status
  for (const warehouse of warehouses) {
    if ('isTariffDoesntApply' in warehouse && !warehouse.isTariffDoesntApply) {
      // Check unload and load rates doc
      const tarrifStatus = warehouse.tariff_document_status;
      const hasDoc = Boolean(warehouse.tariff_document);

      switch (tarrifStatus) {
        case 0:
          isDocsApproved = false;
          if (hasDoc) {
            hasDocInReview = true;
          }
          break;
        case 1:
          return 1;
        case 2:
          break;
        default:
          return 1;
      }
    }

    const w9Status = warehouse.w9_document_status;
    const hasDoc = Boolean(warehouse.w9_document);

    switch (w9Status) {
      case 0:
        isDocsApproved = false;
        if (hasDoc) {
          hasDocInReview = true;
          return 2;
        }
        break;
      case 1:
        return 1;
      case 2:
        break;
      default:
        return 1;
    }
  }

  if (isDocsApproved) {
    return 3;
  }

  return hasDocInReview ? 2 : 0;
};
