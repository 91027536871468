import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(30),
  },
  card: {
    /* background: cornflowerblue; */
    height: 150,
    width: 201,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    boxShadow: '1px 2px 10px lightgrey',
  },
}));

// Maybe implement random quotes on loading screen?
// Or a simple photo, or something more descriptive?
// Or a more professional designed loading screen?

export default () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container alignItems="center" justify="center">
      <div className={classes.card}>
        <CircularProgress size={60} />
      </div>
    </Grid>
  );
};
