import React from 'react';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    userSelect: 'none',
    '& > span:first-child': {
      fontWeight: 'bold',
      '.Mui-checked ~ span &': {
        color: theme.palette.primary.main,
      },
    },
  },
  roleWarning: {
    color: theme.palette.warning.main,
    marginBottom: theme.spacing(1),
  },
}));

const RoleCheckbox = ({ roleKey, roles, setRoles, company, roleNames, user, isWlPocForm, warehouseRoles }) => {
  const classes = useStyles();
  const checked = roles[roleKey] || false;

  // If currently this contact is set to someone, get that contact object.
  const role = `${roleKey}Contact`;

  const roleCurrentContact = isWlPocForm
    ? warehouseRoles[role] && {
        // Since basic info lives in the company level properties,
        // and poc's will live depends where's it's added either company[contacts || warehouseContacts]
        // combine the 2 contacts props to get provided contact properties
        ...company.contacts[warehouseRoles[role]],
        // warehouseContact on initial of company doesn't exist.
        ...company.warehouseContacts?.[warehouseRoles[role]],
      }
    : company[role] && company.contacts[company[role]];

  const willOverwriteRole = checked && roleCurrentContact && roleCurrentContact.id !== user.id;

  return (
    <>
      <FormControlLabel
        key={roleKey}
        control={<Checkbox checked={checked} onChange={() => setRoles({ ...roles, [roleKey]: !roles[roleKey] })} />}
        label={
          <div className={classes.checkboxLabel}>
            <span>{roleNames[roleKey].label}</span>
          </div>
        }
      />
      {willOverwriteRole && (
        <Typography variant="subtitle2" className={classes.roleWarning}>
          <>
            This will override the existing{' '}
            <b>
              {roleNames[roleKey].label} - {roleCurrentContact?.firstName} {roleCurrentContact?.lastName}
            </b>
          </>
        </Typography>
      )}
    </>
  );
};

export default RoleCheckbox;
