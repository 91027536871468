import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(4),
  },
}));

export default props => {
  const classes = useStyles();
  const { title, subtitle } = props;

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </>
  );
};
