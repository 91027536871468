import React from 'react';
import { InputLabel, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { STATES } from './States.js';

export default ({
  name,
  label,
  size,
  type,
  value,
  onChange,
  disabled,
  error,
  helperText,
  className,
  placeholder,
  displayEmpty,
  ...rest
}) => (
  <FormControl variant="outlined" style={{ width: '100%' }} className={className}>
    <InputLabel className="label" outlined="true">
      State
    </InputLabel>
    <Select
      error={error}
      name={name}
      label={label}
      disabled={disabled}
      value={value}
      onChange={onChange}
      className="select"
      style={{ width: '100%' }}
      displayEmpty={displayEmpty}
      renderValue={selected => {
        if (!selected.length) {
          return <span style={{ color: 'grey' }}>{placeholder}</span>;
        }
        return selected;
      }}
      {...rest}
    >
      {STATES.map(state => (
        <MenuItem key={state.value} className="menuItem" value={state.value}>
          {state.name}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </FormControl>
);
