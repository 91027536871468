import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Button, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useAsync from '../../hooks/useAsync';
import { auth } from '../../firebase';

const useStyles = makeStyles(theme => ({
  loginFieldsContainer: {
    margin: `${theme.spacing(6)}px 0`,
    '& > *': {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  formContainer: {
    marginTop: theme.spacing(20),
  },
  resetBtn: {
    marginTop: theme.spacing(5),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

const requestPasswordReset = async ({ email }) => {
  await auth.sendPasswordResetEmail(email);
};

export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [{ result, isLoading, error }, doReset] = useAsync(() => requestPasswordReset({ email }));

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        doReset();
      }}
    >
      {!!!result ? (
        <div>
          <Redirect to="/reset-success" />
        </div>
      ) : (
        <div>
          <TextField required fullWidth variant="outlined" label="Email" value={email || ''} onChange={e => setEmail(e.target.value)} />
          {error && (
            <Typography variant="subtitle2" color="error">
              {error.message}
            </Typography>
          )}
          <Button className={classes.resetBtn} fullWidth variant="contained" type="submit" color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={21} /> : 'Reset Password'}
          </Button>
        </div>
      )}
    </form>
  );
};
