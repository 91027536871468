import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
  tableCell: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '20px',
    '&.bold': {
      fontWeight: 'bold',
    },
    '&.button': {
      width: '20%',
    },
    '&.relative': {
      position: 'relative',
      width: '20%',
      height: 60,
    },
  },
});

const renderField = (row, field) => {
  if (field.component) {
    return <field.component documentUrl={row[field.name]} />;
  }
  return row[field.name];
};

const InvoicingTableRow = ({ row, columns }) => {
  const classes = useStyles();

  return (
    <TableRow>
      {columns.map((field, index) => (
        <TableCell
          key={`invoice-${row.id}-${row[field.name]}`}
          align="center"
          className={classnames({
            [classes.tableCell]: true,
            bold: index === 0,
            button: field.className === 'button',
          })}
        >
          {renderField(row, field)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default InvoicingTableRow;
