import { storage } from '../../../../firebase';

export const getStatusColor = (docStatus, existingFile, theme) => {
  switch (docStatus) {
    case 1: {
      // Rejected
      return theme.palette.error.light;
    }
    case 2: {
      // Approved
      return theme.palette.success.light;
    }
    default: {
      // If document exists, assume that it's in review, otherwise no docoument yet
      return existingFile ? theme.palette.warning.darker : '';
    }
  }
};

export const getStatusLabel = docStatus => {
  switch (docStatus) {
    case 1: {
      // Rejected
      return 'Rejected';
    }
    case 2: {
      // Approved
      return 'Approved';
    }
    default: {
      // status label only appears when there's a document.
      return 'In Review';
    }
  }
};

export const getDateString = dateOrTimestamp => {
  if (!dateOrTimestamp) return;

  if (dateOrTimestamp?.toDate) {
    return dateOrTimestamp.toDate().toLocaleDateString();
  }
  return dateOrTimestamp?.toLocaleDateString();
};

export const uploadFile = async ({ dir, fileKey, file }) => {
  const extension = file.type.split('/')[1];
  const fileRef = storage.ref().child(`/${dir}/${fileKey}.${extension}`);
  const snapshot = await fileRef.put(file);
  return snapshot.ref.getDownloadURL();
};
