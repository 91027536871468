import React, { useRef } from 'react';
import { Grid, Paper, Typography, Divider, makeStyles } from '@material-ui/core';
import { useSameHeight } from '../../hooks';

const useStyles = makeStyles(theme => ({
  columnContainer: {
    height: '100%', // make all the columns appear to be the same height
    borderRadius: theme.spacing(3),
    padding: theme.spacing(4),
    boxShadow: 'none',
    '& > h2': {
      fontSize: '1.5rem',
    },
    '& > hr': {
      margin: theme.spacing(3, 0),
    },
  },
}));

const ServicesColumn = ({ headerText, children }) => {
  const classes = useStyles();
  const headerContainerRef = useRef(null);
  useSameHeight(`ServiceSectionsHeader`, headerContainerRef);

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.columnContainer}>
        <Typography variant="h2" ref={headerContainerRef}>
          {headerText}
        </Typography>
        <Divider />
        {children}
      </Paper>
    </Grid>
  );
};

export default ServicesColumn;
