import React, { useRef } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { useSameHeight } from '../../hooks';
import RegisterButton from '../BookingAgent/RegisterButton';
import { handleColor, handleDisableBtn, handleLabel } from './helpers';

// Workaround due to comma-separated media queries not working in JSS
const narrowColumnButtonStyling = theme => ({
  flexDirection: 'column',
  '& > *': {
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
});

const useStyles = makeStyles(theme => ({
  serviceSection: {
    // flexing button to the bottom of the div, in case the height is taller than auto (as dictated by useSameHeight)
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '& > *:only-child': {
      // push button to the right if there's no learn-more link
      marginLeft: 'auto',
    },
    '& > button': {
      padding: theme.spacing(1.5, 7),
      '&[disabled]': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        opacity: 0.5,
      },
    },
    // Not using theme breakpoints due to buttons conflicting differently at different sizes.
    '@media (max-width: 500px)': narrowColumnButtonStyling(theme),
    '@media (min-width: 800px) and (max-width: 1200px)': narrowColumnButtonStyling(theme),
  },
}));

// TODO: Change Component to be reactive mostly only by a status given, not props base to handle
const ServiceSection = ({ description, onLearnMore, onRegister, isLoading, status, indexInColumn, hasRights }) => {
  const classes = useStyles();

  // keep the same height of each row across columns
  const containerRef = useRef(null);
  useSameHeight(`ServiceSections_${indexInColumn}`, containerRef);

  return (
    <div ref={containerRef} className={classes.serviceSection}>
      <Typography variant="body1">{description}</Typography>
      <div className={classes.actionsContainer}>
        {Boolean(onLearnMore) && <span onClick={onLearnMore}>Learn More &rarr;</span>}

        <RegisterButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={handleDisableBtn(status, onRegister) || !hasRights}
          onClick={onRegister}
          isLoading={isLoading}
          handleColor={handleColor(status)}
          labelBtn={handleLabel(status, onRegister)}
        />
      </div>
    </div>
  );
};

export default ServiceSection;
