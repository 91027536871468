import React from 'react';
import {
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: 900,
    display: 'flex',
    justifyContent: 'space-between',
    borderColor: theme.palette.common.primary,
    marginBottom: theme.spacing(1),
  },

  container: {
    marginBottom: '60px',
    position: 'relative',
  },

  descriptionContainer: {
    height: '55px',
  },
  description: {
    fontSize: theme.spacing(2.2),
    lineHeight: '26px',
    width: '600px',
  },
  cardContent: {
    display: 'flex',
    padding: theme.spacing(3, 2, 0, 3),
  },
  cardActions: {
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
  },
  title: {
    alignSelf: 'center',
    fontWeight: 800,
    fontSize: '20px',
  },
  price: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  checkBoxContainer: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    width: '100%',
    marginTop: '-17px',
    boxShadow: '0px 3px 1px -1px rgb(0 0 0 / 20%), 0px 3px 1px 0px rgb(0 0 0 / 14%), 0px 3px 3px 0px rgb(0 0 0 / 12%)',
    height: '49px',
    display: ' flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '10px',
  },
}));

const useStylesLabelCheckBox = makeStyles(theme => ({
  checkBoxContainer: {
    alignItems: 'flex-start',
    textAlign: 'left',
    marginInline: '0px',
    '&>*:last-child': {
      padding: '9px',
    },
  },
}));

const LabelCheckBox = ({ onChange, label, name, value, disabler }) => {
  const classes = useStylesLabelCheckBox();

  return (
    <FormControlLabel
      className={classes.checkBoxContainer}
      control={
        <Checkbox
          disabled={disabler}
          name={name}
          checked={value}
          onChange={e => {
            onChange(e.target.checked);
          }}
          color="primary"
        />
      }
      label={label}
    />
  );
};

const InstantQuoteCard = ({
  title,
  description,
  price,
  link,
  label,
  onChange,
  value,
  disabler,
  text,
  name,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          styles={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.price} variant="h4">
            $ {price}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
          <Typography className={classes.description} variant="h5">
            {text}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className={classes.checkBoxContainer}>
        <LabelCheckBox
          value={value}
          name={name}
          disabler={disabler}
          label={'Select for Reservation'}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default InstantQuoteCard;
