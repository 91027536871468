import { db } from '../../../../firebase';
import axios from 'axios';

export const getThirdPartyCompanyServices = async () => {
  const snapshot = await db.collection('third_party_company_services').get();

  return snapshot.docs.reduce(
    (acc, doc) => {
      const thirdParty = doc.data();

      if (thirdParty.available_at === 0) {
        return {
          originValues: [...acc.originValues, { label: thirdParty.name, value: thirdParty.id }],
          destinationValues: [...acc.destinationValues, { label: thirdParty.name, value: thirdParty.id }],
        };
      }

      if (thirdParty.available_at === 1) {
        return { ...acc, originValues: [...acc.originValues, { label: thirdParty.name, value: thirdParty.id }] };
      } else {
        return {
          ...acc,
          destinationValues: [...acc.destinationValues, { label: thirdParty.name, value: thirdParty.id }],
        };
      }
    },
    { originValues: [], destinationValues: [] }
  );
};

export const getAutoTransportServiceData = async payload => {
  const response = await axios.post(`${process.env.REACT_APP_CARSELO_API_URL}/api/GetVehicleData`, payload, {
    headers: {
      'Content-Type': 'application/json',
      'x-functions-key': process.env.REACT_APP_CARSELO_API_KEY,
    },
  });

  return response.data;
};
