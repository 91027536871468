import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PasswordRecoveryForm from '../../components/Auth/PasswordRecoveryForm';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  loginFieldsContainer: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
    padding: theme.spacing(0, 6),
  },
  formHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  separator: {
    border: 'none',
    borderTop: '1px dashed #707070',
    opacity: '0.15',
    margin: theme.spacing(6, 0),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h4" className={classes.formHeading}>
        Walkboard Partner Portal
      </Typography>
      <Typography variant="h1"> Reset your Password</Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
        Request a reset password email
      </Typography>
      <PasswordRecoveryForm />
      <hr className={classes.separator} />
      <Box textAlign="center">
        <Link underline="none" className={classes.link} to="/login">
          Already have an account?
        </Link>
      </Box>
    </Container>
  );
};
