import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { DocumentUploadSmall as DocumentUploadSmallIcon } from '../../../images';
import { getWarehouseById } from '../../../store/selectors/warehouses';
import { getCompany } from '../../../store/selectors/company';
import { getDocumentPrivileges } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 6,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
  },
  actionBtn: {
    padding: theme.spacing(0.681, 1),
    width: theme.spacing(13),
    marginRight: theme.spacing(1.5),
    background: ({ isStatusReview }) => (isStatusReview ? 'inherit' : theme.palette.primary.main),
    color: ({ isStatusReview }) => (isStatusReview ? theme.palette.primary.main : theme.palette.common.white),
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: ({ isStatusReview }) =>
        `2px solid ${isStatusReview ? theme.palette.primary.main : theme.palette.primary.darker}`,
    },
  },
  uploadIcon: {
    width: theme.spacing(2),
    fill: ({ isStatusReview }) => (isStatusReview ? theme.palette.primary.main : theme.palette.common.white),
  },
  openBtn: {
    minWidth: 0,
    padding: theme.spacing(0.681, 2.5),
    marginRight: theme.spacing(1.5),
    border: `2px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[500],
  },
  inputFile: {
    display: 'none',
  },
  inputLabel: {
    display: 'flex',
  },
}));

const ActionButtons = ({ docStatus, docKey, existingFile, onSelectFile, isLoading, warehouseId }) => {
  const company = useSelector(getCompany);
  const warehouse = useSelector(getWarehouseById(warehouseId));
  const { hasDownloadRights, hasUploadRights } = useSelector(getDocumentPrivileges(docKey, warehouseId));

  // Needed for the dynamic color button for the status card
  const isStatusReview = Boolean(docStatus === 0 && existingFile);
  const classes = useStyles({ isStatusReview });
  const inputId = `update-${docKey} ${warehouse ? `-${warehouse.id}` : ''}`;

  return (
    <>
      {hasUploadRights && docStatus !== 2 && (
        <>
          <input
            className={classes.inputFile}
            accept="image/*, application/pdf"
            id={inputId}
            type="file"
            onChange={e =>
              e.target.files.length && onSelectFile({ fileKey: docKey, file: e.target.files[0], company, warehouse })
            }
          />
          <label className={classes.inputLabel} htmlFor={inputId}>
            <Button
              component="span"
              variant={isStatusReview ? 'outlined' : 'contained'}
              startIcon={<DocumentUploadSmallIcon className={classes.uploadIcon} />}
              className={`${classes.button} ${classes.actionBtn}`}
              disabled={isLoading}
              color="primary"
            >
              {existingFile ? 'Update' : 'Upload'}
            </Button>
          </label>
        </>
      )}

      {hasDownloadRights && existingFile && (
        <Button
          className={`${classes.button} ${classes.openBtn}`}
          variant="outlined"
          component="a"
          href={existingFile}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open
        </Button>
      )}
    </>
  );
};

export default ActionButtons;
