import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: ({ flexbox }) => (flexbox ? 'flex' : 'block'),
    alignItems: 'center',
    minHeight: 44,
    backgroundColor: theme.palette.common.white,
    color: lightBlue[500],
    borderColor: lightBlue[500],
    borderLeftWidth: theme.spacing(1),
    padding: theme.spacing(1),
    '& > h6': {
      lineHeight: '2',
    },
  },
}));

export default WrappedContent => ({ flexbox, ...rest }) => {
  const classes = useStyles({ flexbox });

  return (
    <Paper variant="outlined" className={classes.root}>
      <WrappedContent {...rest} />
    </Paper>
  );
};
