import { v4 as uuidV4 } from 'uuid';

export const DEFAULT_PRICING_FIELDS = [
  {
    id: uuidV4(),
    weight: 0,
    requested_delivery_date: '',
    requested_pack_load_date: '',
    destination_third_party_services: [],
    origin_third_party_services: [],
    national_account_id: null,
    origin_formatted_string: '',
    destination_formatted_string: '',
    origin_map_position: null,
    destination_map_position: null,
    auto_transport_quotes: [],

    destination_shuttle: 0,
    destination_long_carry: 0,
    destination_stair_carry: 0,
    destination_elevator: 0,

    origin_shuttle: 0,
    origin_long_carry: 0,
    origin_stair_carry: 0,
    origin_elevator: 0,
  },
];

export const AUTO_TRANSPORT_GET_YEARS_DATA =
  Array(new Date().getFullYear() - 1992 + 1)
    .fill()
    .map((_, idx) => 1992 + idx) || [];
