export const handleLabel = (status, onRegister) => {
  switch (status) {
    case 5: 
      return 'Request Access'
    case 4:
      return 'Registration in progress';
    case 3:
      return 'Approved';
    case 2:
      return 'Ineligible';
    case 1:
      return 'Under Review';
    default:
      if (onRegister) {
        return 'Register Now';
      }
      return 'Coming Soon';
  }
};

export const handleColor = status => {
  switch (status) {
    case 3:
      return 'green';
    case 1:
      return 'orange';
    default:
      return null;
  }
};

export const handleDisableBtn = (status, onRegister) => {
  if (!onRegister || status === 2) {
    return true;
  }

  return false;
};
