import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import LoginScreen from './Login';
import SignUpScreen from './SignUp';
import VerificationPromptScreen from './VerificationPrompt';
import ApprovalScreen from './Approval';
import PasswordRecoveryScreen from './PasswordRecovery';
import PasswordRecoverySuccessScreen from './PasswordRecoverySuccess';
import EmailActionScreen from './EmailAction';

import moverImg from '../../images/mover.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(8)}px)`, // Entire Scree - NavBar
  },
  sideImage: {
    objectFit: 'cover',
    position: 'fixed',
    top: 0,
    bottom: 0,
    zIndex: -1,
    width: '50%',
    height: '100vh',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-3),
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} alignItems="center">
      <Hidden smDown>
        <Grid item md={6}>
          <img src={moverImg} className={classes.sideImage} alt="side_image" />
        </Grid>
        <Grid item md={1} />
      </Hidden>
      <Grid item md={4} xs={12}>
        <Switch>
          <Route path="/login" component={LoginScreen} />
          <Route path="/reset" component={PasswordRecoveryScreen} />
          <Route path="/reset-success" component={PasswordRecoverySuccessScreen} />
          <Route path="/sign-up" component={SignUpScreen} />
          <Route path="/verify" component={VerificationPromptScreen} />
          <Route path="/approval" component={ApprovalScreen} />
          <Route path="/action" component={EmailActionScreen} />
        </Switch>
      </Grid>
    </Grid>
  );
};
