import React, { useState, useEffect } from 'react';
import { Typography, Button, CircularProgress, Box, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { functions, auth } from '../../firebase';
import useAsync from '../../hooks/useAsync';

const useStyles = makeStyles(theme => ({
  loginFieldsContainer: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
  signinBtn: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

const signInWithCreds = async ({ email, password }) => {
  try {
    const { data: token } = await functions.httpsCallable('loginWithCreds')({ email, password });
    return token;
  } catch (e) {
    throw new Error('The e-mail or password you entered does not match our records.');
  }
};

export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [{ result: token, isLoading, error }, doLogin] = useAsync(() => signInWithCreds({ email, password }), false);

  useEffect(() => {
    const signIn = async token => {
      await auth.signInWithCustomToken(token);
    };

    if (token) {
      signIn(token);
    }
  }, [token]);

  return (
    <form
      className={classes.loginFieldsContainer}
      onSubmit={e => {
        e.preventDefault();
        doLogin();
      }}
    >
      <TextField
        required
        fullWidth
        variant="outlined"
        label="Email"
        value={email || ''}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        required
        fullWidth
        variant="outlined"
        type="password"
        label="Password"
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
      />
      {error && (
        <Typography variant="subtitle2" color="error">
          {error.message}
        </Typography>
      )}
      <Box textAlign="right">
        <Link className={classes.link} to="/reset">
          Forgot password?
        </Link>
      </Box>

      <Button
        className={classes.signinBtn}
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={21} /> : 'Sign In'}
      </Button>
      <Typography />
    </form>
  );
};
