import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const getThemeStyles = ({ variant, color, size }) => {
  const base = {
    fontSize: size || 16,
    lineHeight: `${(Boolean(size) && size + 4) || 20}px`,
    color: color || '#1A1A1A',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  switch (variant) {
    case 'label': {
      return {
        ...base,
        fontSize: size || 14,
        lineHeight: '18px',
        color: color || '#9F9F9F',
      };
    }
    case 'large': {
      return {
        ...base,
        fontSize: 18,
        lineHeight: '23px',
      };
    }
    // Add more variants here if needed.
    // Variants as found in designs: https://www.figma.com/file/DH8PcYUlC4VFVcyMZrxb0Z/Partner-Portal?node-id=729%3A3479
    default: {
      return base;
    }
  }
};

const useStyles = makeStyles({
  text: {
    color: ({ textStyles }) => textStyles.color,
    fontSize: ({ textStyles }) => textStyles.fontSize,
    lineHeight: ({ textStyles }) => textStyles.lineHeight,
    overflow: ({ textStyles }) => textStyles.overflow,
    textOverflow: ({ textStyles }) => textStyles.textOverflow,
    whiteSpace: ({ textStyles }) => textStyles.whiteSpace,
  },
});

const BodyText = ({ className, children, color, size, el, variant, styles, width, ...rest }) => {
  const textStyles = getThemeStyles({ variant, color, size });
  const classes = useStyles({ textStyles });
  const props = {
    className: `${classes.text} ${className}`,
    ...rest,
  };
  const Component = el || 'span';

  return (
    <Component style={{ width, ...styles }} {...props}>
      {children}
    </Component>
  );
};
export default BodyText;
