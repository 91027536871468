import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import InvoicingTable from '../components/Invoicing/Table';
import { useFirestoreSubscribe } from '../hooks';
import { getCompany, isServiceProviderActive } from '../store/selectors/company';
import { useFeatureFlagsByKey } from '../utils/featureFlags';
import { getInvoices } from '../mocks';
import PageHeader from '../components/PageHeader';

const useStyles = makeStyles(theme => ({
  loadingCircle: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3),
  },
}));

const InvoiceScreen = () => {
  const classes = useStyles();
  const company = useSelector(getCompany);
  const companyHasServiceProvider = useSelector(isServiceProviderActive);
  const [invoices, isLoading] = useFirestoreSubscribe(`/agent_companies/${company.id}/invoices`);
  const isInvoicesMocked = useFeatureFlagsByKey('invoicingMockedData');

  if (!companyHasServiceProvider) {
    return <Redirect to="/company/info" />;
  }

  if (isLoading) {
    return <CircularProgress className={classes.loadingCircle} size={60} thickness={4.0} />;
  }

  return (
    <>
      <PageHeader title="Invoices for Completed Services" />
      <InvoicingTable invoices={isInvoicesMocked ? getInvoices(company.id) : invoices} />
    </>
  );
};
export default InvoiceScreen;
