/**
 * New company status bar steps
 */
export const STEPS = [
  { text: 'Create an, account' },
  { text: 'Select your, Van Line', isClickable: true, href: '/company/info' },
  { text: 'Add your, Warehouse(s)', isClickable: true, href: '/company/info' },
  { text: 'Get Verified!' },
  { text: 'Submit your, Level Agreement', isClickable: true, href: '/company/documents' },
  { text: 'Submit your, Warehouse(s) Docs', isClickable: true, href: '/company/documents' },
  { text: "You're, Approved" },
];
