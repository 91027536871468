import { SET_WAREHOUSES } from '../actions/warehouses';

export const initialState = { warehouses: [], isLoading: false };

const normalizeSnapshot = array =>
  array?.reduce?.((acc, element) => {
    acc[element.id] = element.data();
    return acc;
  }, {}) || {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WAREHOUSES: {
      const { isLoading, warehouses } = action.payload;
      return {
        ...state,
        isLoading,
        warehouses: normalizeSnapshot(warehouses),
      };
    }
    default:
      return state;
  }
};
