import React from 'react';
import { makeStyles } from '@material-ui/core';
import RegisterButton from '../RegisterButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    marginBottom: '12px',
    border: '1px solid #9F9F9F',
    borderRadius: '4px',
    width: '100%',

    '& span': {
      fontWeight: '800',
      fontSize: '16px',
      margin: '0 5px 0 10px',
    },
  },
}));

const Quote = ({ fullQuote, setSelectedFullQuote }) => {
  const classes = useStyles();
  const segments = Object.values(fullQuote?.segments);
  const firstSegment = segments[0];

  const cityStateHandler = location => {
    let locationArray = location.split(',');
    if (locationArray.length === 4) {
      let arrayFiltered = locationArray.splice(1, 2);
      return arrayFiltered.join(',');
    }
    return location;
  };
  return (
    <div className={classes.container}>
      <p>
        <span>From: </span>
        {cityStateHandler(firstSegment.origin_formatted_string)}
      </p>
      <p>
        <span>To: </span>
        {cityStateHandler(firstSegment.destination_formatted_string)}
      </p>
      <p>
        <span>Weight: </span>
        {firstSegment.estimated_weight} lbs
      </p>
      <p>
        <span>Packing on: </span>
        {firstSegment.requested_pack_load_date}
      </p>
      <RegisterButton
        className={classes.buttonContainer}
        variant="contained"
        color="primary"
        type="submit"
        labelBtn="Apply"
        onClick={() => setSelectedFullQuote({ segments })}
      />
    </div>
  );
};

export default Quote;
