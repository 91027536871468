import React from 'react';
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    '&.orange': {
      background: orange[400],
      '&:hover': {
        background: orange[800],
      },
      '&:disabled': {
        background: '#bc7a34 !important',
        color: `${theme.palette.common.white} !important`,
        opacity: 0.5,
      },
    },
    '&.green': {
      background: theme.palette.success.main,
      '&:hover': {
        background: theme.palette.success.dark,
      },
      '&:disabled': {
        background: '#9ab8a1 !important',
        color: `${theme.palette.common.white} !important`,
        opacity: 0.5,
      },
    },
  },
}));

const HandleLoaderRegister = ({ isLoading, labelBtn = '' }) => {
  return isLoading ? <CircularProgress color="inherit" size={21} /> : labelBtn;
};

const RegisterButton = ({ labelBtn, handleColor, isLoading, inReview, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <Button className={`${classes.root} ${handleColor}`} disabled={disabled} {...rest}>
      <HandleLoaderRegister isLoading={isLoading} labelBtn={labelBtn} />
    </Button>
  );
};

export default RegisterButton;
