import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { auth } from '../../firebase';
import useAsync, { useExperimentalAsync } from '../../hooks/useAsync';

const useStyles = makeStyles(theme => ({
  resetBtn: {
    marginTop: theme.spacing(5),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

const resetPassword = async (code, newPassword) => {
  await auth.confirmPasswordReset(code, newPassword);
  return true;
};

const verifyCode = async code => {
  return auth.verifyPasswordResetCode(code);
};

export default ({ actionCode }) => {
  const classes = useStyles();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [{ result: hasBeenReset, isLoading: isProcessingReset }, doReset] = useAsync(() =>
    resetPassword(actionCode, password),
    false
  );
  const [{ result: verifiedEmail }] = useExperimentalAsync(
    () => verifyCode(actionCode),
    undefined
  );

  const isEmpty = !password || !confirmPassword;
  const isNotConfirmed = password !== confirmPassword;
  const isInvalid = isEmpty || isNotConfirmed;
  return Boolean(verifiedEmail) ? (
    <form
      onSubmit={e => {
        e.preventDefault();
        doReset();
      }}
    >
      <TextField disabled fullWidth margin="normal" variant="outlined" label="Email" value={verifiedEmail} />
      <TextField
        required
        fullWidth
        margin="normal"
        variant="outlined"
        type="password"
        label="New Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <TextField
        required
        fullWidth
        margin="normal"
        variant="outlined"
        type="password"
        label="Confirm New Password"
        value={confirmPassword}
        error={isNotConfirmed}
        helperText={isNotConfirmed && 'Password does not match'}
        onChange={e => setConfirmPassword(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={isProcessingReset || isInvalid}
        className={classes.resetBtn}
      >
        {isProcessingReset ? <CircularProgress size={21} /> : 'Reset Password'}
      </Button>
      {hasBeenReset && <Redirect to="/login" />}
    </form>
  ) : (
    <CircularProgress size={24} />
  );
};
