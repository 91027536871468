import userReducer from './user';
import companyReducer from './company';
import warehousesReducer from './warehouses';
import bookingAgentReducer from './bookingAgent';

export default {
  user: userReducer,
  bookingAgent: bookingAgentReducer,
  company: companyReducer,
  warehouses: warehousesReducer,
};
