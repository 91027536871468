import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Divider, TextField, Grid, Link, makeStyles } from '@material-ui/core';

import ContactUs from './ContactUs/index';
import { H2 } from './common';
import { getCompanyWithoutContacts } from '../store/selectors/company';

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: '0.9em;',
  },
  gridBoxLeftFields: {
    padding: '12px 40px 12px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  gridBoxRightFields: {
    padding: '12px 40px',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      borderLeft: 'none',
      marginTop: theme.spacing(2.5),
      textAlign: 'center',
    },
  },
  divider: {
    margin: theme.spacing(2.5, 0),
  },
  contactLink: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  gridPadding: {
    padding: '16px 0',
  },
}));

const GeneralCompanyInfo = ({ onChange }) => {
  const classes = useStyles();
  const [isDialogOpen, setDialog] = useState(false);
  const existingCompanyInfo = useSelector(getCompanyWithoutContacts);
  const [company] = useState(existingCompanyInfo);

  return (
    <>
      <H2>General</H2>
      <Divider className={classes.divider} />
      <Grid container className={classes.gridPadding}>
        <Grid item xs={12} md={6} className={classes.gridBoxLeftFields}>
          <TextField
            disabled
            label="Company Name"
            value={company.name}
            onChange={e => onChange('name', e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridBoxRightFields}>
          <Typography className={classes.description}>
            This is the company associated with your account. If this is incorrect or you need to update it,
            please{' '}
            <Link className={classes.contactLink} onClick={() => setDialog(true)}>
              Contact Us
            </Link>
            <ContactUs isDialogOpen={isDialogOpen} onClose={() => setDialog(false)} />.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralCompanyInfo;
