import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Avatar, H3, BodyText } from '../../../common';

const useStyles = makeStyles(theme => ({
  headingText: {
    paddingLeft: theme.spacing(2),
  },
  subInfo: {
    display: 'grid',
  },
}));

const PocCardSummary = ({ user, subInfo, className, avatarSize }) => {
  const classes = useStyles();

  const info = subInfo?.length ? subInfo.join(' • ') : user.title;

  return (
    <>
      <Avatar user={user} size={avatarSize} />
      <div className={`${classes.headingText} ${className}`}>
        <H3 className="title">
          {user.firstName} {user.lastName}
        </H3>
        <div className={classes.subInfo}>
          <BodyText className="subTitle" variant="label">
            {info}
          </BodyText>
        </div>
      </div>
    </>
  );
};

export default PocCardSummary;
