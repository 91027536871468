import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Done } from '@material-ui/icons';

import { BodyText } from '../../common';
import { WAREHOUSE_DOCUMENTS, DOCUMENT_KEYS } from './constants';

const useStyles = makeStyles(theme => ({
  approve: {
    background: theme.palette.success.light,
    height: 27,
    width: 27,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '& svg': {
      fontSize: 25,
    },
  },
  error: {
    padding: '2px 10px',
    height: 24,
    background: '#F44B4B',
    borderRadius: 100,
  },
  text: {
    color: 'white',
    fontWeight: 700,
  },
}));

const DocumentStatusPill = ({ warehouse }) => {
  const classes = useStyles();

  const pendingDocuments = Object.keys(WAREHOUSE_DOCUMENTS).reduce((acc, docKey) => {
    if (docKey === DOCUMENT_KEYS.TARIFF && warehouse.isTariffDoesntApply) {
      // If the Tariff Document isn't required, ignore the status
      return acc;
    }

    const document = warehouse[WAREHOUSE_DOCUMENTS[docKey]?.urlKey];
    const status = Number(warehouse[WAREHOUSE_DOCUMENTS[docKey]?.statusKey] || 0);

    if ((status === 0 && !document) || status === 1) {
      return acc + 1;
    }

    return acc;
  }, 0);

  if (Boolean(pendingDocuments)) {
    return (
      <div className={classes.error}>
        <BodyText variant="label" className={classes.text}>
          {pendingDocuments} pending
        </BodyText>
      </div>
    );
  }

  return (
    <div className={classes.approve}>
      <Done />
    </div>
  );
};

export default DocumentStatusPill;
