import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ResetPasswordForm from '../../components/Auth/ResetPasswordForm';
import EmailVerificationScreen from './EmailVerification';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  formHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
}));

const resetPasswordMode = 'resetPassword';
const verifyEmailMode = 'verifyEmail';
const subheaders = {
  [resetPasswordMode]: 'Reset your Password',
  [verifyEmailMode]: 'Email Verification',
};
const descriptions = {
  [resetPasswordMode]: 'Enter new password',
};

export default () => {
  const classes = useStyles();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');
  const continueUrl = params.get('continueUrl');

  const actionParams = { actionCode, continueUrl };
  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography component="h1" variant="h4" className={classes.formHeading}>
        Walkboard Partner Portal
      </Typography>
      <Typography component="h2" variant="h1">
        {subheaders[mode]}
      </Typography>
      {!!descriptions[mode] && (
        <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
          {descriptions[mode]}
        </Typography>
      )}
      {mode === resetPasswordMode && <ResetPasswordForm {...actionParams} />}
      {mode === verifyEmailMode && <EmailVerificationScreen {...actionParams} />}
    </Container>
  );
};
