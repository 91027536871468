export const STATES_MAP = {
  AK: { value: 'AK', name: 'Alaska' },
  AL: { value: 'AL', name: 'Alabama' },
  AR: { value: 'AR', name: 'Arkansas' },
  AS: { value: 'AS', name: 'American Samoa' },
  AZ: { value: 'AZ', name: 'Arizona' },
  CA: { value: 'CA', name: 'California' },
  CO: { value: 'CO', name: 'Colorado' },
  CT: { value: 'CT', name: 'Connecticut' },
  DC: { value: 'DC', name: 'District Of Columbia' },
  DE: { value: 'DE', name: 'Delaware' },
  FL: { value: 'FL', name: 'Florida' },
  FM: { value: 'FM', name: 'Federated States Of Micronesia' },
  GA: { value: 'GA', name: 'Georgia' },
  GU: { value: 'GU', name: 'Guam' },
  HI: { value: 'HI', name: 'Hawaii' },
  IA: { value: 'IA', name: 'Iowa' },
  ID: { value: 'ID', name: 'Idaho' },
  IL: { value: 'IL', name: 'Illinois' },
  IN: { value: 'IN', name: 'Indiana' },
  KS: { value: 'KS', name: 'Kansas' },
  KY: { value: 'KY', name: 'Kentucky' },
  LA: { value: 'LA', name: 'Louisiana' },
  MA: { value: 'MA', name: 'Massachusetts' },
  MD: { value: 'MD', name: 'Maryland' },
  ME: { value: 'ME', name: 'Maine' },
  MH: { value: 'MH', name: 'Marshall Islands' },
  MI: { value: 'MI', name: 'Michigan' },
  MN: { value: 'MN', name: 'Minnesota' },
  MO: { value: 'MO', name: 'Missouri' },
  MP: { value: 'MP', name: 'Northern Mariana Islands' },
  MS: { value: 'MS', name: 'Mississippi' },
  MT: { value: 'MT', name: 'Montana' },
  NC: { value: 'NC', name: 'North Carolina' },
  ND: { value: 'ND', name: 'North Dakota' },
  NE: { value: 'NE', name: 'Nebraska' },
  NH: { value: 'NH', name: 'New Hampshire' },
  NJ: { value: 'NJ', name: 'New Jersey' },
  NM: { value: 'NM', name: 'New Mexico' },
  NV: { value: 'NV', name: 'Nevada' },
  NY: { value: 'NY', name: 'New York' },
  OH: { value: 'OH', name: 'Ohio' },
  OK: { value: 'OK', name: 'Oklahoma' },
  OR: { value: 'OR', name: 'Oregon' },
  PA: { value: 'PA', name: 'Pennsylvania' },
  PR: { value: 'PR', name: 'Puerto Rico' },
  PW: { value: 'PW', name: 'Palau' },
  RI: { value: 'RI', name: 'Rhode Island' },
  SC: { value: 'SC', name: 'South Carolina' },
  SD: { value: 'SD', name: 'South Dakota' },
  TN: { value: 'TN', name: 'Tennessee' },
  TX: { value: 'TX', name: 'Texas' },
  UT: { value: 'UT', name: 'Utah' },
  VA: { value: 'VA', name: 'Virginia' },
  VI: { value: 'VI', name: 'Virgin Islands' },
  VT: { value: 'VT', name: 'Vermont' },
  WA: { value: 'WA', name: 'Washington' },
  WI: { value: 'WI', name: 'Wisconsin' },
  WV: { value: 'WV', name: 'West Virginia' },
  WY: { value: 'WY', name: 'Wyoming' },
};

export const STATES = Object.values(STATES_MAP);
