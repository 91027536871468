import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Grid, SvgIcon, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { auth } from '../../firebase';
import { isLoggedIn, isVerified, hasCompanyAccess } from '../../store/selectors/user';
import { Logout, WalkboardLogo } from '../../images';
import ContactUs from '../ContactUs';
import { trackGaEvent } from '../../utils/trackGa';
import NavLinks from './NavLinks';

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    // Paper color, acts as solid background
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    maxWidth: theme.spacing(20),
  },
  logoutBtn: {
    float: 'right',
  },
  navLink: {
    margin: theme.spacing(0, 0, 0, 3.4),
    padding: theme.spacing(0, 0.4, 0.5),
    textDecoration: 'none',
    color: theme.palette.common.black,
    fontWeight: 600,
    borderBottom: '2px solid transparent',
    transition: 'border-bottom ease-out 200ms',
    '&:hover': {
      borderBottom: '2px solid #3C96D9',
    },
  },
  muiLink: {
    cursor: 'pointer',
  },
}));

const Nav = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [isDialogOpen, setDialog] = useState(false);
  const isUserLoggedIn = useSelector(isLoggedIn); // this will be `undefined` while loading

  const isUserVerified = useSelector(isVerified);
  const hasAccess = useSelector(hasCompanyAccess);
  // In this case, though we're still showing nav, we're likely on a `MessageScreen` displaying some access error.
  const isVerifiedWithCompanyAccess = isUserVerified && hasAccess;

  // TODO: perform exact path matchup to avoid collision with subroutes that include `approval`
  const isOnVerificationScreen = pathname.includes('verify');

  const isOnCompanyScreen = pathname.includes('company');
  const isOnInvoiceScreen = pathname.includes('invoices');

  const showNavLinks = isVerifiedWithCompanyAccess && (isOnCompanyScreen || isOnInvoiceScreen);

  return (
    <>
      <AppBar
        className={classnames(
          { [classes.root]: isVerifiedWithCompanyAccess && isOnCompanyScreen },
          classes.offset,
          'refNav'
        )}
        color="transparent"
        position="sticky"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <Link to="/">
                <img className={classes.logo} src={WalkboardLogo} alt="Walkboard" />
              </Link>
            </Grid>

            {showNavLinks && <NavLinks />}

            <Grid item>
              <MuiLink
                underline="none"
                className={`${classes.muiLink} ${classes.navLink}`}
                onClick={() => {
                  setDialog(true);
                  trackGaEvent('User', 'Clicked Help Link', 'Help');
                }}
              >
                Help
              </MuiLink>
              <ContactUs isDialogOpen={isDialogOpen} onClose={() => setDialog(false)} />
            </Grid>
            {!!isUserLoggedIn && (
              <Grid item xs>
                <Button
                  variant="text"
                  className={classes.logoutBtn}
                  startIcon={<SvgIcon component={Logout} viewBox="0 0 600 476.6" alt="Logout" />}
                  onClick={() => auth.signOut()}
                >
                  {isOnVerificationScreen ? 'Return to Homepage' : 'Logout'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Nav;
