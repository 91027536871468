import cleanPhoneNumber from '../../../utils/cleanPhoneNumber';

export const POC_FIELDS = {
  firstName: {
    name: 'firstName',
    label: 'First Name',
    required: true,
    validation: { pattern: '^[\\w\\W]{2,32}$', errorMsg: 'Must be between 2 - 32 characters.' },
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    required: true,
    validation: { pattern: '^[\\w\\W]{2,64}$', errorMsg: 'Must be between 2 - 64 characters.' },
  },
  title: {
    name: 'title',
    label: 'Title',
    validation: { pattern: '^$|^[\\w\\W]{2,64}$', errorMsg: 'Must be between 2 - 64 characters.' },
  },
  phoneNumber: {
    name: 'phoneNumber',
    label: 'Phone',
    required: true,
    validation: { pattern: '^$|^[\\d]{10}$', errorMsg: 'Must be 10 digits.' },
    format: value => cleanPhoneNumber(value),
    sanitize: value => value.replace(/\D+/g, ''),
  },
  email: {
    name: 'email',
    label: 'Email',
    required: true,
    validation: {
      pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      errorMsg: 'Must be a valid email.',
    },
  },
};

export const POC_FIELDS_ARRAY = Object.values(POC_FIELDS);

export const checkPOCValidity = (contact, setErrors, company) => {
  let validity = true;

  POC_FIELDS_ARRAY.forEach(field => {
    if (field.required && !contact[field.name]) {
      validity = false;
      setErrors(prevState => ({ ...prevState, [field.name]: 'Field is required.' }));
    }

    if (field.name === 'email') {
      // Doing the isEmailTaken check inside the conditional to prevent doing the check for all fields.
      const isEmailTaken = Boolean(
        [...Object.values(company.contacts || {}), ...Object.values(company.warehouseContacts || {})].find(
          user => user.email?.toUpperCase() === contact.email?.toUpperCase() && user.id !== contact.id
        )
      );
      if (isEmailTaken) {
        validity = false;
        setErrors(prevState => ({ ...prevState, email: 'A contact with this email already exists' }));
      }
    }

    // make sure contact[field.name] is a string value
    // or .match will throw error
    if (!(contact[field.name] || '').match(field.validation.pattern)) {
      validity = false;
      setErrors(prevState => ({ ...prevState, [field.name]: field.validation.errorMsg }));
    }
  });

  return validity;
};
