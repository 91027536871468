import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { H3 } from '../../common';
import StateSelect from '../../StateSelect';
import { STATES } from '../../States';
import cleanPhoneNumber from '../../../utils/cleanPhoneNumber';
import { hasWlBillingAddressPrivilege, hasWlMainAddressPrivilege } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: `${theme.spacing(3)}px !important`, // to overwrite the one by contentContainer
    marginTop: theme.spacing(3),
  },
  fieldRow: {
    display: 'flex',
    '& > *:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  stateSelect: {
    padding: 0,
    '& .label': {
      display: 'none',
    },
    '& .MuiSelect-root': {
      padding: theme.spacing(2, 1.5),
    },
  },
}));

const getStateName = shortName => STATES.find(state => state.value === shortName?.toUpperCase())?.name || '';

const WarehouseLocationForm = ({ warehouse, setWarehouse, errors, setErrors }) => {
  const classes = useStyles();
  const [isBillingSame, setIsBillingSame] = useState(false);
  const hasWlBillingAddressRights = useSelector(hasWlBillingAddressPrivilege(warehouse.id));
  const hasWlMainAddressRights = useSelector(hasWlMainAddressPrivilege(warehouse.id));

  const onMainFieldChange = (field, value, billingField) => {
    const updates = { [field]: value };
    const errors = { [field]: '' };
    if (isBillingSame) {
      updates[billingField] = value;
      errors[billingField] = '';
    }
    setWarehouse({ ...warehouse, ...updates });
    setErrors(prevState => ({ ...prevState, ...errors }));
  };

  return (
    <>
      {hasWlMainAddressRights && (
        <>
          <H3 className={classes.title}>Main Address</H3>
          <TextField
            variant="outlined"
            name="mainAddress"
            placeholder="Address"
            value={warehouse.mainAddress || ''}
            error={Boolean(errors.mainAddress)}
            helperText={errors.mainAddress || ''}
            onChange={e => {
              onMainFieldChange('mainAddress', e.target.value, 'billingAddress');
            }}
          />
          <TextField
            variant="outlined"
            name="mainCity"
            error={Boolean(errors.mainCity)}
            helperText={errors.mainCity || ''}
            placeholder="City"
            value={warehouse.mainCity || ''}
            onChange={e => {
              onMainFieldChange('mainCity', e.target.value, 'billingCity');
            }}
          />
          <div className={classes.fieldRow}>
            <StateSelect
              className={classes.stateSelect}
              variant="outlined"
              name="mainState"
              displayEmpty
              placeholder="State"
              error={Boolean(errors.mainState)}
              helperText={errors.mainState || ''}
              value={getStateName(warehouse.mainState)}
              onChange={e => {
                onMainFieldChange('mainState', e.target.value, 'billingState');
              }}
            />
            <TextField
              variant="outlined"
              name="mainZipCode"
              placeholder="Zip Code"
              error={Boolean(errors.mainZipCode)}
              helperText={errors.mainZipCode || ''}
              value={warehouse.mainZipCode || ''}
              onChange={e => {
                const value = e.target.value.replace(/\D+/g, '');
                onMainFieldChange('mainZipCode', value, 'billingZipCode');
              }}
            />
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={isBillingSame}
                onChange={e => {
                  const checked = e.target.checked;
                  setIsBillingSame(checked);
                  if (checked) {
                    const { mainAddress, mainCity, mainState, mainZipCode } = warehouse;
                    setWarehouse({
                      ...warehouse,
                      billingAddress: mainAddress,
                      billingCity: mainCity,
                      billingState: mainState,
                      billingZipCode: mainZipCode,
                    });
                    setErrors({ ...errors, billingAddress: '', billingCity: '', billingState: '', billingZipCode: '' });
                  }
                }}
              />
            }
            label={
              <div className={classes.checkboxLabel}>
                <span>Same Billing Address as Main Address</span>
              </div>
            }
          />
        </>
      )}

      {hasWlBillingAddressRights && (
        <>
          <H3 className={classes.title}>Billing Address</H3>

          <TextField
            variant="outlined"
            name="billingAddress"
            placeholder="Address"
            value={warehouse.billingAddress || ''}
            error={Boolean(errors.billingAddress)}
            helperText={errors.billingAddress || ''}
            disabled={isBillingSame}
            onChange={e => {
              setWarehouse({ ...warehouse, billingAddress: e.target.value });
              setErrors(prevState => ({ ...prevState, billingAddress: '' }));
            }}
          />
          <TextField
            variant="outlined"
            name="billingCity"
            placeholder="City"
            value={warehouse.billingCity || ''}
            error={Boolean(errors.billingCity)}
            helperText={errors.billingCity || ''}
            disabled={isBillingSame}
            onChange={e => {
              setWarehouse({ ...warehouse, billingCity: e.target.value });
              setErrors(prevState => ({ ...prevState, billingCity: '' }));
            }}
          />
          <div className={classes.fieldRow}>
            <StateSelect
              className={classes.stateSelect}
              variant="outlined"
              name="billingState"
              displayEmpty
              placeholder="State"
              value={getStateName(warehouse.billingState)}
              error={Boolean(errors.billingState)}
              helperText={errors.billingState || ''}
              disabled={isBillingSame}
              onChange={e => {
                setWarehouse({ ...warehouse, billingState: e.target.value });
                setErrors(prevState => ({ ...prevState, billingState: '' }));
              }}
            />

            <TextField
              variant="outlined"
              name="billingZipCode"
              placeholder="Zip Code"
              value={warehouse.billingZipCode || ''}
              error={Boolean(errors.billingZipCode)}
              helperText={errors.billingZipCode || ''}
              disabled={isBillingSame}
              onChange={e => {
                setWarehouse({ ...warehouse, billingZipCode: e.target.value.replace(/\D+/g, '') });
                setErrors(prevState => ({ ...prevState, billingZipCode: '' }));
              }}
            />
          </div>
          <div className={classes.fieldRow}>
            <TextField
              variant="outlined"
              name="billingPhone"
              placeholder="Phone"
              value={cleanPhoneNumber(warehouse.billingPhone) || ''}
              error={Boolean(errors.billingPhone)}
              helperText={errors.billingPhone || ''}
              onChange={e => {
                setWarehouse({ ...warehouse, billingPhone: e.target.value.replace(/\D+/g, '') });
                setErrors(prevState => ({ ...prevState, billingPhone: '' }));
              }}
            />

            <TextField
              variant="outlined"
              name="billingEmail"
              placeholder="Email"
              value={warehouse.billingEmail || ''}
              error={Boolean(errors.billingEmail)}
              helperText={errors.billingEmail || ''}
              onChange={e => {
                setWarehouse({ ...warehouse, billingEmail: e.target.value });
                setErrors(prevState => ({ ...prevState, billingEmail: '' }));
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
export default WarehouseLocationForm;
