import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export default value => {
  if (!value || value.length < 10) {
    return false;
  }

  const number = phoneUtil.parseAndKeepRawInput(value, 'US');
  const isValidNumber = phoneUtil.isValidNumber(number);
  const regionCode = phoneUtil.getRegionCodeForNumber(number, 'US');

  if (!isValidNumber || !regionCode) {
    return false;
  }

  return true;
};
