import React from 'react';
import { Typography, Container } from '@material-ui/core';

export default () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h1">Account pending approval.</Typography>
    </Container>
  );
};
