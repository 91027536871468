import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles, Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import {
  hasInvoicingAccess,
  hasAdminRights,
  hasDocumentScreenAccess,
  hasServiceScreenAccess,
} from '../../store/selectors/user';
import { isServiceProviderActive, isServicesRegisteredCompany } from '../../store/selectors/company';
import { useFeatureFlagsByKey } from '../../utils/featureFlags';

const useStyles = makeStyles(theme => ({
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
  disableLink: {
    color: `${theme.palette.grey[400]} !important`,
    borderBottom: 'none !important',
    cursor: 'auto',
    pointerEvents: 'none',
    '&:hover': {
      borderBottom: 'none !important',
    },
  },
  navLink: {
    margin: theme.spacing(0, 0, 0, 3.4),
    padding: theme.spacing(0, 0.4, 0.5),
    textDecoration: 'none',
    color: theme.palette.common.black,
    fontWeight: 600,
    borderBottom: '2px solid transparent',
    transition: 'border-bottom ease-out 200ms',
    '&:hover': {
      borderBottom: '2px solid #3C96D9',
    },
  },
  muiLink: {
    cursor: 'pointer',
  },
}));

const navLinks = [
  {
    label: 'Services',
    link: '/company/services',
    flag: 'isServicesEnabled',
    accessToCheck: ['isViewerAdmin', 'userHasAccessForServiceScreen'],
  },
];

const NavLinks = () => {
  const classes = useStyles();
  const isOriginAndDestinationServicesEnabled = useFeatureFlagsByKey('originAndDestinationServices');
  const isServicesEnabled = useFeatureFlagsByKey('serviceScreen');
  const isCompanyEnabled = useFeatureFlagsByKey('companyScreen') && isOriginAndDestinationServicesEnabled;
  const isInvoicingEnabled = useFeatureFlagsByKey('invoicingScreen') && isOriginAndDestinationServicesEnabled;
  const isDocumentsEnabled = useFeatureFlagsByKey('documentScreen') && isOriginAndDestinationServicesEnabled;

  const enabledFlags = { isCompanyEnabled, isServicesEnabled, isDocumentsEnabled, isInvoicingEnabled };
  const isCompanyServiceRegistered = useSelector(isServicesRegisteredCompany);
  const companyHasServiceProvider = useSelector(isServiceProviderActive);
  const hasInvoicing = useSelector(hasInvoicingAccess);
  const isViewerAdmin = useSelector(hasAdminRights);
  const userHasAccessForDocumentScreen = useSelector(hasDocumentScreenAccess);
  const userHasAccessForServiceScreen = useSelector(hasServiceScreenAccess);

  const navAccessProps = {
    isCompanyServiceRegistered,
    hasInvoicing,
    isViewerAdmin,
    userHasAccessForDocumentScreen,
    userHasAccessForServiceScreen,
    companyHasServiceProvider,
  };
  return navLinks.map(({ label, link, activeChecks, flag, accessToCheck }, idx) => {
    // Check if feature flag is enable
    if (flag && !enabledFlags[flag]) {
      return null;
    }

    // check if user has access for the link
    if (accessToCheck && !accessToCheck?.some?.(accessName => navAccessProps[accessName])) {
      return null;
    }

    return (
      <Grid key={idx} item>
        <NavLink
          className={classnames(classes.navLink, {
            [classes.disableLink]: activeChecks?.some?.(propName => !navAccessProps[propName]),
          })}
          activeClassName={classes.active}
          to={link}
        >
          {label}
        </NavLink>
      </Grid>
    );
  });
};

export default NavLinks;
