import React from 'react';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  loginFieldsContainer: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
    padding: theme.spacing(0, 6),
  },
  formHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  separator: {
    border: 'none',
    borderTop: '1px dashed #707070',
    opacity: '0.15',
    margin: theme.spacing(6, 0),
  },
  link: {
    color: theme.palette.link,
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h1" className={classes.formHeading}>
        Done!
      </Typography>
      <Typography variant="h4"> Password reset requested. Check your email for instructions.</Typography>
    </Container>
  );
};
