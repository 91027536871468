import React from 'react';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';

import DocumentActions from './DocumentActions';
import {
  DocumentApproved as DocumentApprovedIcon,
  DocumentReview as DocumentReviewIcon,
  DocumentRejected as DocumentRejectedIcon,
  DocumentUpload as DocumentUploadIcon,
} from '../../../images';
import { getStatusColor } from './helpers/utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'initial',
    borderRadius: 12,
    padding: theme.spacing(1),
    background: ({ statusColor }) => (!statusColor ? theme.palette.grey[100] : theme.palette.common.white),
    border: ({ statusColor }) => !statusColor && `2px solid ${theme.palette.grey[200]}`,
    borderLeft: ({ statusColor }) => statusColor && `7px solid ${statusColor}`,
    boxShadow: ({ statusColor }) => statusColor && theme.shadows[1],
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  actionsDocs: {
    display: 'flex',
    padding: theme.spacing(2, 1, 1),
  },
  documentTitle: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(2),
    color: ({ statusColor }) => (statusColor ? theme.palette.common.black : theme.palette.grey[500]),
  },
}));

const useStylesDocumentIconStatus = makeStyles(theme => ({
  root: {
    fill: ({ statusColor }) => statusColor || theme.palette.grey[400],
  },
}));

const DocumentIconStatus = ({ docStatus, existingFile, statusColor }) => {
  const classes = useStylesDocumentIconStatus({ statusColor });

  switch (docStatus) {
    case 1: {
      // Rejected
      return <DocumentRejectedIcon className={classes.root} />;
    }
    case 2: {
      // Approved
      return <DocumentApprovedIcon className={classes.root} />;
    }
    default: {
      // If document exists, assume that it's in review, otherwise no docoument yet
      return existingFile ? (
        <DocumentReviewIcon className={classes.root} />
      ) : (
        <DocumentUploadIcon className={classes.root} />
      );
    }
  }
};

const DocumentCard = ({ className, name, docStatus, existingFile, ...props }) => {
  const theme = useTheme();
  const statusColor = getStatusColor(docStatus, existingFile, theme);
  const classes = useStyles({ statusColor });

  return (
    <Grid className={`${classes.root} ${className}`} container spacing={0} direction="column" wrap="nowrap">
      <Grid className={classes.heading} item xs={12}>
        <DocumentIconStatus statusColor={statusColor} docStatus={docStatus} existingFile={existingFile} />
        <Typography className={classes.documentTitle} variant="h6">
          {name}
        </Typography>
      </Grid>
      <Grid className={classes.actionsDocs} item xs={12}>
        <DocumentActions {...props} existingFile={existingFile} docStatus={docStatus} />
      </Grid>
    </Grid>
  );
};

export default DocumentCard;
