import { Dialog, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    width: 750,
    maxWidth: 1200,
    padding: theme.spacing(1.7, 3.5, 2.5),
    borderRadius: 12,
    overflow: 'hidden',
  },
}));

export const Modal = ({ onClose, open, children, ...props }) => {
  const classes = useStyles();

  return (
    open && (
      <Dialog classes={{ paper: classes.paper }} open onClose={onClose}>
        {children}
      </Dialog>
    )
  );
};
