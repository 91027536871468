import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '../../common';
import WarehouseLocationForm from './WarehouseLocationForm';
import { db } from '../../../firebase';
import { useAsyncCallback } from '../../../hooks';
import { checkValidity } from './WarehouseFields';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    overflowY: 'auto',
    height: '100%',
    padding: theme.spacing(0, 5),
    '& input': {
      padding: theme.spacing(2, 1.5),
    },
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
  },
  buttonContainer: {
    flexShrink: 0,
    height: 80,
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    background: '#F5F5F5',
    bottom: 0,
    '& > .MuiButton-root': {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '26px',
    },
  },
  cancelButton: {
    justifyContent: 'initial',
    marginRight: theme.spacing(2),
    '& svg': {
      marginRight: theme.spacing(8),
    },
  },
}));

const getWarehouseTitle = (isEdit, warehouse) => {
  if (isEdit) {
    return `Edit Warehouse ${warehouse.mainCity}, ${warehouse.mainState}`;
  }
  return 'Add Warehouse';
};

const AddEditWarehouseDialog = ({ companyId, onClose, selectedWarehouse }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [isBillingSame, setIsBillingSame] = useState(false);

  const [warehouse, setWarehouse] = useState(
    selectedWarehouse || {
      mainAddress: '',
      mainCity: '',
      mainState: '',
      mainZipCode: '',
      billingAddress: '',
      billingCity: '',
      billingState: '',
      billingZipCode: '',
      companyId,
    }
  );

  const [{ isLoading: isSaving }, saveWarehouse] = useAsyncCallback(async () => {
    const dbLocation = db.collection('agent_companies').doc(String(companyId)).collection('warehouses');
    if (!warehouse.id) {
      const docRef = dbLocation.doc();
      await docRef.set({ id: docRef.id, ...warehouse, updateBackend: true });
    } else {
      await dbLocation.doc(warehouse.id).set({ ...warehouse, updateBackend: true }, { merge: true });
    }
  });

  const isEdit = Boolean(selectedWarehouse);

  return (
    <Drawer anchor="right" open onClose={onClose} title={getWarehouseTitle(isEdit, warehouse)}>
      <div className={classes.contentContainer}>
        <WarehouseLocationForm
          warehouse={warehouse}
          setWarehouse={setWarehouse}
          errors={errors}
          setErrors={setErrors}
          isBillingSame={isBillingSame}
          setIsBillingSame={setIsBillingSame}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" fullWidth onClick={onClose} className={classes.cancelButton}>
          <ArrowBack />
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaving}
          fullWidth
          onClick={async () => {
            if (checkValidity(warehouse, setErrors, isBillingSame)) {
              await saveWarehouse();
              onClose();
            }
          }}
        >
          {isSaving ? <CircularProgress size={20} /> : `${isEdit ? 'Update' : 'Add New'} Warehouse`}
        </Button>
      </div>
    </Drawer>
  );
};
export default AddEditWarehouseDialog;
