import React from 'react';

import { ServiceSection } from '../index';

const LongDistance = ({ indexInColumn }) => {
  return (
    <ServiceSection
      indexInColumn={indexInColumn}
      description={
        <>
          Provide <b>full service long distance</b> as a preferred interstate service provider for corporate accounts
          and COO moves.
        </>
      }
    />
  );
};

export default LongDistance;
