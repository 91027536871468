import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import InstantQuoteCard from './InstantQuoteCards';

const useStyles = makeStyles(theme => ({
  root: {
    width: '1024px',
    borderColor: theme.palette.common.primary,
    marginBottom: theme.spacing(1),
    margin: '0 20px',
  },
  descriptionContainer: {
    width: 1024,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: theme.spacing(2),
  },
}));

const onChangeAutoTrasportField = ({ isChecked, setValue, getValues, segment: { auto_transport_quotes } }) => {
  const autoTransportFormValues = getValues('auto_transportation');
  const currentAutoTransportIds = auto_transport_quotes.map(auto => auto.id);
  if (isChecked) {
    setValue('auto_transportation', [...currentAutoTransportIds, ...autoTransportFormValues]);
  } else {
    const newAutoTransportationPayload = autoTransportFormValues.filter(
      autoId => !currentAutoTransportIds.includes(autoId)
    );
    setValue('auto_transportation', newAutoTransportationPayload);
  }
};

const onChangeOriginThirdPartyService = ({
  isChecked,
  setValue,
  getValues,
  segment: { third_party_services_quotes },
}) => {
  const originThirdPartyValues = getValues('third_party_services');

  const currentOriginServiceIds = third_party_services_quotes
    .filter(service => service.location_type === 0 && service.id)
    .map(service => service.id);
  if (isChecked) {
    setValue('third_party_services', [...currentOriginServiceIds, ...originThirdPartyValues]);
  } else {
    const newThirdPartyPayload = originThirdPartyValues.filter(service => !currentOriginServiceIds.includes(service));
    setValue('third_party_services', newThirdPartyPayload);
  }
};

const onChangeDestinationThirdPartyService = ({
  isChecked,
  setValue,
  getValues,
  segment: { third_party_services_quotes },
}) => {
  const destinationThirdPartyValues = getValues('third_party_services');
  const currentDestinationServiceIds = third_party_services_quotes
    .filter(service => service.location_type === 1)
    .map(service => service.id);
  if (isChecked) {
    setValue('third_party_services', [...currentDestinationServiceIds, ...destinationThirdPartyValues]);
  } else {
    const newThirdPartyPayload = destinationThirdPartyValues.filter(
      service => !currentDestinationServiceIds.includes(service)
    );
    setValue('third_party_services', newThirdPartyPayload);
  }
};

const onChangeSegment = ({ isChecked, setValue, getValues, segment: { id } }, reserveType) => {
  const segmentValues = getValues('segments');
  const newSegmentsPayload = segmentValues.filter(segment => segment.id !== id);
  if (isChecked) {
    setValue('segments', [{ id, reserved: reserveType === 'Full' ? 1 : 2 }, ...newSegmentsPayload]);
  } else {
    setValue('segments', newSegmentsPayload);
  }
};

const textHandler = (cardType, segment) => {
  switch (cardType) {
    case 'Full':
      return `Hauling ${segment.estimated_weight} lbs. of household goods ${segment.mileage} miles from ${segment.origin_city}, ${segment.origin_state} to ${segment.destination_city}, ${segment.destination_state}, packing starting on ${segment.requested_pack_load_date} (est. transit time 6-8 days)`;

    case 'Flex':
      return `Hauling ${segment.estimated_weight} lbs. of household goods
        ${segment.mileage} miles from ${segment.origin_city}, ${segment.origin_state} to ${segment.destination_city}, ${segment.destination_state}, 
        loading (NO PACKING) starting on ${segment.requested_pack_load_date} (est. transit time 6-8 days)`;

    case 'Origin':
      return segment.third_party_services_quotes
        .filter(service => service.location_type === 0)
        .map((service, idx) => (
          <div key={idx}>
            {`${service.pricing.third_party_service.name}: ${
              service.pricing.rate_type === 1 ? 'Hourly' : 'Fixed'
            } rate of $${service.pricing.origin} ${
              service.pricing.rate_type === 1
                ? `min ${service.pricing.origin_minimum_hours} hours, estimated ${service.pricing.estimated_hours} hours.`
                : ''
            }`}{' '}
            <br />
          </div>
        ));

    case 'Destination':
      return segment.third_party_services_quotes
        .filter(service => service.location_type === 1)
        .map((service, idx) => (
          <div key={idx}>
            {`${service.pricing.third_party_service.name}: ${
              service.pricing.rate_type === 1 ? 'Hourly' : 'Fixed'
            } rate of $${service.pricing.destination} ${
              service.pricing.rate_type === 1
                ? `min ${service.pricing.destination_minimum_hours} hours, estimated ${service.pricing.estimated_hours} hours.`
                : ''
            }`}{' '}
            <br />
          </div>
        ));

    case 'autoTransport':
      return segment.auto_transport_quotes.map((auto, idx) => (
        <div key={idx}>
          {`Hauling ${auto.year} ${auto.make} ${auto.model}, ${segment.mileage} miles from ${segment.origin_city}, ${segment.origin_state} to ${segment.destination_city}, ${segment.destination_state}`}{' '}
          <br />
        </div>
      ));
    default:
      break;
  }
};

const InstantQuote = ({ segment, handleError }) => {
  const classes = useStyles();
  const { setValue, getValues, watch } = useFormContext();
  const segmentFormValues = watch('segments');

  return (
    <div className={classes.root}>
      <InstantQuoteCard
        title="Full service transportation"
        text={textHandler('Full', segment)}
        price={segment.quote}
        value={segmentFormValues.some(segmentValue => segmentValue.id === segment.id && segmentValue.reserved === 1)}
        onChange={isChecked => {
          onChangeSegment({ isChecked, setValue, getValues, segment }, 'Full');
          handleError(null);
        }}
        name="segments"
      />

      <InstantQuoteCard
        title="Flex Transportation (PBO/No Packing)"
        text={textHandler('Flex', segment)}
        price={segment.flex_quote}
        value={segmentFormValues.some(segmentValue => segmentValue.id === segment.id && segmentValue.reserved === 2)}
        onChange={isChecked => {
          onChangeSegment({ isChecked, setValue, getValues, segment }, 'Flex');
          handleError(null);
        }}
        name="segments"
      />

      {segment.origin_third_party_services?.length > 0 && (
        <InstantQuoteCard
          title="Origin Third Party Services"
          text={textHandler('Origin', segment)}
          price={segment.origin_third_party_services_quote}
          disabler={
            !segmentFormValues.find(segmentValue => segmentValue.id === segment.id) || segmentFormValues.length === 0
          }
          onChange={isChecked => onChangeOriginThirdPartyService({ isChecked, setValue, getValues, segment })}
        />
      )}

      {segment.destination_third_party_services?.length > 0 && (
        <InstantQuoteCard
          title="Destination Third Party Services"
          text={textHandler('Destination', segment)}
          price={segment.destination_third_party_services_quote}
          disabler={
            !segmentFormValues.find(segmentValue => segmentValue.id === segment.id) || segmentFormValues.length === 0
          }
          onChange={isChecked => onChangeDestinationThirdPartyService({ isChecked, setValue, getValues, segment })}
        />
      )}

      {segment.auto_transport_quotes.length > 0 && (
        <InstantQuoteCard
          title="Auto Transportation"
          name="auto_transportation"
          text={textHandler('autoTransport', segment)}
          price={segment.auto_transport_total}
          disabler={
            !segmentFormValues.find(segmentValue => segmentValue.id === segment.id) || segmentFormValues.length === 0
          }
          onChange={isChecked => onChangeAutoTrasportField({ isChecked, setValue, getValues, segment })}
        />
      )}
    </div>
  );
};

export default InstantQuote;
