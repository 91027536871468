import React from 'react';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import { ROLE_WL_KEYS } from '../constants';

export const getDrawerTitle = (isEdit, isWlPocForm, warehouse) => {
  if (isWlPocForm) {
    return `${warehouse.mainCity}, ${warehouse.mainState} • Point of Contact`;
  }
  return `${isEdit ? 'Edit' : 'New'} Contact`;
};

export const getCancelButtonText = (isEdit, hideContactForm) => {
  if (hideContactForm) {
    return (
      <>
        <ArrowBackIcon />
        Back
      </>
    );
  }
  return isEdit ? 'Cancel Changes' : 'Cancel';
};

export const getRoles = (company, user, isWlPocForm) => {
  return isWlPocForm
    ? {
        admin: user?.adminContact,
        primary: user?.primaryContact,
        escalation: user?.escalationContact,
        operations: user?.operationsContact,
        invoicing: user?.invoicingContact,
      }
    : {
        admin: company.admins.includes(user.id),
        primary: company.primaryContact === user.id,
        secondary: company.secondaryContact === user.id,
        invoicing: company.invoicingContact === user.id,
      };
};

export const getWlUniqueRoles = contacts =>
  Object.values(contacts || {}).reduce(
    (acc, contact) => {
      for (const roleKey of ROLE_WL_KEYS) {
        const roleName = `${roleKey}Contact`;
        if (contact[roleName] && roleName in acc) {
          acc[roleName] = contact.id;
        }
      }

      return acc;
    },
    {
      primaryContact: '',
      invoicingContact: '',
    }
  );

export const getSeparatedUserContacts = contacts =>
  Object.values(contacts || {}).reduce(
    (collection, contact) => {
      if (!contact.id.includes('static')) {
        return [[...collection[0], contact], collection[1]];
      }
      return [collection[0], [...collection[1], contact]];
    },
    [[], []]
  );

export const getWarehouseCardNames = (isShowAllWlRelated, user, warehouses) =>
  isShowAllWlRelated &&
  (user.warehouseLocationIds || []).reduce((acc, warehouseId) => {
    const warehouse = warehouses.find?.(warehouse => warehouse.id === warehouseId);

    if (warehouse) {
      acc = [...acc, `${warehouse.mainCity}, ${warehouse.mainState}`];
    }

    return acc;
  }, []);
