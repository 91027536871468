import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';
import { isVerified, hasCompanyAccess } from '../../store/selectors/user';
import {
  isServicesRegisteredCompany,
  isServiceProviderActive,
  isServiceProviderStatus,
} from '../../store/selectors/company';

import StatusBar from './StatusBar';

const useStyles = makeStyles(theme => ({
  offset: {
    ...theme.mixins.toolbar,
    top: 0,
    position: 'sticky',
    '& ~ header.refNav': {
      ...theme.mixins.toolbar,
      top: '82px',
      height: '64px',
      position: 'sticky',
    },
    '& ~ header.refSubNav': {
      ...theme.mixins.toolbar,
      top: '146px',
      minHeight: '48px !important',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();

  const isUserVerified = useSelector(isVerified);
  const hasAccess = useSelector(hasCompanyAccess);
  const companyIsApproved = useSelector(isServiceProviderActive);
  const serviceStatus = useSelector(isServiceProviderStatus);
  const isCompanyServiceRegistered = useSelector(isServicesRegisteredCompany);

  const isVerifiedWithCompanyAccess =
    isUserVerified && hasAccess && isCompanyServiceRegistered && !companyIsApproved && serviceStatus !== 3;
  const {
    location: { pathname },
  } = history;

  const isInfoPath = pathname === '/company/info';
  const isDocuments = pathname === '/company/documents';

  if (!isVerifiedWithCompanyAccess || (!isInfoPath && !isDocuments)) {
    return null;
  }

  return (
    <AppBar color="transparent" position="static" elevation={0} className={classes.offset}>
      <StatusBar serviceStatus={serviceStatus} />
    </AppBar>
  );
};
