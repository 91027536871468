import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '../../common';
import PointOfContactForm from './PointOfContactForm';
import WarehouseContactAddingCard from '../WarehouseLocation/WarehouseContactAddingCard';
import RoleCheckboxesContainer from './Roles/RoleCheckboxesContainer';
import { getCompany } from '../../../store/selectors/company';
import { setContact } from './actions';
import { useAsyncCallback } from '../../../hooks/useAsync';
import { checkPOCValidity } from './POCSectionFields';
import { getCancelButtonText, getDrawerTitle, getRoles, getWlUniqueRoles } from './helpers/utils';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    flexShrink: 0,
    padding: theme.spacing(0.75, 0),
    background: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '& > div': {
      padding: theme.spacing(1.5, 1.75),
    },
    '& > .MuiButton-root': {
      margin: 'auto',
      marginTop: theme.spacing(2),
    },
  },
  actionButton: {
    fontSize: '1.1rem',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 2),
    '&.back': {
      border: `1px solid ${theme.palette.common.black}`,
      position: 'relative',
      '& svg': {
        position: 'absolute',
        left: 28,
      },
    },
  },
}));

/**
 * By Default this component will act as an Company POC Form,
 * otherwise, if provided with isWlPocForm prop will act as Warehouse POC Form.
 */
export default ({
  onClose,
  selectedUser,
  warehouse = {},
  isWlPocForm = false,
  isNoRoles,
  warehouses,
  setHideContactForm,
  hideContactForm,
  setAddEditDialogContact,
}) => {
  const classes = useStyles();
  const company = useSelector(getCompany);

  const [user, setUser] = useState(
    selectedUser || {
      firstName: '',
      lastName: '',
      title: '',
      phoneNumber: '',
      email: '',
    }
  );

  const isEdit = Boolean(selectedUser);

  // keys should match the ROLE_NAMES constant's keys
  const [roles, setRoles] = useState(isEdit ? getRoles(company, user, isWlPocForm) : {});

  const [errors, setErrors] = useState({});

  const [{ result, isLoading, error }, doSetContact] = useAsyncCallback(setContact);

  // Process here for to not iterate on every checkbox component, roles of warehouse are on per contact
  const warehouseRoles = getWlUniqueRoles(warehouse?.contacts);

  useEffect(() => {
    // close the dialog if changes are saved successfully
    if (Boolean(result.success)) {
      onClose();
    }
  }, [result, onClose]);

  return (
    <Drawer anchor="right" open onClose={onClose} title={getDrawerTitle(isEdit, isWlPocForm, warehouse)}>
      {hideContactForm ? (
        <WarehouseContactAddingCard user={user} warehouses={warehouses} setHideContactForm={setHideContactForm} />
      ) : (
        <PointOfContactForm user={user} setUser={setUser} errors={errors} setErrors={setErrors} />
      )}

      <Grid container className={classes.buttonContainer}>
        {!isNoRoles && (
          <RoleCheckboxesContainer
            user={user}
            roles={roles}
            setRoles={setRoles}
            isWlPocForm={isWlPocForm}
            warehouseRoles={warehouseRoles}
          />
        )}
        {Boolean(error) && (
          <Grid item sm={12}>
            <Typography variant="subtitle2" color="error" align="center">
              {error.message}
            </Typography>
          </Grid>
        )}
        <Grid item sm={6}>
          <Button
            className={`${classes.actionButton} back`}
            variant="outlined"
            fullWidth
            onClick={() => {
              if (hideContactForm) {
                // Returns to the contact selecting view when adding contacts to a warehouse.
                setAddEditDialogContact(true);
              }
              onClose();
            }}
          >
            {getCancelButtonText(isEdit, hideContactForm)}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            className={classes.actionButton}
            variant="contained"
            fullWidth
            color="primary"
            disabled={isLoading}
            onClick={() => {
              checkPOCValidity(user, setErrors, company) &&
                doSetContact({ user, company, roles, isWlPocForm, warehouse, isNoRoles, warehouseRoles });
            }}
          >
            {isLoading && <CircularProgress size={20} />}
            {isEdit ? 'Save Changes' : 'Add Contact'}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};
