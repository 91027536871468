import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';

import { useFeatureFlagsByKey } from '../utils/featureFlags';
import CompanyInformationForm from '../components/CompanyInfo/CompanyInformationForm';
import DocumentsScreen from '../components/CompanyInfo/Documents';
import ServicesScreen from './Services';
import BookingAgentScreen from './BookingAgent/BookingAgentScreen';
import InvoiceScreen from './Invoice';
import SubNav from '../components/Nav/SubNav';
import PrivilegeRoute from '../components/Routes/PrivilegeRoute';
import {
  hasInvoicingAccess,
  hasAdminRights,
  hasDocumentScreenAccess,
  hasServiceScreenAccess,
} from '../store/selectors/user';
import {
  isServiceProviderActive,
  isServiceProviderStatus,
  isServicesRegisteredCompany,
  isNationalAccountApprovedProvider,
} from '../store/selectors/company';

const useStyles = makeStyles(theme => ({
  container: { maxWidth: props => props.maxWidth, width: '96vw' },
}));

const getRedirectRoute = (
  isInvoicingEnabled,
  canAccessInvoicing,
  isServiceProvider,
  isCompanyServiceRegistered,
  servicesNavBarApproved,
  path
) => {
  if (servicesNavBarApproved) {
    return `${path}/booking-agent/get-pricing`;
  }
  if (isCompanyServiceRegistered) {
    return `${path}/info`;
  }

  return isInvoicingEnabled && canAccessInvoicing && isServiceProvider ? `${path}/invoices` : `${path}/services`;
};

const Company = ({ match: { path }, location: { pathname } }) => {
  const maxWidth =
    pathname === '/company/booking-agent/get-pricing'
      ? 1640
      : pathname.includes('/company/booking-agent/get-pricing/instant-quote')
      ? '96vw'
      : 1024;
  const classes = useStyles({ maxWidth });
  const isCompanyEnabled = useFeatureFlagsByKey('companyScreen');
  const isInvoicingEnabled = useFeatureFlagsByKey('invoicingScreen');
  const isServicesEnabled = useFeatureFlagsByKey('serviceScreen');
  const isDocumentsEnabled = useFeatureFlagsByKey('documentScreen');
  const isOriginAndDestinationServicesEnabled = useFeatureFlagsByKey('originAndDestinationServices');
  const canAccessInvoicing = useSelector(hasInvoicingAccess);
  const isServiceProvider = useSelector(isServiceProviderActive);
  const serviceProviderStatus = useSelector(isServiceProviderStatus);
  const isViewerAdmin = useSelector(hasAdminRights);
  const viewerHasAccessDocumentScreen = useSelector(hasDocumentScreenAccess);
  const viewerHasAccessServiceScreen = useSelector(hasServiceScreenAccess);
  const isCompanyServiceRegistered = useSelector(isServicesRegisteredCompany);
  const servicesNavBarApproved = useSelector(isNationalAccountApprovedProvider);

  const privileges = {
    isInvoicingEnabled,
    isViewerAdmin,
    canAccessInvoicing,
    viewerHasAccessDocumentScreen,
    viewerHasAccessServiceScreen,
    isOriginAndDestinationServicesEnabled,
    isCompanyServiceRegistered,
    servicesNavBarApproved,
  };

  const companySubNavLinks = [
    // O/D Links
    {
      link: '/company/info',
      relatedLinksService: ['/company/info', '/company/documents', '/company/invoices'],
      label: 'Company Information',
    },
    {
      link: '/company/documents',
      relatedLinksService: ['/company/info', '/company/documents', '/company/invoices'],
      label: 'Documents',
      // Disabled link for stepper
      disabled: ![3, 4].includes(serviceProviderStatus),
      accessToCheck: ['isViewerAdmin'],
    },
    {
      link: '/company/invoices',
      relatedLinksService: ['/company/info', '/company/documents', '/company/invoices'],
      label: 'Invoices',
      disabled: !(isInvoicingEnabled && isServiceProvider),
      accessToCheck: ['isInvoicingEnabled', 'canAccessInvoicing'],
    },
    // Booking Links
    {
      relatedLinksService: ['booking-agent', 'reservations'],
      link: '/company/booking-agent',
      suffix: '/get-pricing',
      label: 'Get Pricing',
    },
    {
      relatedLinksService: ['booking-agent', 'reservations'],
      link: '/company/booking-agent/reservations',
      label: 'Reservations',
    },
  ];

  const links = companySubNavLinks.filter(item => {
    if (item.accessToCheck && !item.accessToCheck.some(accessName => privileges[accessName])) {
      return false;
    }

    return item.relatedLinksService
      ? item.relatedLinksService.some(link => pathname.includes(link))
      : pathname.includes(item.link);
  });

  return (
    <>
      <SubNav pathname={pathname} links={links} />
      <Container className={classes.container} disableGutters>
        <Switch>
          <Route path={`${path}/services`} component={ServicesScreen} isEnabled={isServicesEnabled} />
          <PrivilegeRoute
            path={`${path}/info`}
            isEnabled={isCompanyEnabled && isOriginAndDestinationServicesEnabled && serviceProviderStatus !== 2}
            component={CompanyInformationForm}
            privilegesToCheck={['isCompanyServiceRegistered']}
            userPrivileges={privileges}
          />
          <PrivilegeRoute
            path={`${path}/booking-agent`}
            component={BookingAgentScreen}
            isEnabled={true}
            privilegesToCheck={['isViewerAdmin']}
            userPrivileges={privileges}
          />
          <PrivilegeRoute
            path={`${path}/documents`}
            component={DocumentsScreen}
            isEnabled={isDocumentsEnabled && isOriginAndDestinationServicesEnabled && serviceProviderStatus !== 2}
            privilegesToCheck={['isViewerAdmin', 'viewerHasAccessDocumentScreen']}
            userPrivileges={privileges}
          />
          <PrivilegeRoute
            path={`${path}/invoices`}
            component={InvoiceScreen}
            isEnabled={isInvoicingEnabled && isOriginAndDestinationServicesEnabled}
            privilegesToCheck={['isViewerAdmin', 'canAccessInvoicing']}
            userPrivileges={privileges}
          />
          <Redirect
            to={getRedirectRoute(
              isInvoicingEnabled,
              canAccessInvoicing,
              isServiceProvider,
              isCompanyServiceRegistered,
              servicesNavBarApproved,
              path
            )}
          />
        </Switch>
      </Container>
    </>
  );
};

export default Company;
