import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default props => {
  const { collection, value, label, onSelect, disabled } = props;

  const onChange = event => {
    onSelect(event.target.value);
  };

  const classes = useStyles();

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} disabled={disabled} onChange={onChange} autoWidth>
        {!!collection &&
          collection.map(element => (
            <MenuItem key={element.id} value={element.id}>
              {element.name.replace(/([a-z])([A-Z])/g, '$1 $2').replace('_', ' ')}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
