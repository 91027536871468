import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';

const useStylesErrorFullQuoteBox = makeStyles(theme => ({
  root: {
    height: 200,
    width: 500,
    marginTop: theme.spacing(12),
    marginRight: 'auto',
    marginLeft: 'auto',
    boxShadow: theme.shadows[1],
  },
  cardContent: {
    width: '100%',
  },
  cardActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    marginBottom: theme.spacing(5),
  },
}));

const ErrorGetPricingFormBox = ({ message }) => {
  const classes = useStylesErrorFullQuoteBox();

  return (
    <Card component={Grid} container className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.header} variant="h5" align="center">
          <ErrorIcon color="error" /> Get Pricing Failed
        </Typography>
        <Typography variant="body1" align="center">
          {message === 'ERROR' ? 'Something wrong happened over the Get Pricing.' : message}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button variant="contained" color="primary" href="/company/booking-agent/get-pricing">
          Return to Get Pricing
        </Button>
      </CardActions>
    </Card>
  );
};

export default ErrorGetPricingFormBox;
