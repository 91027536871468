import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';

import GetPricingScreen from './GetPricing';
import PrivilegeRoute from '../../components/Routes/PrivilegeRoute';
import Reservation from '../Reservations/Reservation';
import BookingAgentRegistrationForm from '../../components/BookingAgent/BookingAgentRegistrationForm';
import { isServiceBookingAgentRegistered, isNationalAccountApprovedProvider } from '../../store/selectors/company';
import { hasAdminRights } from '../../store/selectors/user';
import InstantQuoteScreen from './InstantsQuoteScreen';

const BookingAgentScreen = () => {
  let { path } = useRouteMatch();

  const canAccessBookingAgentForm = !useSelector(isServiceBookingAgentRegistered);
  const canAccesssGetPricing = useSelector(isNationalAccountApprovedProvider);
  const isViewerAdmin = useSelector(hasAdminRights);

  const privileges = {
    isViewerAdmin,
    canAccessBookingAgentForm,
    canAccesssGetPricing,
  };

  return (
    <Switch>
      <PrivilegeRoute
        path={`${path}/get-pricing`}
        exact
        isEnabled={canAccesssGetPricing}
        component={GetPricingScreen}
        privilegesToCheck={['canAccesssGetPricing']}
        userPrivileges={privileges}
      />
      <PrivilegeRoute
        path={`${path}/get-pricing/instant-quote/:id`}
        isEnabled={canAccesssGetPricing}
        component={InstantQuoteScreen}
        privilegesToCheck={['canAccesssGetPricing']}
        userPrivileges={privileges}
      />
      <PrivilegeRoute
        path={`${path}/reservations`}
        component={Reservation}
        isEnabled={true}
        privilegesToCheck={['isViewerAdmin']}
        userPrivileges={privileges}
      />
      <PrivilegeRoute
        path={path}
        component={BookingAgentRegistrationForm}
        isEnabled={canAccessBookingAgentForm}
        privilegesToCheck={['canAccessBookingAgentForm']}
        userPrivileges={privileges}
        redirectTo="/company/booking-agent/get-pricing"
      />
    </Switch>
  );
};

export default BookingAgentScreen;
