import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useController } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { isValid, format } from 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { parseError } from './helpers';

const useStyles = makeStyles(theme => ({
  calendarField: {
    width: 197,
  },
  icon: {
    fill: theme.palette.grey[500],
  },
}));

const DatePickerField = ({ control, rules, name, defaultValue, placeHolder, minDate, maxDate }) => {
  const classes = useStyles();
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({ control, rules, name, defaultValue: defaultValue ?? null });
  const error = parseError(errors, name);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.calendarField}
        format="yyyy-MM-dd"
        okLabel="Save"
        cancelLabel="Cancel"
        minDate={
          minDate ? new Date(new Date(minDate).valueOf() + new Date().getTimezoneOffset() * 60 * 1000) : new Date()
        }
        maxDate={maxDate}
        value={value ? new Date(new Date(value).valueOf() + new Date().getTimezoneOffset() * 60 * 1000) : null}
        placeholder={placeHolder}
        DialogProps={{
          //This will prevent that the navbar blocks the calendar dialog on mobile
          style: { zIndex: 1350 },
        }}
        onChange={date => {
          onChange(isValid(date) ? format(date, 'yyyy-MM-dd') : null);
        }}
        inputVariant="outlined"
        InputProps={{
          endAdornment: <EventIcon className={classes.icon} />,
        }}
        InputLabelProps={{ shrink: true }}
        error={Boolean(error?.message)}
        helperText={error?.message}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
