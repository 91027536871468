import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const SubNavLink = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    '&[disabled]': {
      color: theme.palette.grey[500],
      pointerEvents: 'none',
    },
  },
}))(Link);

export default SubNavLink;
