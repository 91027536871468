import React, { useEffect } from 'react';
import { Typography, Container, Link, makeStyles } from '@material-ui/core';
import { auth } from '../../firebase';

const useStyles = makeStyles(theme => ({
  subMessage: {
    marginTop: theme.spacing(2.5),
  },
}));

export default () => {
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(async () => {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        auth.signOut();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const emailSupport = 'partnersupport@walkboard.com';

  return (
    <Container maxWidth="md">
      <Typography component="h1" variant="h2">
        Please check your e-mail to verify your e-mail address.
      </Typography>
      <Typography className={classes.subMessage} component="h5" variant="body1">
        (This may take up to 5 minutes so please be patient. If you do not receive an email please contact us at{' '}
        <Link href={`mailto:${emailSupport}`}>{emailSupport}</Link>)
      </Typography>
    </Container>
  );
};
