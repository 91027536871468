import React from 'react';
import { Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { MASONRY_GUTTER } from '../common';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: `${theme.spacing(1.5)}px !important`,
    marginBottom: `${MASONRY_GUTTER}px !important`, // matches parent gutter
    '&:before': { display: 'none' },
  },
  summaryRoot: {
    padding: theme.spacing(0, 1.5),
    '& .MuiExpansionPanelSummary-content': {
      alignItems: 'center',
    },
  },
  contentContainer: {
    padding: theme.spacing(0, 2, 3),
    flexDirection: 'column',
  },
}));

const ExpansionCard = ({ defaultExpanded, className, summaryContent, children }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel classes={{ rounded: `${classes.root} ${className}` }} defaultExpanded={defaultExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summaryRoot }}>
        {summaryContent}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={`${classes.contentContainer} content`}>
        <>
          <Divider />
          {children}
        </>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExpansionCard;
