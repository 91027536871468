import React from 'react';

import { Grid, Divider, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { H3 } from '../common/text';
import LearnMoreSteps from './LearMoreSteps';
import RegisterButton from '../BookingAgent/RegisterButton';
import { handleColor, handleDisableBtn, handleLabel } from './helpers';
import { Modal } from '../modal/Modal';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 220,
    height: 35,
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  closeIcon: {
    cursor: 'pointer',
    fill: theme.palette.grey[400],
  },
}));

const LearnMoreDialog = ({ header, steps, onClose, isLoading, inReview, approved, onRegister, hasRights }) => {
  const classes = useStyles();

  return (
    <Modal open onClose={onClose}>
      <Grid container spacing={0} wrap="nowrap" justify="flex-end">
        <IconButton size="small">
          <CloseIcon onClick={onClose} className={classes.closeIcon} />
        </IconButton>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={7}>
          <H3 className={classes.header}>{header}</H3>
        </Grid>
        <Grid className={classes.buttonGrid} item md={5}>
          <RegisterButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={onRegister}
            isLoading={isLoading}
            disabled={handleDisableBtn(approved, onRegister) || !hasRights}
            handleColor={handleColor(approved, inReview)}
            labelBtn={handleLabel(approved, inReview, onRegister)}
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <LearnMoreSteps steps={steps} header="Steps" />
    </Modal>
  );
};

export default LearnMoreDialog;
