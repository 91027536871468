import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  Divider,
  DialogTitle,
  DialogActions,
  CircularProgress,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import { Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Edit } from '@material-ui/icons';

import { BodyText } from '../../../common';
import PocCardSummary from './PocCardSummary';
import PocCardDetails from './PocCardDetails';

const useStylesSummaryInfoCard = makeStyles(theme => ({
  summaryRoot: { padding: theme.spacing(0, 1.5) },
}));

export const SummaryInfoCard = ({ user, subInfo }) => {
  const classes = useStylesSummaryInfoCard();

  return (
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.summaryRoot }}>
      <PocCardSummary user={user} subInfo={subInfo} />
    </ExpansionPanelSummary>
  );
};

const useStylesExpansionDetailsCard = makeStyles(theme => ({
  contentContainer: {
    padding: 0,
    flexDirection: 'column',
  },
  divider: {
    margin: theme.spacing(1, 2),
  },
}));

export const ExpansionDetailsCard = ({ user, children }) => {
  const classes = useStylesExpansionDetailsCard();

  return (
    <ExpansionPanelDetails className={classes.contentContainer}>
      <PocCardDetails user={user} />
      {Boolean(children) && (
        <>
          {children}
          <Divider className={classes.divider} />
        </>
      )}
    </ExpansionPanelDetails>
  );
};

const usePocRolesStyles = makeStyles(theme => ({
  rolesContainer: {
    margin: theme.spacing(2, 3),
  },
}));

export const PocRoles = ({ userRoles }) => {
  const classes = usePocRolesStyles();

  return (
    <div className={classes.rolesContainer}>
      <BodyText variant="label">Roles</BodyText>
      <div>
        {userRoles.length ? (
          userRoles.map((role, idx) => (
            <Typography variant="subtitle1" color="primary" key={`${role}_${idx}`}>
              {role}
            </Typography>
          ))
        ) : (
          <Typography variant="subtitle1" color="textSecondary">
            None
          </Typography>
        )}
      </div>
    </div>
  );
};

const useButtonStyles = makeStyles({
  button: {
    fontSize: 16,
    fontWeight: 700,
    height: 32,
    '&.secondary': {
      color: '#E86161',
    },
    '& svg': {
      fontSize: 20,
      marginRight: 8,
    },
  },
});

export const ContactDeleteButton = ({ isDeleting, onClick, isDeleteDialogOpen, setDeleteDialogOpen }) => {
  const classes = useButtonStyles();
  return (
    <>
      <Button className={`${classes.button} secondary`} fullWidth onClick={() => setDeleteDialogOpen(true)}>
        <DeleteIcon /> Delete Contact
      </Button>
      <Dialog aria-labelledby="confirm-delete-user-dialog" open={isDeleteDialogOpen}>
        <DialogTitle id="confirm-delete-user-dialog">Confirm User Deletion</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onClick} color="primary" disabled={isDeleting}>
            {isDeleting && <CircularProgress size="1em" />}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ContactEditButton = ({ onEdit }) => {
  const classes = useButtonStyles();
  return (
    <Button className={classes.button} fullWidth color="primary" onClick={onEdit}>
      <Edit /> Edit Contact
    </Button>
  );
};
