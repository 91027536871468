import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const REQUIRED_FIELDS = [
  {
    field: 'mainAddress',
  },
  {
    field: 'mainCity',
  },
  {
    field: 'mainState',
  },
  {
    field: 'mainZipCode',
  },
  {
    field: 'billingAddress',
  },
  {
    field: 'billingCity',
  },
  {
    field: 'billingState',
  },
  {
    field: 'billingZipCode',
  },
  {
    field: 'billingPhone',
    validation: {
      pattern: '^$|^[\\d]{10}$',
      errorMsg: 'Must be 10 digits.',
    },
  },
  {
    field: 'billingEmail',
    validation: {
      pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      errorMsg: 'Must be a valid email.',
    },
  },
];

const billingSegment = ['billingAddress', 'billingCity', 'billingState', 'billingZipCode'];

export const checkValidity = (warehouse, setErrors, isBillingSame = false) => {
  let validity = true;

  REQUIRED_FIELDS.forEach(({ field, validation }) => {
    if (Boolean(validation) && !(warehouse[field] || '').match(validation.pattern)) {
      validity = false;
      setErrors(prevState => ({ ...prevState, [field]: validation.errorMsg }));
      return;
    }

    // Validation for billingPhone
    if (field === 'billingPhone' && warehouse[field]) {
      const number = phoneUtil.parseAndKeepRawInput(warehouse[field], 'US');
      const isValidNumber = phoneUtil.isValidNumber(number);
      const regionCode = phoneUtil.getRegionCodeForNumber(number, 'US');

      if (!isValidNumber || !regionCode) {
        validity = false;
        setErrors(prevState => ({ ...prevState, [field]: 'Provide a valid phone number.' }));
      }
      return;
    }

    // Skip validation of billing segments if billing is same as main toggled
    if (billingSegment.includes(field) && isBillingSame) {
      return;
    }

    if (!warehouse[field]) {
      validity = false;
      setErrors(prevState => ({ ...prevState, [field]: 'Required' }));
      return;
    }
  });

  return validity;
};
