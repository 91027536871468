import { hasAdminRights, getUid, getUserRelatedWarehouseIds } from '../index';
import {
  isAdminContact as isWlAdminContact,
  isInvoicingContact as isWlInvoicingContact,
  isPrimaryContact as isWlPrimaryContact,
} from '../../warehouses';

import { DOCUMENT_KEYS } from '../../../../components/CompanyInfo/Documents/constants';

/**
 * Takes 2 strings, returns factory functions selector that
 * conditions selected docKey privileges with view, download and upload rights.
 * @param {String} docKey
 * @param {String} warehouseId
 * @returns {Record} User's view, download and upload rights by doc type.
 */
export const getDocumentPrivileges = (docKey, warehouseId) => state => {
  switch (docKey) {
    case DOCUMENT_KEYS.SLA: {
      return {
        // viewRights
        hasViewRights: Boolean(hasAdminRights(state)),
        // DownloadRights
        hasDownloadRights: Boolean(hasAdminRights(state)),
        // UploadRights
        hasUploadRights: Boolean(hasAdminRights(state)),
      };
    }
    case DOCUMENT_KEYS.TARIFF: {
      const uid = getUid(state);

      return {
        // viewRights
        hasViewRights: Boolean(
          hasAdminRights(state) ||
            isWlInvoicingContact(state, warehouseId, uid) ||
            isWlPrimaryContact(state, warehouseId, uid) ||
            isWlAdminContact(state, warehouseId, uid)
        ),
        // DownloadRights
        hasDownloadRights: Boolean(
          hasAdminRights(state) ||
            isWlInvoicingContact(state, warehouseId, uid) ||
            isWlPrimaryContact(state, warehouseId, uid) ||
            isWlAdminContact(state, warehouseId, uid)
        ),
        // UploadRights
        hasUploadRights: Boolean(hasAdminRights(state) || isWlAdminContact(state, warehouseId, uid)),
      };
    }
    case DOCUMENT_KEYS.W9: {
      return {
        // viewRights
        hasViewRights: Boolean(hasAdminRights(state)),
        // DownloadRights
        hasDownloadRights: Boolean(hasAdminRights(state)),
        // UploadRights
        hasUploadRights: Boolean(hasAdminRights(state)),
      };
    }

    default:
      return {};
  }
};

/**
 * takes entire state, returns all warehouses ids for user document access.
 * @param {Record} state
 * @returns {Array} warehouses ids with document access
 */
export const getWarehouseIdsDocumentAccess = state => {
  const uid = getUid(state);

  return getUserRelatedWarehouseIds(state).filter(
    warehouseId =>
      isWlPrimaryContact(state, warehouseId, uid) ||
      isWlInvoicingContact(state, warehouseId, uid) ||
      isWlAdminContact(state, warehouseId, uid)
  );
};
