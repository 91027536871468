import React from 'react';
import { makeStyles } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon, Error as ErrorIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  review: {
    background: '#ffb700',
    color: theme.palette.common.white,
    borderRadius: '50%',
  },
  error: {
    backgroundColor: theme.palette.common.white,
    color: '#F44B4B',
    borderRadius: '50%',
    border: 'none',
  },
}));

export const ReviewStepIcon = () => {
  const classes = useStyles();
  return <MoreHorizIcon className={classes.review} />;
};

export const ErrorStepIcon = () => {
  const classes = useStyles();
  return <ErrorIcon className={classes.error} />;
};
