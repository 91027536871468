import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import PageHeader from '../components/PageHeader';

import { ServiceColumn } from '../components/Services';
import {
  OriginAndDestination,
  LocalMoving,
  LongDistance,
  QuoteAndBook,
  UnbookedLead,
  HireCheckedLabor,
  OrderVideoSurveys,
} from '../components/Services/Sections';

const ServicesScreen = () => {
  return (
    <>
      <PageHeader title="Our Services" subtitle="" />
      <Grid container spacing={2}>
        <ServiceColumn headerText={'Grow your business and increase revenue as a preferred service provider.'}>
          <OriginAndDestination indexInColumn={0} />
          <Divider />
          <LocalMoving indexInColumn={1} />
          <Divider />
          <LongDistance indexInColumn={2} />
        </ServiceColumn>

        <ServiceColumn headerText={'Expand your service offerings and monetize more leads as a booking agent.'}>
          <QuoteAndBook indexInColumn={0} />
          <Divider />
          <UnbookedLead indexInColumn={1} />
        </ServiceColumn>

        <ServiceColumn headerText={'Enhance your operational capabilities with our state-of-the-art platforms.'}>
          <HireCheckedLabor indexInColumn={0} />
          <Divider />
          <OrderVideoSurveys indexInColumn={1} />
        </ServiceColumn>
      </Grid>
    </>
  );
};
export default ServicesScreen;
