import { SERVICES_STATUS_VALUES } from '../constants';

/**
 * Takes entire state, returns company document without updateBackend
 * @param {Record} state
 * @returns {Record} company document
 */
export const getCompany = state => {
  const company = state.company;
  // return the initial value of company,
  // null or false when company will not load
  if (!company) return company;

  // Don't ever return with `updateBackend` when selecting
  const { updateBackend, ...sanitizedCompany } = company;
  return sanitizedCompany;
};

/**
 * Takes entire state, returns all company contacts
 * @param {Record} state
 * @returns {Record} company contacts
 */
export const getContacts = state => Object.values(getCompany(state)?.contacts || []);

/**
 * Takes entire state, returns all company contacts admins
 * @param {Record} state
 * @returns {Array} company contacts admins
 */
export const getCompanyAdmins = state => !!getCompany(state) && getCompany(state).admins;

/**
 * Takes entire state, returns current primary contact uid of the company
 * @param {Record} state
 * @returns {String} primary contact uid
 */
export const getPrimaryContact = state => !!getCompany(state) && getCompany(state).primaryContact;

/**
 * Takes entire state, returns current invoicing contact uid of the company
 * @param {Record} state
 * @returns {String} invoicing contact uid
 */
export const getInvoicingContact = state => !!getCompany(state) && getCompany(state).invoicingContact;

// Get the company object, minus any POC & roles value
/**
 * Takes entire state, returns company document without the company contacts
 * @param {Record} state
 * @returns {Record} company document without contacts
 */
export const getCompanyWithoutContacts = state => {
  const company = getCompany(state);
  if (!company) return;

  const { contacts, primaryContact, secondaryContact, invoicingContact, admins, ...companyWithoutContacts } = company;
  return companyWithoutContacts;
};

/**
 * Takes entire state, returns all status services of the company
 * @param {Record} state
 * @returns {Record} company status services
 */
export const getServices = state => {
  const company = getCompany(state);
  return company.services || {};
};

/**
 * Takes entire state, returns and test if company has been approved for service provider.
 * @param {Record} state
 * @returns {boolean} test if company service provider is approved
 */
export const isServiceProviderActive = state => {
  const services = getServices(state);

  // number 3 status means approved.
  return services.originAndDestinationStatus === SERVICES_STATUS_VALUES.approved;
};

export const isServiceProviderStatus = state => {
  const services = getServices(state);
  return services.originAndDestinationStatus;
};

/**
 * Takes entire state, returns and test if company is new to show company information..
 * @param {Record} state
 * @returns {boolean} test if is a new company to show company information
 */
export const isServicesRegisteredCompany = state => {
  const services = getServices(state);

  return Boolean(services.isServicesRegistered);
};

/**
 * Takes entire state, returns and test if company has been approved for service provider.
 * @param {Record} state
 * @returns {boolean} test the status of the new company
 */
export const isNewCompanyStatusBarProvider = state => {
  const company = getCompany(state);

  return company.isNewCompany;
};

/**
 * Takes entire state, returns and test if company has a vanLine.
 * @param {Record} state
 * @returns {boolean} test if company has a vanLine
 */
export const isVanLineProviderActive = state => {
  const company = getCompany(state);

  return Boolean(company.vanlineId);
};

/**
 * Takes entire state, returns and test if the company has a booking agent service registered
 * @param {Record} state
 * @returns {boolean} test if is a new company has bookingAgentRegistered flag
 */
export const isServiceBookingAgentRegistered = state => {
  const services = getServices(state);

  return Boolean(services.bookingAgentRegistered);
};

/**
 * Takes entire state, returns and test if the company has a booking agent service registered
 * @param {Record} state
 * @returns {boolean} test if is the national account is approved flag
 */
export const isNationalAccountApprovedProvider = state => {
  const company = getCompany(state);

  return Boolean(company.national_account) && Boolean(company.national_account.approved);
};

/**
 * Takes entire state, returns and test if the company has an Approved sla document
 * @param {Record} state
 * @returns {boolean} test if is the sla document status is approved flag
 */
export const isSlaDocumentStatus = state => {
  const company = getCompany(state);
  if (company.sla_document_status === 2) {
    return 3;
  } else if (company.sla_document_status === 1) {
    return 2;
  } else if (company.sla_document) {
    return 1;
  }
  return 0;
};
