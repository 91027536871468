import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';

import { BodyText } from '../../../common';
import { POC_FIELDS } from '../POCSectionFields';

const useStyles = makeStyles(theme => ({
  row: {
    margin: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowItem: {
    width: '60%',
    '&.details-right': {
      width: '40%',
      paddingLeft: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(1, 2),
  },
}));

const PocCardDetails = ({ user, className }) => {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.row} ${className}`}>
        <div className={`${classes.rowItem} details-left`}>
          <BodyText variant="label">Email</BodyText>
          <BodyText el="div">{user.email}</BodyText>
        </div>
        <div className={`${classes.rowItem} details-right`}>
          <BodyText variant="label">Phone</BodyText>
          <BodyText el="div">{POC_FIELDS.phoneNumber.format(user.phoneNumber)}</BodyText>
        </div>
      </div>

      <Divider className={classes.divider} />
    </>
  );
};

export default PocCardDetails;
