import React, { useState } from 'react';
import { Grid, Button, TextField, InputAdornment } from '@material-ui/core';
import { AddCircle as AddCircleIcon, Search as SearchIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { Drawer, H3 } from '../../common';
import { getCompany } from '../../../store/selectors/company';
import PocCardSummary from '../PointsOfContact/ContactCard/PocCardSummary';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 5),
    '& input': {
      padding: theme.spacing(2, 1.5),
    },
    '& > *': {
      marginBottom: theme.spacing(1.1),
    },
  },
  title: {
    margin: theme.spacing(3, 0),
  },
  subHeader: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid #E1E1E1`,
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  searchIcon: {
    fill: theme.palette.grey[400],
  },
  searchTextField: {
    marginBottom: `${theme.spacing(2)}px !important`,
    '& > div': {
      borderRadius: theme.spacing(1),
      '& > input': {
        paddingLeft: 0,
      },
    },
  },
  card: {
    background: theme.palette.grey[50],
    padding: theme.spacing(1.3, 1),
    boxShadow: theme.shadows[1],
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    borderRadius: `${theme.spacing(1.3)}px !important`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '& .title': {
      fontSize: 18,
    },
  },
  addNewPocButton: {
    padding: theme.spacing(1, 3),
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(1),
  },
  buttonContainer: {
    background: theme.palette.grey[50],
    padding: theme.spacing(0.75, 0),
    bottom: 0,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '& > div': {
      padding: theme.spacing(1.5, 1.75),
    },
  },
  actionButton: {
    fontSize: 20,
    height: 48,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 2),
    position: 'relative',
    '& svg': {
      position: 'absolute',
      left: 28,
    },
  },
}));

export default ({ onClose, warehouse, setSelectedUser, setIsUserDialogOpen, setHideContactForm }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const company = useSelector(getCompany);

  // combines the 2 contacts maps, assuming there's no repetitive poc's in either properties
  // filers it to provide all contact indirectly/directly of company, but not related to current warehouse.
  const availablePocs = Object.values({ ...company.contacts, ...company.warehouseContacts }).filter(
    contact => !contact.warehouseLocationIds?.includes(warehouse.id)
  );
  const searchedPocs = availablePocs.filter(
    poc =>
      `${poc.firstName} ${poc.lastName}`.toLowerCase().includes(search.toLowerCase()) ||
      poc.title?.toLowerCase().includes(search.toLowerCase())
  );
  const drawerTitle = `${warehouse.mainCity}, ${warehouse.mainState} • Add Point of Contact`;

  return (
    <Drawer anchor="right" open onClose={onClose} title={drawerTitle}>
      <div className={classes.contentContainer}>
        <Grid className={classes.subHeader} container wrap="nowrap" alignItems="center">
          <H3 className={classes.title}>Existing Contacts</H3>
          <Button
            className={classes.addNewPocButton}
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setHideContactForm(false);
              setIsUserDialogOpen(true);
              onClose();
            }}
            variant="contained"
            color="primary"
          >
            New Contact
          </Button>
        </Grid>

        <TextField
          className={classes.searchTextField}
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />

        {searchedPocs.map(contact => (
          <Grid
            key={contact.id}
            className={classes.card}
            container
            wrap="nowrap"
            alignItems="center"
            onClick={() => {
              setSelectedUser(contact);
              setIsUserDialogOpen(true);
              setHideContactForm(true);
              onClose();
            }}
          >
            <PocCardSummary
              user={contact}
              subInfo={company.primaryContact === contact.id ? ['Primary Contact'] : ['']}
            />
          </Grid>
        ))}
      </div>

      <Grid container className={classes.buttonContainer} spacing={0}>
        <Grid item sm={12}>
          <Button
            className={classes.actionButton}
            variant="outlined"
            onClick={() => {
              onClose();
              setHideContactForm(false);
            }}
            fullWidth
          >
            <ArrowBackIcon />
            Back
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};
