import React from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import WarehouseDocumentCard from './WarehouseDocumentCard';
import DocumentStatusPill from './DocumentStatusPill';
import WarehouseCardSummary from '../WarehouseLocation/WarehouseCardSummary.js';
import { ExpansionCard, MasonryContainer, H2, H3 } from '../../common';
import { WAREHOUSE_DOCUMENTS, DOCUMENT_KEYS } from './constants';
import { getWarehouses } from '../../../store/selectors/warehouses';
import { hasAdminRights, getWarehouseIdsDocumentAccess } from '../../../store/selectors/user';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(3, 0, 3.8),
  },
  card: {
    '& .content': {
      '& > div': {
        margin: theme.spacing(2, 2, 0),
      },
      '& > hr': {
        marginBottom: theme.spacing(3),
      },
    },
  },
}));

const WarehouseDocumentSection = () => {
  const classes = useStyles();
  const warehouses = useSelector(getWarehouses);
  const isViewerAdmin = useSelector(hasAdminRights);
  const viewerWarehouseIdsDocumentAccess = useSelector(getWarehouseIdsDocumentAccess);

  return (
    <div className={classes.root}>
      <H2 id="wl-docs">Warehouse Documents</H2>

      <Divider className={classes.divider} />

      <MasonryContainer>
        {Boolean(warehouses) &&
          warehouses.map(
            warehouse =>
              (isViewerAdmin || viewerWarehouseIdsDocumentAccess.includes(warehouse.id)) && (
                <ExpansionCard
                  key={warehouse.id}
                  className={classes.card}
                  summaryContent={
                    <WarehouseCardSummary
                      warehouse={warehouse}
                      StatusComponent={<DocumentStatusPill warehouse={warehouse} />}
                    />
                  }
                >
                  <H3>Warehouse Documents</H3>
                  {Object.keys(WAREHOUSE_DOCUMENTS).map(docKey => {
                    // Don't render the Tariff DocumentCard if the document was disabled in admin panel.
                    if (docKey === DOCUMENT_KEYS.TARIFF && warehouse.isTariffDoesntApply) return null;

                    return <WarehouseDocumentCard key={docKey} warehouse={warehouse} docKey={docKey} />;
                  })}
                </ExpansionCard>
              )
          )}
      </MasonryContainer>
    </div>
  );
};

export default WarehouseDocumentSection;
