import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RoleCheckbox from './RoleCheckbox';
import { getCompany } from '../../../../store/selectors/company';
import { useSelector } from 'react-redux';
import { ROLE_KEYS, ROLE_NAMES, ROLE_WL_KEYS, ROLE_WL_NAMES } from '../constants';

const useStyles = makeStyles(theme => ({
  rolesGrid: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(4.35)}px !important`,
    '& > h5': {
      fontSize: '1.35rem',
      paddingBottom: theme.spacing(1.2),
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const RoleCheckboxesContainer = ({ user, roles, setRoles, isWlPocForm = false, warehouseRoles }) => {
  const classes = useStyles();
  const company = useSelector(getCompany);

  const roleNames = isWlPocForm ? ROLE_WL_NAMES : ROLE_NAMES;
  const roleKeys = isWlPocForm ? ROLE_WL_KEYS : ROLE_KEYS;

  return (
    <Grid item sm={12} className={classes.rolesGrid}>
      <Typography variant="h5">Set {isWlPocForm ? 'Warehouse' : 'Company'} Roles</Typography>
      <Grid container>
        {roleKeys.map(roleKey => (
          <Grid key={roleKey} item sm={6}>
            <RoleCheckbox
              user={user}
              roleKey={roleKey}
              roles={roles}
              setRoles={setRoles}
              company={company}
              roleNames={roleNames}
              isWlPocForm={isWlPocForm}
              warehouseRoles={warehouseRoles}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default RoleCheckboxesContainer;
