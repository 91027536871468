import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import './index.css';
import { FeatureFlagsProvider } from './utils/featureFlags';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import store from './store';

const muiTheme = createMuiTheme(theme);

const trackingCodeGA = process.env.GA_TRACKING_CODE || 'UA-171544865-1';
ReactGA.initialize(trackingCodeGA);

ReactDOM.render(
  <FeatureFlagsProvider>
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <Router>
          <Route render={() => <App />} />
        </Router>
      </Provider>
    </ThemeProvider>
  </FeatureFlagsProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
