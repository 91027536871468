import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Grid, Container, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SubNavLink from './SubNavLink';
import {
  isServicesRegisteredCompany,
  isServiceBookingAgentRegistered,
  isServiceProviderStatus,
} from '../../store/selectors/company';

const useStyles = makeStyles(theme => ({
  root: {
    top: 64,
    background: '#eaeaea',
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  dropDown: {
    lineHeight: '0.856em',
    borderRadius: 0,
    marginRight: theme.spacing(2),
    '& > .MuiSelect-select': {
      color: theme.palette.common.black,
    },
    '& > fieldset': {
      borderStyle: 'none',
      borderWidth: 'none',
      borderRadius: 'none',
      borderLeft: `1px solid rgba(0, 0, 0, 0.23) !important`,
      borderRight: `1px solid rgba(0, 0, 0, 0.23) !important`,
    },
  },
  link: {
    alignSelf: 'center',
  },
}));

const serviceLinks = {
  providerLink: {
    link: '/company/info',
    relatedLinksService: ['/company/info', '/company/documents', '/company/invoices'],
    label: 'O/D Service Provider Tools',
  },
  bookingAgent: {
    link: '/company/booking-agent',
    label: 'Booking Agent Tools',
  },
};

const useGetServiceLinks = () => {
  const isCompanyInfoRegistered = useSelector(isServicesRegisteredCompany);
  const isBookingAgentRegistered = useSelector(isServiceBookingAgentRegistered);
  const userServiceStatus = useSelector(isServiceProviderStatus);
  const links = [];

  if (isCompanyInfoRegistered) {
    links.push({ ...serviceLinks.providerLink, disabled: userServiceStatus === 2 });
  }

  if (isBookingAgentRegistered) {
    links.push(serviceLinks.bookingAgent);
  }
  return links;
};

const getCurrentLink = pathname => {
  // This case is only for booking-agent
  if (pathname === '/company/booking-agent') {
    return 'default';
  }

  for (const serviceLink of Object.values(serviceLinks)) {
    if ('relatedLinksService' in serviceLink) {
      for (const link of serviceLink.relatedLinksService) {
        if (pathname.includes(link)) {
          return serviceLink.link;
        }
      }
    }

    if (pathname.includes(serviceLink.link)) {
      return serviceLink.link;
    }
  }

  return 'default';
};

const SubNav = ({ pathname, links = [], hash = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const menuLinks = useGetServiceLinks();

  return (
    <AppBar className={`${classes.root} refSubNav`} position="sticky">
      <Toolbar variant="dense">
        <Container>
          <Grid container>
            <Grid className={classes.dropDownItem} item>
              <Select
                variant="outlined"
                className={classes.dropDown}
                value={getCurrentLink(history.location.pathname)}
                onChange={e => {
                  history.push(e.target.value);
                }}
              >
                <MenuItem value="default" disabled>
                  Services Registered
                </MenuItem>
                {menuLinks.map(({ link, label, disabled }) => (
                  <MenuItem key={link} value={link} disabled={disabled}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {links.map(({ link, suffix, label, yOffset, disabled = false }) => (
              <Grid className={classes.link} item key={label}>
                {hash ? (
                  <SubNavLink
                    href={`${pathname}${suffix}`}
                    onClick={e => {
                      e.preventDefault();
                      const container = document.querySelector(`${suffix}`);
                      const distance = container.getBoundingClientRect().top + window.pageYOffset + (yOffset || 0);

                      window.scrollTo({
                        top: distance,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    {label}
                  </SubNavLink>
                ) : (
                  <SubNavLink disabled={disabled} to={`${link}${suffix || ''}`} exact="true" component={Link}>
                    {label}
                  </SubNavLink>
                )}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default SubNav;
