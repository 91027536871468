import { v4 as uuidv4 } from 'uuid';

import firebase, { db, functions } from '../../../firebase';

const getRoleId = (isChecked, userId, existingId = null) => {
  // if the checkbox is checked, override the existing id with the user id regardless
  if (isChecked) {
    return userId;
  }

  // if checkbox is unchecked, check if it was originally set to this user. If so, that means we're trying to unset it.
  // if it wasn't set to this user to begin with, and the checkbox is unchecked, then don't touch the existing id
  return userId === existingId ? null : existingId;
};

// TODO: Fututre instances this will be refactored to be more readable, and parallel functions
export const setContact = async ({ user, company, roles, isWlPocForm, warehouse, isNoRoles, warehouseRoles }) => {
  // TODO: shouldn't allow admin to add a static contact with an email that's already in contact.

  const id = user.id || `${isWlPocForm ? '' : 'static_'}${uuidv4()}`;
  const companyId = String(company.id);
  const isPocFromCompany = Boolean(company.contacts[id]) || (!user.id && !isWlPocForm);

  // remove all WLRoles for edit contact that we don't want on company level save
  const {
    escalationContact,
    adminContact,
    primaryContact,
    operationsContact,
    invoicingContact,
    wlpoc_postgres_id,
    ...contact
  } = user;

  const companyProps = {};

  // If Poc is from company, update/add it from contacts properties
  if (isPocFromCompany) {
    companyProps['contacts'] = {
      [id]: {
        ...contact,
        id,
        companyId,
        ...(isWlPocForm && {
          warehouseLocationIds: firebase.firestore.FieldValue.arrayUnion(warehouse.id),
        }),
      },
    };

    // If isWlPocForm is provided or isNoRoles, don't update the company roles.
    if (!isWlPocForm && !isNoRoles) {
      companyProps['primaryContact'] = getRoleId(roles.primary, id, company.primaryContact);
      companyProps['secondaryContact'] = getRoleId(roles.secondary, id, company.secondaryContact);
      companyProps['invoicingContact'] = getRoleId(roles.invoicing, id, company.invoicingContact);
      companyProps['admins'] = roles.admin
        ? Array.from(new Set([...company.admins, id])) // Add without duplication
        : company.admins.filter(adminId => adminId !== id); // remove
    }
  } else {
    // Non directly related Poc contact to company will live under warehouseContacts property
    companyProps['warehouseContacts'] = {
      [id]: {
        ...contact,
        id,
        ...(warehouse.id && {
          warehouseLocationIds: firebase.firestore.FieldValue.arrayUnion(warehouse.id),
        }),
      },
    };
  }

  // If form WarehousePOC type add/update on warehouse document properties
  // This call NEEDS to be done first so the contact can be created on the Warehouse before we hit the company endpoint.
  await db
    .collection('agent_companies')
    .doc(companyId)
    .set(
      {
        ...companyProps,
        ...(!isWlPocForm && { updateBackend: true }),
      },
      { merge: true }
    );

  // If form WarehousePOC type add/update on warehouse document properties
  if (isWlPocForm) {
    // check for warehouse existance, if no warehouse is provided on the instance,
    // current instance is WLPOC Section to update basic Fields
    const warehouseId = warehouse.id || user.warehouseLocationIds?.[0];
    const warehouseProps = {};
    const updateWlContactsRoles = {};

    if (!isNoRoles) {
      // Check the provided roles of wl can only be accounted to only one contact for a warehouse.
      for (const [roleKey, contactId] of Object.entries(warehouseRoles)) {
        const roleName = roleKey.replace('Contact', '');
        // Check if current user changed his role as overriding the previous
        if (roles[roleName] && contactId && contactId !== id) {
          updateWlContactsRoles[contactId] = {
            ...updateWlContactsRoles[contactId],
            [roleKey]: false,
          };
        }
      }
    }

    warehouseProps['contacts'] = {
      [id]: {
        id,
        adminContact: Boolean(roles.admin),
        primaryContact: Boolean(roles.primary),
        escalationContact: Boolean(roles.escalation),
        operationsContact: Boolean(roles.operations),
        invoicingContact: Boolean(roles.invoicing),
      },
      ...updateWlContactsRoles,
    };

    await db
      .collection('agent_companies')
      .doc(companyId)
      .collection('warehouses')
      .doc(warehouseId)
      .set({ ...warehouseProps, updateBackend: true }, { merge: true });
  }

  return { success: true };
};

export const approveUser = async user => {
  await functions.httpsCallable('approveUser')({ uid: user.id, companyId: user.companyId });
  return { ...user, approvedByAdmin: true };
};

export const deletePoc = async (user, sendNotification = true) => {
  await functions.httpsCallable('deletePoc')({ uid: user.id, companyId: user.companyId, sendNotification });
  return { id: user.id };
};
