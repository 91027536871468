import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, makeStyles } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import firebase, { db } from '../../../../firebase';
import RegisterButton from '../../RegisterButton';
import { DEFAULT_PRICING_FIELDS } from '../../constants';
import SegmentFields from './SegmentFields';
import { getCompany } from '../../../../store/selectors/company';
import { useAsyncNewCallback } from '../../../../hooks/useAsync';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  gridPadding: {
    padding: theme.spacing(0),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: theme.spacing(4, 0, 3, 0),
  },
}));

const saveSegments = async (submitValues, company, history, trigger) => {
  const isValid = await trigger();

  if (!isValid) {
    return;
  }

  const transportation = {
    national_account_user_id: Object.values(company.national_account.broker_pocs)[0].postgres_id,
    national_account_id: company.national_account?.postgres_id,
    segments: submitValues.segments.reduce(
      (acc, segment) => ({
        ...acc,
        [segment.id]: segment,
      }),
      {}
    ),
  };

  const fullQuoteId = uuidV4();
  const payload = {
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    id: fullQuoteId,
    companyId: company.id,
    ...transportation,
    updateBackend: true,
  };

  try {
    await db
      .collection('agent_companies')
      .doc(String(company.id))
      .collection('full_quotes')
      .doc(fullQuoteId)
      .set(payload);
  } catch (error) {
    console.log(error);
  }

  history.push(`/company/booking-agent/get-pricing/instant-quote/${fullQuoteId}`);
};

const GetPricingForm = ({ selectedFullQuote, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const company = useSelector(getCompany);

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      segments: (selectedFullQuote && selectedFullQuote.segments) || DEFAULT_PRICING_FIELDS,
    },
  });
  const { handleSubmit, reset, trigger } = formProps;

  useEffect(() => {
    if (selectedFullQuote) {
      reset({ ...selectedFullQuote });
    }
  }, [selectedFullQuote, reset]);

  const [{ isLoading }, doSubmit] = useAsyncNewCallback(saveSegments);

  return (
    <FormProvider {...formProps}>
      <Grid className={classes.gridPadding} item />
      <SegmentFields />

      <RegisterButton
        className={classes.buttonContainer}
        variant="contained"
        color="primary"
        type="submit"
        isLoading={isLoading}
        labelBtn="Get Quote"
        onClick={handleSubmit(
          values => {
            doSubmit(values, company, history, trigger);
          },
          error => console.info(error)
        )}
      />
    </FormProvider>
  );
};

export default GetPricingForm;
