import React, { useState } from 'react';
import PageHeader from '../../components/PageHeader';
import GetPricingForm from '../../components/BookingAgent/Sections/GetPricingForm/GetPricingForm';
import RecentShipmentInquires from '../../components/BookingAgent/Sections/GetPricingForm/RecentShipmentInquires';

const GetPricingScreen = () => {
  const [selectedFullQuote, setSelectedFullQuote] = useState(null);

  return (
    <>
      <PageHeader title="Tell Us About The Shipment You Want To Price" />
      <GetPricingForm selectedFullQuote={selectedFullQuote} />
      <RecentShipmentInquires setSelectedFullQuote={setSelectedFullQuote} />
    </>
  );
};
export default GetPricingScreen;
