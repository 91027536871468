import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getCompanyWithoutContacts } from '../../store/selectors/company';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import useAsync from '../../hooks/useAsync';
import { db } from '../../firebase';
import LoadingScreen from '../../components/Layouts/LoadingScreen';

const columns = [
  { id: 'id', label: 'REFERENCE ID', minWidth: 140 },
  { id: 'origin', label: 'Origin', minWidth: 170 },
  {
    id: 'destination',
    label: 'Destination',
    minWidth: 170,
  },
  {
    id: 'weight',
    label: 'Weight',
    minWidth: 100,
  },
  {
    id: 'date',
    label: 'Requested Pack Date',
    minWidth: 120,
  },
];

const getReservedList = async company => {
  const response = await db
    .collection('agent_companies')
    .doc(String(company.id))
    .collection('full_quotes')
    .orderBy('created_at', 'desc')
    .get();

  return response.docs.reduce((acc, doc) => {
    const docData = doc.data();
    if (docData?.has_reservation && docData.created_at?.seconds) {
      return [...acc, docData];
    }
    return acc;
  }, []);
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

const Reservation = () => {
  const classes = useStyles();
  const company = useSelector(getCompanyWithoutContacts);

  const [{ result, isLoading }, onGetReservedList] = useAsync(getReservedList, []);

  useEffect(() => {
    onGetReservedList(company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <PageHeader title="Your Reserved Quotes" />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 800 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {result?.map(quote => {
                const [segment] = Object.values(quote.segments);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={quote.id}>
                    <TableCell>{quote.reference_id}</TableCell>
                    <TableCell>{`${segment.origin_city}, ${segment.origin_state}`}</TableCell>
                    <TableCell>{`${segment.destination_city}, ${segment.destination_state}`}</TableCell>
                    <TableCell>{segment.estimated_weight}</TableCell>
                    <TableCell>{segment.requested_pack_load_date}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Reservation;
